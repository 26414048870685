import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewLicenca = (props) => {

    const [licenca, setLicenca] = useState('');
    const [numcevs, setNumcevs] = useState('');
    const [atividadeEconomica, setAtividadeEconomica] = useState('');
    const [detalhe, setDetalhe] = useState('');
    const [pdf, setPdf] = useState('');
    const [descricao, setDescricao] = useState('');
    const [status, setStatus] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [ano, setAno] = useState('');
    const [renovacao, setRenovacao] = useState('');
    const [classe_id, setSelectedValueClasse] = useState(null);

    const [id] = useState(props.match.params.id);
    const [idempresa] = useState(props.match.params.idempresa);
    //const empresa_id = idempresa;
    console.log("id Visualizar Empresa", id);

    // const [retorno, setRetorno] = useState({
    //     type: '',
    //     mensagem: ''
    // });

    const [classeOptions, setClasseOptions] = useState([]);

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSelectChangeClasse = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueClasse(event.target.value);
    };

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        const getLicenca = async () => {

            await api.get("/licenca/idlicenca/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("id Visualizar Empresa", response.data);
                        setLicenca(response.data.licenca);
                        setNumcevs(response.data.numcevs);
                        setAtividadeEconomica(response.data.atividadeEconomica);
                        setDetalhe(response.data.detalhe);
                        setPdf(response.data.pdf);
                        setDescricao(response.data.descricao);
                        setStatus(response.data.status);
                        setEstado(response.data.estado);
                        setCidade(response.data.cidade);
                        setAno(response.data.ano);
                        setRenovacao(response.data.renovacao);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const classeOptions = async () => {
            try {
                const response = await api.get("/classe", headers);
                console.log("response typeUser", response);
                setClasseOptions(response.data); // Substitua por onde os dados necessários estão no objeto de resposta
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        classeOptions();
        getLicenca();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Visualizar Licenca</h1>
                    <hr />
                    <form className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome da licenca:</label>
                            </b>
                            <input type="text" name="licenca" className="form-control" value={licenca} onChange={text => setLicenca(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Numero do cevs:</label>
                            </b>
                            <input type="text" name="numcevs" className="form-control" value={numcevs} onChange={text => setNumcevs(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Atividade Economica:</label>
                            </b>
                            <input type="text" name="atividadeEconomica" className="form-control" value={atividadeEconomica} onChange={text => setAtividadeEconomica(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">detalhe:</label>
                            </b>
                            <input type="text" name="detalhe" className="form-control" value={detalhe} onChange={text => setDetalhe(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">pdf:</label>
                            </b>
                            <input type="text" name="pdf" className="form-control" value={pdf} onChange={text => setPdf(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">descricao:</label>
                            </b>
                            <input type="text" name="descricao" className="form-control" value={descricao} onChange={text => setDescricao(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputStatus" className="form-label">Status:</label>
                            </b>
                            <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange} disabled>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputClasse" className="form-label"><b>Classe:</b></label>
                            <select id="inputClasse" className="form-select" name="classe_id" value={classe_id} onChange={handleSelectChangeClasse} disabled>
                                {classeOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.classe}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">estado:</label>
                            </b>
                            <input type="text" name="estado" className="form-control" value={estado} onChange={text => setEstado(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">cidade:</label>
                            </b>
                            <input type="text" name="cidade" className="form-control" value={cidade} onChange={text => setCidade(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">ano:</label>
                            </b>
                            <input type="text" name="ano" className="form-control" value={ano} onChange={text => setAno(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">renovacao:</label>
                            </b>
                            <input type="text" name="renovacao" className="form-control" value={renovacao} onChange={text => setRenovacao(text.target.value)} disabled ></input>
                        </div>

                        <Link to={"/licenca/" + idempresa}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                    </form>
                </div>
            </PageContent>
        </div>
    )
}