import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import { addYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';

import axios from 'axios';

import localforage from 'localforage';

export const HistoricoOs = (props) => {

    const nome = localStorage.getItem('nome');


    const [areaOptions, setAreaOptions] = useState([]);
    const [area, setArea] = useState('');

    const [servicosDaApi, setServicosDaApi] = useState([]);
    const [servico, setServico] = useState('');

    const [areaId, setAreaId] = useState("");
    const [descricao, setDescricao] = useState("");

    const [equipamentos, setEquipamentos] = useState([]);
    const [equipamento, setEquipamento] = useState("");
    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState({});

    const [idservicoos] = useState(props.match.params.idservicoos);
    const [idos] = useState(props.match.params.idos);
    const [idcliente] = useState(props.match.params.idcliente);

    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);
    const [arquivocomprovanteservico, setArquivoComprovanteServico] = useState('');
    const [tipoarquivocomprovanteservico, setTipoArquivoComprovanteServico] = useState(null);
    const [comprovanteservico, setComprovanteServico] = useState('');
    const [arquivoservico, setArquivoServico] = useState('');
    const [viewMostrar, setViewMostrar] = useState(null);
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [image, setImage] = useState('');
    const [status, setStatus] = useState('');
    const [pdf, setPdf] = useState(null);

    const [comentario, setCompentario] = useState('');
    const [comentarionovo, setCompentarioNovo] = useState('');

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const verificaServicos = (e) => {
        getServicos(e);
    }

    const verificaServico = (e) => {
        var servico = e.toString();
        setServico(servico);
    }

    const handleAreaChange = (e) => {
        getServicos(e.target.value);
        setArea(e.target.value);
    };

    const verificaPdf = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                console.log("if que verifica arquivo", e);
                setArquivoComprovanteServico('pdf');
                setPdf(e);
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };





    const getServicos = async (areaid) => {
        try {
            const response = await api.get("/servicoarea/" + areaid, headers);
            if (response.data) {
                console.log("response.data", response.data);
                setServicosDaApi(response.data);
            } else {
                console.log("Erro: serviço não encontrado !");
            }
        } catch (err) {
            if (err) {
                console.log("Erro", err);
                setServicosDaApi([]);
            } else {
                console.log("Erro não encontrado");
            }
        }
    };

    const getEquipamentoById = async (e) => {
        try {
            const response = await api.get("/equipamento/selecionar/" + e, headers);
            console.log("response equipamento", response.data);
            setEquipamentoSelecionado(response.data);
        } catch (error) {
            console.error("Erro ao obter opções de equipamento:", error);
        }
    };

    const editarServicoOs = async e => {
        e.preventDefault();

        console.log("idservicoos", idservicoos);
        console.log("area", area);
        console.log("servico", servico);
        console.log("descricao", descricao);
        console.log("equipamento", equipamento);
        console.log("comprovanteservico", comprovanteservico);
        console.log("idos", idos);
        console.log("idcliente", idcliente);
        console.log("comentario", comentario);
        var textoRevisor = "texto revisor";

        // Concatenando usando o método concat()
        if (comentarionovo.trim() !== '' && comentario !== null) {
            var resultadoFinal = nome +" : " + comentarionovo;

            var textoConcatenado =   resultadoFinal + "\n" + comentario;
        }
        else if (comentario === null && comentarionovo != null && comentarionovo.trim() !== '') {
            var textoConcatenado = nome + " : " + comentarionovo;
        } else {
            var textoConcatenado = comentario;
        }

        const editarheaders = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };


        await api.put('/ostemservico', { idservicoos, area, servico, descricao, equipamento, idos, idcliente, comprovanteservico, status, textoConcatenado }, editarheaders)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    const handleServicoChange = (e) => {
        setServico(e.target.value);
    };

    const handleDescricaoChange = (e) => {
        setDescricao(e.target.value);
    };

    const handleEquipamentoChange = (e) => {
        setEquipamento(e.target.value);
        getEquipamentoById(e.target.value);
    };

    const VisualizarPDF = async () => {

        //se essa variavel estiver como pdf então veio do banco de dados como pdf
        //tipoarquivocertificado === 'pdf' 
        //se essa variavel estiver preenchida o arquivo de foi criado ou alterado agora nessa tela
        // arquivocertificado === 'pdf'
        console.log("comprovanteservico", comprovanteservico);
        console.log("pdf", pdf);
        if (comprovanteservico.type === 'application/pdf') {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', comprovanteservico);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        } else {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', pdf);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }

    }

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        //console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivoComprovanteServico('pdf');
                setTipoArquivoComprovanteServico('pdf2');
                setComprovanteServico(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setImage(selectedFile);
            }
        }

    }

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };


    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        const getServicoOs = async (idservicoos) => {

            await api.get("/ostemservico/" + idservicoos, headers)
                .then((response) => {
                    console.log("response", response);
                    if (response.data) {
                        setArea(response.data.area_id);
                        verificaServicos(response.data.area_id);
                        verificaServico(response.data.servico_id);
                        setDescricao(response.data.descricao);
                        setStatus(response.data.status);
                        verificaPdf(response.data.endImagem);
                        setCompentario(response.data.comentario);
                        setEquipamento(response.data.equipamento_id);
                    } else {
                        console.log("Erro: getServicoOrcamento não encontrado !");
                    }
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };
        const getEquipamentoInstituicao = async (idcliente) => {
            try {
                const response = await api.get("/equipamento/" + idcliente, headers);
                if (response.data) {
                    console.log("Equipamentos", response.data);
                    setEquipamentos(response.data);
                } else {
                    console.log("Erro: Equipamentos não encontrados!");
                }
            } catch (error) {
                console.log("Erro ao buscar equipamentos:", error.message);
            }
        };
        getServicoOs(idservicoos);
        getArea();
        getEquipamentoInstituicao(idcliente);

    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                {retorno.type === 'err' ?
                    <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}

                <h1>Histórico Serviço</h1>
                <hr />
                <form onSubmit={editarServicoOs}>
                    <div className="row">
                        <div className="col-md-6">
                            <b>
                                <label for="inputArea" className="form-label">
                                    Área*:
                                </label>
                            </b>
                            <select
                                id="inputArea"
                                className="form-select"
                                name="area"
                                onChange={handleAreaChange}
                                value={area}
                                disabled
                            >
                                <option value="">Selecione</option>
                                {areaOptions.map((opcao) => (
                                    <option value={opcao.id}>{opcao.area}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <b>
                                    <label className="form-label">Serviço</label>
                                </b>
                                <select
                                    className="form-select"
                                    id="servico"
                                    name="servico"
                                    value={servico}
                                    onChange={handleServicoChange}
                                    required
                                    disabled
                                >
                                    <option value="">Selecione</option>
                                    {servicosDaApi.map((servico) => (
                                        <option key={servico.id} value={servico.id}>
                                            {servico.servico}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="descricao" className="form-label">
                                    Descrição:

                                </label>
                            </b>
                            <select
                                id="descricao"
                                className="form-select"
                                name="descricao"
                                value={descricao}
                                onChange={handleDescricaoChange}
                                disabled
                            >
                                
                                <option value="">Selecione</option>
                                {servico === "32" && (
                                    <>
                                        <option value="Cálculo de Blindagem Sala de Raios X Intraoral">
                                            Cálculo de Blindagem Sala de Raios X Intraoral
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Raios X Extraoral">
                                            Cálculo de Blindagem Sala de Raios X Extraoral
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Raios X">
                                            Cálculo de Blindagem Sala de Raios X
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Mamografia">
                                            Cálculo de Blindagem Sala de Mamografia
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Hemodinâmica">
                                            Cálculo de Blindagem Sala de Hemodinâmica
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Tomografia">
                                            Cálculo de Blindagem Sala de Tomografia
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Densitometria Óssea">
                                            Cálculo de Blindagem Sala de Densitometria Óssea
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Liptripsia">
                                            Cálculo de Blindagem Sala de Liptripsia
                                        </option>
                                        <option value="Cálculo de Blindagem Sala de Arco Cirúrgico">
                                            Cálculo de Blindagem Sala de Arco Cirúrgico
                                        </option>
                                        <option value="Plano de Proteção Radiológica e Memorial Descritivo">
                                            Plano de Proteção Radiológica e Memorial
                                            Descritivo
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Raios X">
                                            Programa de Garantia da Qualidade - Raios X
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Mamografia">
                                            Programa de Garantia da Qualidade - Mamografia
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Tomografia">
                                            Programa de Garantia da Qualidade - Tomografia
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Raios X Fluoroscopia">
                                            Programa de Garantia da Qualidade - Raios X
                                            Fluoroscopia
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Raios X Intraoral">
                                            Programa de Garantia da Qualidade - Raios X
                                            Intraoral
                                        </option>
                                        <option value="Memorial Descritivo">
                                            Memorial Descritivo
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Ressonância Magnética Nuclear">
                                            Programa de Garantia da Qualidade - Ressonância
                                            Magnética Nuclear
                                        </option>
                                        <option value="Programa de Garantia da Qualidade - Ultrassom">
                                            Programa de Garantia da Qualidade - Ultrassom
                                        </option>
                                        <option value="Programa de Educação Continuada">
                                            Programa de Educação Continuada
                                        </option>
                                    </>
                                )}
                                {servico === "35" && (
                                    <>
                                        <option value="Raios X Intraoral - bienal">
                                            Raios X Intraoral - bienal
                                        </option>
                                        <option value="Raios X Extraoral - anual">
                                            Raios X Extraoral - anual
                                        </option>
                                        <option value="Raios X - mensal">
                                            Raios X - mensal
                                        </option>
                                        <option value="Raios X - semestral">
                                            Raios X - semestral
                                        </option>
                                        <option value="Raios X - anual">
                                            Raios X - anual
                                        </option>
                                        <option value="Mamografia - diario">
                                            Mamografia - diario
                                        </option>
                                        <option value="Mamografia - mensal">
                                            Mamografia - mensal
                                        </option>
                                        <option value="Mamografia - semestral">
                                            Mamografia - semestral
                                        </option>
                                        <option value="Mamografia - anual">
                                            Mamografia - anual
                                        </option>
                                        <option value="Tomografia - mensal">
                                            Tomografia - mensal
                                        </option>
                                        <option value="Tomografia - semanal">
                                            Tomografia - semanal
                                        </option>
                                        <option value="Tomografia - anual">
                                            Tomografia - anual
                                        </option>
                                        <option value="Raios X Fluoroscopia - semanal">
                                            Raios X Fluoroscopia - semanal
                                        </option>
                                        <option value="Raios X Fluoroscopia - mensal">
                                            Raios X Fluoroscopia - mensal
                                        </option>
                                        <option value="Raios X Fluoroscopia - semestral">
                                            Raios X Fluoroscopia - semestral
                                        </option>
                                        <option value="Raios X Fluoroscopia - anual">
                                            Raios X Fluoroscopia - anual
                                        </option>
                                        <option value="Raios X Telecomandado - semanal">
                                            Raios X Telecomandado - semanal
                                        </option>
                                        <option value="Raios X Telecomandado - semestral">
                                            Raios X Telecomandado - semestral
                                        </option>
                                        <option value="Raios X Telecomandado - anual">
                                            Raios X Telecomandado - anual
                                        </option>
                                        <option value="EPI - semestral">
                                            EPI - semestral
                                        </option>
                                        <option value="EPI - anual">EPI - anual</option>
                                        <option value="EPI - bienal">EPI - bienal</option>
                                        <option value="Negatoscópio e/ou Monitor de Laudos - anual">
                                            Negatoscópio e/ou Monitor de Laudos - anual
                                        </option>
                                        <option value="Sistema de Imagem Filme - Raios X - anual">
                                            Sistema de Imagem Filme - Raios X - anual
                                        </option>
                                        <option value="Sistema de Imagem Filme - Mamografia - anual">
                                            Sistema de Imagem Filme - Mamografia - anual
                                        </option>
                                        <option value="Sistema de Imagem CR - Raios X - anual">
                                            Sistema de Imagem CR - Raios X - anual
                                        </option>
                                        <option value="Sistema de Imagem CR - Mamografia - anual">
                                            Sistema de Imagem CR - Mamografia - anual
                                        </option>
                                        <option value="Densitometria óssea - mensal">
                                            Densitometria óssea - mensal
                                        </option>
                                        <option value="Densitometria óssea - semestral">
                                            Densitometria óssea - semestral
                                        </option>
                                        <option value="Densitometria óssea - anual">
                                            Densitometria óssea - anual
                                        </option>
                                        <option value="Ultrassom - anual">
                                            Ultrassom - anual
                                        </option>
                                        <option value="Ressonancia Magnética Nuclear - semanal">
                                            Ressonancia Magnética Nuclear - semanal
                                        </option>
                                        <option value="Ressonancia Magnética Nuclear - anual">
                                            Ressonancia Magnética Nuclear - anual
                                        </option>
                                    </>
                                )}
                                {servico === "33" && (
                                    <>
                                        <option value="Raios X Intraoral - Levantamento Radiométrico - quadrienal">
                                            Raios X Intraoral - Levantamento Radiométrico -
                                            quadrienal
                                        </option>
                                        <option value="Raios X Intraoral - Radiação de Fuga do cabeçote - quadrienal">
                                            Raios X Intraoral - Radiação de Fuga do cabeçote
                                            - quadrienal
                                        </option>
                                        <option value="Raios X Intraoral - Radiação Espalhada - quadrienal">
                                            Raios X Intraoral - Radiação Espalhada -
                                            quadrienal
                                        </option>
                                        <option value="Raios X Extraoral - Levantamento Radiométrico - quadrienal">
                                            Raios X Extraoral - Levantamento Radiométrico -
                                            quadrienal
                                        </option>
                                        <option value="Raios X - Levantamento Radiométrico - quadrienal">
                                            Raios X - Levantamento Radiométrico - quadrienal
                                        </option>
                                        <option value="Raios X - Radiação de Fuga do cabeçote - quadrienal">
                                            Raios X - Radiação de Fuga do cabeçote -
                                            quadrienal
                                        </option>
                                        <option value="Raios X - Radiação Espalhada - quadrienal">
                                            Raios X - Radiação Espalhada - quadrienal
                                        </option>
                                        <option value="Mamografia - Levantamento Radiométrico - quadrienal">
                                            Mamografia - Levantamento Radiométrico -
                                            quadrienal
                                        </option>
                                        <option value="Mamografia - Radiação de Fuga do cabeçote - quadrienal">
                                            Mamografia - Radiação de Fuga do cabeçote -
                                            quadrienal
                                        </option>
                                        <option value="Tomografia - Levantamento Radiométrico - quadrienal">
                                            Tomografia - Levantamento Radiométrico -
                                            quadrienal
                                        </option>
                                        <option value="Raios X Fluoroscopia - Levantamento Radiométrico - quadrienal">
                                            Raios X Fluoroscopia - Levantamento Radiométrico
                                            - quadrienal
                                        </option>
                                        <option value="Raios X Fluoroscopia - Radiação de Fuga do cabeçote - quadrienal">
                                            Raios X Fluoroscopia - Radiação de Fuga do
                                            cabeçote - quadrienal
                                        </option>
                                        <option value="Raios X Fluoroscopia - Radiação Espalhada - quadrienal">
                                            Raios X Fluoroscopia - Radiação Espalhada -
                                            quadrienal
                                        </option>
                                    </>
                                )}
                            </select>
                        </div>
                        <div className="col-md-6 col-sm-12 form-outline mb-4">
                            {(servico !== '32' && equipamento !== null) &&  (
                                <>
                                    <b>
                                        <label className="form-label">Equipamento:</label>
                                    </b>
                                    <select
                                        className={`form-select`}
                                        name="equipamento"
                                        onChange={handleEquipamentoChange}
                                        value={equipamento}
                                        disabled
                                    >
                                        <option value="">Selecione</option>
                                        {equipamentos.map((opcao) => (
                                            <option value={opcao.id}>
                                                {opcao.equipamento}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}  
                        </div>
                         
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                            <label for="comprovanteservico" className="form-label"><b> Selecione uma foto/pdf do serviço</b></label>
                            <input className="form-control" type="file" name="comprovanteservico" id="comprovanteservico" onChange={veirificaTipoArquivo} /><br /><br />

                            {tipoarquivocomprovanteservico === 'pdf' && <>
                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                            </>}
                            {arquivocomprovanteservico === 'pdf' && <>
                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                            </>}

                        </div>
                        
                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputStatus" className="form-label">Status:</label>
                            </b>
                            <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange}>
                                <option value="revisar">Revisar</option>
                                <option value="aprovado">Aprovado</option>
                                <option value="reprovado">Reprovado</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-4 form-outline mb-4">
                            <label htmlFor="comentarionovo" className="form-label">
                                <b>
                                    Comentário Revisor
                                </b>
                            </label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={comentarionovo}
                                id="comentarionovo"
                                name="comentarionovo"
                                rows="3"
                                onChange={text => setCompentarioNovo(text.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-4 form-outline mb-4">
                            <label htmlFor="comentario" className="form-label">
                                <b>
                                    Histórico
                                </b>
                            </label>
                            <textarea
                                disabled
                                type="text"
                                className="form-control"
                                value={comentario}
                                id="comentario"
                                name="comentario"
                                rows="3"
                                onChange={text => setCompentario(text.target.value)}
                            ></textarea>
                        </div>
                    </div>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/revisaros/" + idos}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'success' ?
                            <Redirect to={{
                                pathname: '/revisaros/' + idos,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}