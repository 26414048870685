import React, { useEffect, useState } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';

import { Link, Redirect } from 'react-router-dom';

import localforage from 'localforage';


export const Perfil = () => {

    const [data, setData] = useState([]);

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [cpf, setCPF] = useState('');
    const [foto, setFoto] = useState('');
    const [senha, setSenha] = useState('');
    const [endImg, setEndImg] = useState('');
    const [email, setEmail] = useState('');
    const [titulacao, setTitulacao] = useState('');
    const [titulacaocnen, setTitulacaocnen] = useState('');
    const [complementocnen, setComplementocnen] = useState('');
    const [vinculo, setVinculo] = useState('');
    const [empresa_id, setSelectedValueEmpresa] = useState(1);
    const [nivel_id, setSelectedValueNivel] = useState();
    const [area_id, setSelectedValueArea] = useState();
    const [certificado, setCertificado] = useState('');

    const [qualificacao, setQualificacao] = useState('');

    const [possui, setPossui] = useState([
        { value: 'selecione', label: 'Selecione' },
        { value: 'possui', label: 'Possui' },
        { value: 'naopossui', label: 'Não Possui' }
    ]);

    const [empresaOptions, setEmpresaOptions] = useState([]);
    const [nivelOptions, setNivelOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [verificacnen, setVerificacnen] = useState([null]);
    const [verificaNivel, setverificaNivel] = useState([null]);

    const [viewMostrar, setViewMostrar] = useState(null);
    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);
    const [arquivocertificado, setArquivocertificado] = useState('');
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [certificadocnen, setCertificadoCnen] = useState('');
    const [image, setImage] = useState('');

    const [tipoarquivocertificado, setTipoarquivocertificado] = useState(null);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const handleSelectChangeEmpresa = (event) => {
        console.log("event handleSelectChangeEmpresa", event);

        console.log("event", typeof event);
        if (typeof event === "number") {
            var idempresa = event.toString();
            setSelectedValueEmpresa(idempresa);
            if (idempresa === 1) {
                setVinculo('proprio');
            }
            if (idempresa !== 1) {
                setVinculo('terceiro');
            }
        }
        if (typeof event === "object") {
            event.preventDefault();
            console.log("objectempresa", event.target.value);
            setSelectedValueEmpresa(event.target.value);
            if (event.target.value === 1) {
                setVinculo('proprio');
            }
            if (event.target.value !== 1) {
                setVinculo('terceiro');
            }
        }

    };

    const handleSelectChangeNivel = (event) => {
        if (typeof event === "number") {
            var nivel = event.toString();
            setverificaNivel(nivel);
        }
        if (typeof event === "object") {
            event.preventDefault();
            setSelectedValueNivel(event.target.value);
            setverificaNivel(event.target.value);
        }
    };

    const handleSelectChangeArea = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueArea(event.target.value);
    };

    const verificaPossuiCnen = (event) => {
        event.preventDefault();
        console.log("Possui", event.target.value);
        setTitulacaocnen(event.target.value);
        setVerificacnen(event.target.value);
    };

    const handleSelectTitulacao = (event) => {
        setTitulacao(event.target.value);
    };

    const VisualizarPDF = async () => {

        //se essa variavel estiver como pdf então veio do banco de dados como pdf
        //tipoarquivocertificado === 'pdf' 
        //se essa variavel estiver preenchida o arquivo de foi criado ou alterado agora nessa tela
        // arquivocertificado === 'pdf'
        console.log("certificado", certificado);
        console.log("certificadocnen", certificadocnen.type);
        if (certificadocnen.type === 'application/pdf') {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', certificadocnen);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        } else {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', certificado);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getPerfil();
                await getArea();
                await empresaOption();
                await nivelOption();
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
                // Tratar erro, se necessário
            }
        };

        fetchData();
    }, []);

    const headers = {
        'headers': {
            'Authorization': "Bearer " + localStorage.getItem('token')
        }
    }

    const getPerfil = async () => {

        await api.get("/view-profile/", headers)
            .then((response) => {
                console.log("view-profile", response.data);


                setId(response.data.id);
                setNome(response.data.nome);
                setCPF(response.data.cpf);
                setEmail(response.data.email);
                setTitulacao(response.data.titulacao);
                setTitulacaocnen(response.data.titulacao_cnen);
                setVinculo(response.data.vinculo);
                setComplementocnen(response.data.complementocnen);
                setVerificacnen(response.data.titulacao_cnen);
                handleSelectChangeEmpresa(response.data.empresa_id);
                setSelectedValueNivel(response.data.typeUser_id);
                handleSelectChangeNivel(response.data.typeUser_id);
                setSelectedValueArea(response.data.area_id);
                setEndImg(response.data.endImagem);
                setQualificacao(response.data.qualificacao);
                setTipoarquivocertificado(response.data.tipoarquivocertificado);
                setCertificado(response.data.endCertificado);

                console.log("endImagem -> ", endImg);
            }).catch((err) => {
                console.log("Perfil", err);
            });
    };

    const empresaOption =
        async () => {
            try {
                const response = await api.get("/empresa/", headers);
                setEmpresaOptions(response.data);
                console.log("response empresa", response);
            } catch (error) {
                console.error('Erro ao obter opções de empresa:', error);
            }
        };

    const nivelOption = async () => {
        try {
            const response = await api.get("/typeuser/", headers);
            console.log("response typeUser", response);
            setNivelOptions(response.data);
        } catch (error) {
            console.error('Erro ao obter opções de vinculo2:', error);
        }
    };

    const getArea = async () => {
        await api.get("/area/", headers)
            .then((response) => {
                console.log("area", response)
                setAreaOptions(response.data);
            }).catch((err) => {
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente mais tarde!'
                });
            });
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivocertificado('pdf');
                setTipoarquivocertificado('pdf2');
                setCertificadoCnen(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setImage(selectedFile);
            }
        }

    }


    const EditarUsuario = async e => {
        e.preventDefault();
        console.log("e", e);

        console.log("id", id, "nome", nome, "email", email, "cpf", cpf, "vinculo", vinculo, "empresa_id", empresa_id, "nivel_id", nivel_id, "area_id", area_id)
        console.log("imagem", endImg, "foto", foto, "senha", senha, "titulacao", titulacao, "titulacaocnen", titulacaocnen);

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        if (nivel_id === 25 || nivel_id === '25') {
            setTitulacao('');
            setTitulacaocnen('');
            setComplementocnen('');
        }
        if (titulacaocnen === 'possui') {
            setComplementocnen('');
        }

        await api.put("/usuario", { id, nome, email, cpf, vinculo, empresa_id, nivel_id, area_id, endImg, foto, certificadocnen, senha, titulacao, titulacaocnen, complementocnen, qualificacao, arquivoimagem, arquivocertificado }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    console.log("err", err);
                } else {
                    console.log("Erro");
                }
            })

        

    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                    <h1>Perfil</h1>

                    <hr />
                    <form onSubmit={EditarUsuario} className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome:</label>
                            </b>
                            <input type="text" name="nome" className={`form-control ${retorno?.nome && 'is-invalid'}`} value={nome} onChange={text => setNome(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">CPF:</label>
                            </b>
                            <input type="text" name="cpf" className={`form-control ${retorno?.cpf && 'is-invalid'}`} value={cpf} onChange={text => setCPF(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Email:</label>
                            </b>
                            <input type="text" name="email" className={`form-control ${retorno?.email && 'is-invalid'}`} value={email} onChange={text => setEmail(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Senha:</label>
                            </b>
                            <input type="password" name="senha" className={`form-control ${retorno?.senha && 'is-invalid'}`} value={senha} onChange={text => setSenha(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputNivel" className="form-label"><b>Nivel:</b></label>
                            <select id="inputNivel" disabled className="form-select" name="nivel_id" value={nivel_id} onChange={handleSelectChangeNivel}>
                                {nivelOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.type}</option>
                                ))}
                            </select>
                        </div>

                        {verificaNivel !== "25" && <>
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label for="inputTitulacao" className="form-label">Titulação:</label>
                                </b>
                                <select
                                    id="inputTitulacao"
                                    className="form-select"
                                    name="titulacao"
                                    onChange={handleSelectTitulacao}
                                    value={titulacao}
                                >
                                    <option value="">Selecione</option>
                                    <option value="graduacao">Graduação</option>
                                    <option value="mestrado">Mestrado</option>
                                    <option value="doutorado">Doutorado</option>
                                    <option value="especialista">Especialista</option>
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label for="inputTitulacaocnen" className="form-label">Titulação CNEN:</label>
                                </b>
                                <select
                                    id="inputTitulacaocnen"
                                    className="form-select"
                                    name="titulacaocnen"
                                    value={titulacaocnen}
                                    onChange={verificaPossuiCnen}
                                >
                                    {possui.map((ps) => (
                                        <option value={ps.value}>{ps.label}</option>
                                    ))}
                                </select>

                                {/* <select id="inputTitulacaocnen" className="form-select" name="titulacaocnen" value={titulacaocnen} onChange={verificaPossuiCnen}>
                                <option value="possui">Possui</option>
                                <option value="naopossui">Não Possui</option>
                            </select> */}
                            </div>
                            {verificacnen === 'possui' && <>
                                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                    <b>
                                        <label className="form-label">Regristo CNEN*:</label>
                                    </b>
                                    <input type="text" autoComplete="off" name="complementocnen" value={complementocnen} placeholder="Digite o Regristo CNEN" className="form-control" onChange={text => setComplementocnen(text.target.value)} ></input>
                                </div>
                            </>}
                        </>}
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputEmpresa" className="form-label"><b>Empresa:</b></label>
                            <select id="inputEmpresa" className="form-select" name="empresa_id" value={empresa_id} onChange={handleSelectChangeEmpresa}>
                                {empresaOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.nome}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputArea" className="form-label"><b>Area:</b></label>
                            <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea}>
                                {areaOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.area}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                            <label for="formFile" className="form-label">Selecione uma foto</label>
                            <input className="form-control" type="file" name="foto" id="formFile" onChange={e => setFoto(e.target.files[0])} /><br /><br />

                            {foto ? <img src={URL.createObjectURL(foto)} alt="Imagem do usuário" className="rounded-circle" width="150" height="150" /> : <img src={endImg} className="rounded-circle" alt="Imagem do usuário" width="150" height="150" />}

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 form-outline mb-4">
                            <label htmlFor="qualificacao" className="form-label">
                                Qualificações
                            </label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={qualificacao}
                                id="qualificacao"
                                name="qualificacao"
                                rows="3"
                                onChange={text => setQualificacao(text.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                            <label for="certificadocnen" className="form-label"> Selecione uma foto/pdf do certificado</label>
                            <input className="form-control" type="file" name="certificadocnen" id="certificadocnen" onChange={veirificaTipoArquivo} /><br /><br />

                            {tipoarquivocertificado === 'pdf' && <>
                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                            </>}

                            {arquivocertificado === 'pdf' && <>
                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                            </>}

                        </div>

                        <div className="col-12 mb-4 text-center">
                            <Link to={"/dashboard"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/dashboard',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Editar</button>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    );
}
