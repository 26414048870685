import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import './PDFViewer.css';

import { Link, Redirect } from 'react-router-dom';

import { addYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

import localforage from 'localforage';

export const AddEquipamentoInterno = () => {

    const [equipamentoInterno, setEquipamentoInterno] = useState({
        nome: '',
        numeroserie: '',
        patrimonio: '',
        status: '',
        vinculo: '',
        empresa: '',
        classificacao: ''
    });

    const [sonda, setSonda] = useState({
        nomeSonda: '',
        numeroserieSonda: '',
        patrimonioSonda: '',
        laboratorio: '',
        numerocertificadocalibracao: '',
        datacalibracao: '',
        datarenovacaocalibracao: '',
        tipo_sonda: number,
        aplicacao: '',
        tipoarquivocertificado: '',
        foto: ''
    });

    // Fazer isso para adicionar mais de uma sonda
    const [sondas, setSondas] = useState([])
    //fazer isso quando clica no adicionar
    const salvarSonda = async e => {
        e.preventDefault();
        setViewMostrar(0);
        setImage('');
        console.log("salvar sonda", sonda);
        console.log("salvar sonda", checkedValuesAplicacao);
        console.log("checkbox sonda", aplicacaoOptions);
        console.log("imagem", image);
        const novaSonda = { ...sonda, aplicacao: aplicacaoOptions, foto: image, tipoarquivocertificado: arquivocertificado };
        console.log("novaSonda", novaSonda);
        setSondas([...sondas, novaSonda])
        setSonda({
            nomeSonda: '',
            numeroserieSonda: '',
            patrimonioSonda: '',
            laboratorio: '',
            numerocertificadocalibracao: '',
            datacalibracao: '',
            datarenovacaocalibracao: '',
            tipoarquivocertificado: '',
            tipo_sonda: null,
        });
        limparCamposAplicacao();

        console.log("sonda", sonda);


    }

    const limparCamposAplicacao = () => {
        setAplicacaoOptions(aplicacaoOptions.map(option => ({ ...option, checked: false })));
        setValueAplicacao([]);
    };
    //setSondas([...sondas, sonda])

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [empresaOptions, setEmpresaOptions] = useState([]);
    const [checkedValues, setValue] = useState([]);
    const [tipoSondaOptions, setTipoSondaOptions] = useState([]);
    const [listaTecnicoEmpresa, setListaTecnicoEmpresaOptions] = useState([]);
    const [aplicacaoOptions, setAplicacaoOptions] = useState([]);
    const [aplicacaoselecionadaOptions, setAplicacaoSelecionadaOptions] = useState([]);
    const [checkedValuesAplicacao, setValueAplicacao] = useState([]);
    const [dataRenovacao, setdataRenovacao] = useState([]);
    const [tipoEquipamentoOptions, settipoEquipamentoOptions] = useState([]);
    const [validarClassificao, setValidarClassificaoOptions] = useState([2]);
    const [tecnico, setTecnino] = useState([null]);
    const [vinculos, setVinculos] = useState([
        { value: 'selecione', label: 'Selecione' },
        { value: 'sede', label: 'Sede' },
        { value: 'tecnico', label: 'Técnico' },
        { value: 'comodato', label: 'Comodato' }
    ]);

    const valueInput = e => setEquipamentoInterno({ ...equipamentoInterno, [e.target.name]: e.target.value });

    const valueInputSonda = e => setSonda({ ...sonda, [e.target.name]: e.target.value });

    const addEquipamentoInterno = async e => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const headersFoto = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        var obj = {
            equipamentoInterno,
            sondas,
            aplicacao: checkedValuesAplicacao
        }

        equipamentoInterno.empresa = empresaOptions;
        equipamentoInterno.classificacao = validarClassificao;
        equipamentoInterno.vinculo = tecnico;

        console.log("EquipamentoInterno", equipamentoInterno, sondas, checkedValuesAplicacao);
        console.log("EquipamentoInterno", obj);

        await api.post('/equipamentointerno', obj, headers)
            .then(async (response) => {
                console.log("response", response);
                var IdEquipamentoInterno = response.data.retorno;
                console.log("classificacao", equipamentoInterno.classificacao);
                console.log("IdEquipamentoInterno", IdEquipamentoInterno);
                //phatom
                if (equipamentoInterno.classificacao == 2) {
                    var aplicacaoEquipamento = {
                        aplicacao: checkedValuesAplicacao,
                        id: IdEquipamentoInterno
                    }
                    await api.post('/aplicacaoEquipamento', aplicacaoEquipamento, headers)
                        .then((response) => {
                            console.log("response equipamentointerno", response);
                            setRetorno({
                                type: 'redSuccess',
                                mensagem: response.data.mensagem
                            });
                        })
                        .catch((err) => {

                        });
                }
                //Detector
                if (equipamentoInterno.classificacao == 1) {

                    //Colocar um contador para saber se foi enviado todos se for enviado todos colocar o setRetorno redSucess
                    var contador = 0;
                    var contadorSucesso = 0;
                    for (let index = 0; index < sondas.length; index++) {
                        const element = sondas[index];
                        element.id = IdEquipamentoInterno;
                        contador = contador + 1;
                        console.log("response sondaAplicacao", element);
                        await api.post('/sondaAplicacao', element, headersFoto)
                            .then((response) => {
                                console.log("response equipamentointerno", response);
                                contadorSucesso = contadorSucesso + 1;

                            })
                            .catch((err) => {

                            });
                    }

                    if (contador === sondas.length && contadorSucesso === sondas.length) {
                        setRetorno({
                            type: 'redSuccess',
                            mensagem: 'Cadastro realizado com sucesso'
                        });
                    }else {
                        setRetorno({
                            type: 'erro',
                            mensagem: 'Algum dos cadastro de sonda deu erro'
                        });
                    }


                }

            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    const checkBoxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const checkBoxChangeAplicacao = (event, index) => {
        const { value, checked } = event.target;

        let res = [...aplicacaoOptions]
        res[index].checked = event.target.checked
        setAplicacaoOptions(res)

        if (checked) {
            setValueAplicacao(pre => [...pre, value])
        } else {
            setValueAplicacao(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const checkBoxChangeAplicacaoselecionada = (event, index) => {
        const { value, checked } = event.target;

        if (checked) {
            setAplicacaoSelecionadaOptions(pre => [...pre, value])
        } else {
            setAplicacaoSelecionadaOptions(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    function verificaTecnico(event) {
        console.log("verificaTecnico", event.target.value);
        var tecnicoVerificado = event.target.value;
        setTecnino(tecnicoVerificado);

    }

    function verificaClassificacao(event) {
        console.log("verificaClassificacao", event.target.value);
        var classificacao = event.target.value;
        console.log("classificacao", classificacao);
        setValidarClassificaoOptions(classificacao);
    }

    const VisualizarPDF = async () => {
        if (image !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', image);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }


    const geraDataRenovacao = (event) => {
        var datadecalibracao = event.target.value;

        sonda.datacalibracao = datadecalibracao;

        var znDate = zonedTimeToUtc(datadecalibracao, 'America/Sao_Paulo');
        console.log("znDate", znDate);

        var adddoisanos = addYears(znDate, 2);

        var dataFormatada = format(adddoisanos, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });
        console.log("dataFormatada", dataFormatada);
        setdataRenovacao(dataFormatada);

        sonda.datarenovacaocalibracao = dataFormatada;

    }

    const [image, setImage] = useState('');
    const [arquivocertificado, setArquivocertificado] = useState('');
    const [endImg] = useState('./icone_usuario.png');
    const [viewMostrar, setViewMostrar] = useState(null);

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrar(1);
                setArquivocertificado('pdf');
                setImage(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrar(2);
                setArquivocertificado('imagem');
                setImage(selectedFile);
            }
        }

    }


    useEffect(() => {
        console.log("sondas", sondas);
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const empresaOptions = async () => {
            try {
                const response = await api.get("/empresaUsuarioLogado", headers);
                console.log("response empresa", response.data);
                setEmpresaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        const tipoSonda = async () => {
            try {
                const response = await api.get("/tiposonda", headers);
                console.log("response classe", response);
                setTipoSondaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        const aplicacaoOptions = async () => {
            try {
                const response = await api.get("/aplicacaoValidando", headers);
                console.log("response aplicacao", response.data);
                setAplicacaoOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }
        const tipoEquipamentoOptions = async () => {
            try {
                const response = await api.get("/tipoequipamento", headers);
                console.log("response tipoequipamento", response.data);
                settipoEquipamentoOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        const listaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnico", headers);
                console.log("response usuarioTecnico", response.data);
                setListaTecnicoEmpresaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }


        tipoSonda();
        aplicacaoOptions();
        empresaOptions();
        tipoEquipamentoOptions();
        listaTecnicoEmpresa();
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Adicionar Equipamento Interno</h1>
                <hr />
                {retorno.type === 'error' ?
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}
                <form onSubmit={addEquipamentoInterno} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nome" className="form-control" onChange={valueInput} autoComplete="off" ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">N° de serie:</label>
                        </b>
                        <input type="text" name="numeroserie" className="form-control" onChange={valueInput} autoComplete="off" ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Patrimonio:</label>
                        </b>
                        <input type="text" name="patrimonio" className="form-control" onChange={valueInput} autoComplete="off" ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Status:</label>
                        </b>
                        <select
                            className="form-select"
                            name="status"
                            onChange={valueInput}
                        >
                            <option value="">Selecione</option>
                            <option value="vencido">Vencido</option>
                            <option value="válido">Válido</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputVinculo" className="form-label">Vínculo:</label>
                        </b>
                        <select
                            id="inputVinculo"
                            className="form-select"
                            defaultValue={"selecione"}
                            name="vinculo"
                            onChange={verificaTecnico}
                            value={vinculos.value}
                        >
                            {vinculos.map((vin) => (
                                <option value={vin.value}>{vin.label}</option>
                            ))}
                            {/* <option value="sede">Sede</option>
                            <option value="tecnico">Técnico</option>
                            <option value="comodato">Comodato</option> */}

                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputTipoClassificacao" className="form-label">Classificação:</label>
                        </b>
                        <select
                            id="inputTipoClassificacao"
                            className="form-select"
                            name="classificacao"
                            onChange={verificaClassificacao}
                            value={tipoEquipamentoOptions.tipo}
                        >
                            <option value="">Selecione</option>
                            {tipoEquipamentoOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.tipo}</option>
                            ))}
                        </select>
                    </div>
                    {tecnico === 'tecnico' ? <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputUsuario" className="form-label">Técnicos*:</label>
                        </b>
                        <select
                            id="inputUsuario"
                            className={`form-select`}
                            name="usuario_id"
                            onChange={valueInput}
                            value={listaTecnicoEmpresa.id}
                        >
                            <option value="">Selecione</option>
                            {listaTecnicoEmpresa.map((listaUsuario) => (
                                <option value={listaUsuario.id}>{listaUsuario.nome}</option>
                            ))}
                        </select>
                    </div> : " "}

                    <h1>Empresas vinculadas ao equipamento</h1>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <div className="row">
                            {empresaOptions.map((itemEmpresa, index) =>
                                <div key={index} className="form-check col-md-4">
                                    <div>
                                        <input type="checkbox" className="form-check-input" checked={itemEmpresa.checked} value={itemEmpresa.id} id={itemEmpresa.id} onChange={checkBoxChange} />
                                        <label className="form-check-label" for={itemEmpresa.id}>{itemEmpresa.nome}</label>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                    {validarClassificao == '1' && <><hr />
                        <h1>Sonda</h1><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome:</label>
                            </b>
                            <input type="text" name="nomeSonda" className="form-control" value={sonda.nomeSonda} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">N° de serie:</label>
                            </b>
                            <input type="text" name="numeroserieSonda" className="form-control" value={sonda.numeroserieSonda} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Patrimonio:</label>
                            </b>
                            <input type="text" name="patrimonioSonda" className="form-control" value={sonda.patrimonioSonda} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Laboratorio:</label>
                            </b>
                            <input type="text" name="laboratorio" className="form-control" value={sonda.laboratorio} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">N° Certificado de calibração:</label>
                            </b>
                            <input type="text" name="numerocertificadocalibracao" value={sonda.numerocertificadocalibracao} className="form-control" onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Data calibração:</label>
                            </b>
                            <input type="date" name="datacalibracao" className="form-control" value={sonda.datacalibracao} onChange={geraDataRenovacao}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Data da renovação de calibração:</label>
                            </b>
                            <input type="date" name="datarenovacaocalibracao" value={dataRenovacao} className="form-control" onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                            </b>
                            <select
                                id="inputTipoSonda"
                                className="form-select"
                                name="tipo_sonda"
                                onChange={valueInputSonda}
                                value={tipoSondaOptions.tipo}
                            >
                                <option>Selecione</option>
                                {tipoSondaOptions.map((opcao) => (
                                    <option value={opcao.id}>{opcao.tipo}</option>
                                ))}
                            </select>
                        </div>
                        {/* <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                           <input type="file" className='form-control' />
                           <button type='submit' className='btn btn-success'>View PDF</button>
                        </div>

                        <h2>View PDF</h2>
                        <div className='pdf-container'>
                            
                        </div> */}
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                            <label for="formFile" className="form-label">Selecione uma foto/pdf do certificado</label>
                            <input className="form-control" type="file" name="sonda.foto" id="formFile" onChange={veirificaTipoArquivo} /><br /><br />

                            {viewMostrar === 1 && <>
                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                                        Visualizar PDF
                                </button>
                            </>}

                            {viewMostrar === 2 && <>
                                {image ? <img src={URL.createObjectURL(image)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={endImg} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                            </>}

                        </div>

                        <h1>Aplicação</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div className="row">
                                {aplicacaoOptions.map((itemAplicacao, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" checked={itemAplicacao.checked} value={itemAplicacao.id} id={itemAplicacao.id} onChange={(e) => checkBoxChangeAplicacao(e, index)} />
                                            <label className="form-check-label" for={itemAplicacao.id}>{itemAplicacao.aplicacao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                        <button type="button" className="btn btn-success" onClick={salvarSonda}>Salvar Sonda</button>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <h3>Sondas selecionadas</h3>
                            <div className="row">
                                {sondas?.map((itemSonda) => (
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Nome: {itemSonda.nomeSonda}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted"> numero de serie: {itemSonda.numeroserieSonda}</h6>
                                            <p className="card-text">Patrimonio: {itemSonda.patrimonioSonda}</p>
                                            <p className="card-text">Laboratorio: {itemSonda.laboratorio}</p>
                                            <p className="card-text">N° certificado de calibração: {itemSonda.numerocertificadocalibracao}</p>
                                            <label className="form-label">Data de calibração:</label>
                                            <input className="card-text form-control" type="date" name="datarenovacaocalibracao" disabled value={itemSonda.datacalibracao}></input>
                                            <p className="card-text">Data de renovação da calibração:</p>
                                            <input className="card-text form-control" type="date" name="datarenovacaocalibracao" disabled value={itemSonda.datarenovacaocalibracao}></input>
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                                <b>
                                                    <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                                                </b>
                                                <select
                                                    id="inputTipoSonda"
                                                    className="form-select"
                                                    disabled
                                                    name="tipo_sonda"
                                                    onChange={valueInputSonda}
                                                    value={itemSonda.tipo_sonda}
                                                >
                                                    <option value="">Selecione</option>
                                                    {tipoSondaOptions.map((opcao) => (
                                                        <option value={opcao.id}>{opcao.tipo}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                <h3>Aplicação</h3>
                                                <div className="row">
                                                    {itemSonda.aplicacao.map((aplica, index) =>
                                                        <div key={index} className="form-check col-md-4">
                                                            <div>
                                                                <input type="checkbox" className="form-check-input" disabled checked={aplica.checked === true} value={aplica.id} id={aplica.id} onChange={(e) => checkBoxChangeAplicacaoselecionada(e, index)} />
                                                                <label className="form-check-label" for={aplica.id}>{aplica.aplicacao}</label>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </div>
                                            {/* <label for="inputArea" className="form-label"><b>Tipo:</b></label>
                                  <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea}>
                                        {areaOptions.map((option) => (
                                            <option key={option.id} value={option.id}>{option.area}</option>
                                        ))}
                                  </select> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                    <br /><br />
                    {validarClassificao == '2' && <><hr />
                        <h1>Aplicação</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div className="row">
                                {aplicacaoOptions.map((item, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" checked={item.checked} value={item.id} id={item.id} onChange={(e) => checkBoxChangeAplicacao(e, index)} />
                                            <label className="form-check-label" for={item.id}>{item.aplicacao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </>}
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/equipamentointerno"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/equipamentointerno',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};