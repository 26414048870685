import React from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';


export const OsCliente = () => {
  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className="container px-4">
          <h1>Os</h1>
        </div>
      </PageContent>
    </div>
  );
};
