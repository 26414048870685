import React, { useState, useEffect } from "react";
import api from "../../../config/configApi";

import { NavBar } from "../../../components/NavBar";
import { SideBar } from "../../../components/SideBar";
import { PageContent } from "../../../components/PageComponent";

import { format } from "date-fns";

import { Link, Redirect } from 'react-router-dom';

export const AddOs = () => {
  const criador = localStorage.getItem("nome");

  const data = new Date();
  const dataAtual = format(data, "dd/MM/yyyy");

  const [retorno, setRetorno] = useState({
    type: "",
    mensagem: "",
  });
  const [clientes, setClientes] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [servicosDaApi, setServicosDaApi] = useState([]);
  const [listaTecnicos, setListaTecnicos] = useState([]);
  const [equipamentos, setEquipamentos] = useState([]);
  const [listaServicosOs, setListaServicosOs] = useState([]);
  const [equipamentoSelecionado, setEquipamentoSelecionado] = useState({});

  const [cliente, setCliente] = useState("");

  const handleClienteChange = (e) => {
    setCliente(e.target.value);
    getEquipamentoInstituicao(e.target.value);
  };

  const [prazoEntrega, setPrazoEntrega] = useState("");

  const handlePrazoEntregaChange = (e) => {
    setPrazoEntrega(e.target.value);
  };

  const [tecnico, setTecnico] = useState("");
  const handleTecnicoChange = (e) => {
    setTecnico(e.target.value);
  };
  const [detalhes, setDetalhes] = useState("");
  const handleDetalhesChange = (e) => {
    setDetalhes(e.target.value);
  };

  const [servico, setServico] = useState("");
  const [descricao, setDescricao] = useState("");
  const [equipamento, setEquipamento] = useState("");

  const handleServicoChange = (e) => {
    setServico(e.target.value);
  };

  const handleDescricaoChange = (e) => {
    setDescricao(e.target.value);
  };

  const handleEquipamentoChange = (e) => {
    setEquipamento(e.target.value);
    getEquipamentoById(e.target.value);
  };

  const handleAdicionarServico = (e) => {
    e.preventDefault();

    const novoServicoOs = {
      descricao: descricao,
      id_servico: servico,
      servico: servicosDaApi.find((item) => item.id === parseInt(servico)).servico,
      area_id: areaId,
      area: areaOptions.find((item) => item.id === parseInt(areaId)).area,
      equipamento_id: equipamento ? equipamento : "",
      equipamento: equipamento ? equipamentos.find((item) => item.id === parseInt(equipamento)).equipamento: "",
    };

    setListaServicosOs([...listaServicosOs, novoServicoOs]);
  };

  const handleExcluirServico = (index) => {
    // Crie uma cópia da lista de serviços
    const novaListaServicos = [...listaServicosOs];

    // Remova o serviço pelo índice
    const servicoRemovido = novaListaServicos.splice(index, 1)[0];

    // Atualize o estado da lista de serviços
    setListaServicosOs(novaListaServicos);
  };

  const getListaTecnicoEmpresa = async () => {
    try {
      const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
      console.log("response usuarioTecnico", response.data);
      setListaTecnicos(response.data);
    } catch (error) {
      console.error("Erro ao obter opções de vinculo2:", error);
    }
  };

  const getEquipamentoById = async (e) => {
    try {
      const response = await api.get("/equipamento/selecionar/" + e, headers);
      console.log("response equipamento", response.data);
      setEquipamentoSelecionado(response.data);
    } catch (error) {
      console.error("Erro ao obter opções de equipamento:", error);
    }
  };

  const handleAreaChange = (e) => {
    getServicos(e.target.value);
    setAreaId(e.target.value);
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const getCliente = async () => {
    try {
      const response = await api.get("/instituicao", headers);
      if (response.data) {
        setClientes(response.data);
      } else {
        console.log("Erro: Usuário não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro");
      } else {
        console.log("Erro não encontrado");
      }
    }
  };

  const getArea = async () => {
    try {
      const response = await api.get("/area", headers);
      setAreaOptions(response.data);
    } catch (error) {
      console.error("Erro ao obter opções de vinculo2:", error);
    }
  };

  const getServicos = async (areaid) => {
    try {
      const response = await api.get("/servicoarea/" + areaid, headers);
      if (response.data) {
        setServicosDaApi(response.data);
      } else {
        console.log("Erro: serviço não encontrado !");
      }
    } catch (err) {
      if (err) {
        console.log("Erro", err);
        setServicosDaApi([]);
      } else {
        console.log("Erro não encontrado");
      }
    }
  };

  const getEquipamentoInstituicao = async (e) => {
    try {
      const response = await api.get("/equipamento/" + e, headers);
      if (response.data) {
        console.log("Equipamentos", response.data);
        setEquipamentos(response.data);
      } else {
        console.log("Erro: Equipamentos não encontrados!");
      }
    } catch (error) {
      console.log("Erro ao buscar equipamentos:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dadosGerais = {
      cliente: cliente,
      criador: criador,
      data: dataAtual,
      prazoEntrega: prazoEntrega,
      tecnico: tecnico,
      detalhes: detalhes,
    };

    const obj = {
      dadosGerais,
      listaServicosOs
    }

    console.log("obj", obj);

    await api.post('/os', obj, headers)
      .then((response) => {
        setRetorno({
          type: 'success',
          mensagem: response.data.mensagem
        });
      }).catch((err) => {
        if (err.response) {
          setRetorno({
            type: 'error',
            mensagem: err.response.data.mensagem
          });
        } else {
          setRetorno({
            type: 'error',
            mensagem: "Erro: Tente novamente!"
          });
        }
      });
  };


  useEffect(() => {
    getCliente();
    getArea();
    getListaTecnicoEmpresa();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className="container">
          <h1>Adicionar Os</h1>

          <hr />
          {/* {retorno.type === "error" ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{retorno.mensagem}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : (
            ""
          )} */}
          <form className="row">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header bg-secondary text-white">
                    <h5 className="card-title">Dados gerais</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {clientes && clientes.length > 0 && (
                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                          <label htmlFor="cliente" className="form-label">
                            Cliente
                          </label>
                          <select
                            className="form-select"
                            id="cliente"
                            name="cliente"
                            value={cliente}
                            onChange={handleClienteChange}
                            required
                          >
                            <option value="">Selecione</option>
                            {clientes.map((cliente) => (
                              <option key={cliente.id} value={cliente.id}>
                                {cliente.nome}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="criador" className="form-label">
                          Criador da OS
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="criador"
                          name="criador"
                          value={criador}
                          disabled
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="data" className="form-label">
                          Data
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="data"
                          name="data"
                          value={dataAtual}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label htmlFor="prazoEntrega" className="form-label">
                          Prazo de execução
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="prazoEntrega"
                          name="prazoEntrega"
                          value={prazoEntrega}
                          onChange={handlePrazoEntregaChange}
                          required
                        />
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                        <label for="tecnico" className="form-label">
                          Técnicos*:
                        </label>
                        <select
                          id="tecnico"
                          className={`form-select`}
                          name="tecnico"
                          onChange={handleTecnicoChange}
                          value={tecnico}
                        >
                          <option value="">Selecione</option>
                          {listaTecnicos.map((listaUsuario) => (
                            <option value={listaUsuario.id}>
                              {listaUsuario.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <label htmlFor="detalhes" className="form-label">
                          Detalhes
                        </label>
                        <textarea
                          className="form-control"
                          id="detalhes"
                          name="detalhes"
                          rows="3"
                          value={detalhes}
                          onChange={handleDetalhesChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="card mt-4">
                  <div className="card-header bg-secondary text-white">
                    <h5 className="card-title">Serviços</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <b>
                          <label for="inputArea" className="form-label">
                            Área*:
                          </label>
                        </b>
                        <select
                          id="inputArea"
                          className="form-select"
                          name="areaId"
                          onChange={handleAreaChange}
                          value={areaOptions.area}
                        >
                          <option value="">Selecione</option>
                          {areaOptions.map((opcao) => (
                            <option value={opcao.id}>{opcao.area}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <b>
                            <label className="form-label">Serviço</label>
                          </b>
                          <select
                            className="form-select"
                            id="servico"
                            name="servico"
                            value={servico}
                            onChange={handleServicoChange}
                            required
                          >
                            <option value="">Selecione</option>
                            {servicosDaApi.map((servico) => (
                              <option key={servico.id} value={servico.id}>
                                {servico.servico}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                          <label for="descricao" className="form-label">
                            Descrição:
                          </label>
                        </b>
                        <select
                          id="descricao"
                          className="form-select"
                          name="descricao"
                          value={descricao}
                          onChange={handleDescricaoChange}
                        >
                          <option value="">Selecione</option>
                          {servico === "32" && (
                            <>
                              <option value="Cálculo de Blindagem Sala de Raios X Intraoral">
                                Cálculo de Blindagem Sala de Raios X Intraoral
                              </option>
                              <option value="Cálculo de Blindagem Sala de Raios X Extraoral">
                                Cálculo de Blindagem Sala de Raios X Extraoral
                              </option>
                              <option value="Cálculo de Blindagem Sala de Raios X">
                                Cálculo de Blindagem Sala de Raios X
                              </option>
                              <option value="Cálculo de Blindagem Sala de Mamografia">
                                Cálculo de Blindagem Sala de Mamografia
                              </option>
                              <option value="Cálculo de Blindagem Sala de Hemodinâmica">
                                Cálculo de Blindagem Sala de Hemodinâmica
                              </option>
                              <option value="Cálculo de Blindagem Sala de Tomografia">
                                Cálculo de Blindagem Sala de Tomografia
                              </option>
                              <option value="Cálculo de Blindagem Sala de Densitometria Óssea">
                                Cálculo de Blindagem Sala de Densitometria Óssea
                              </option>
                              <option value="Cálculo de Blindagem Sala de Liptripsia">
                                Cálculo de Blindagem Sala de Liptripsia
                              </option>
                              <option value="Cálculo de Blindagem Sala de Arco Cirúrgico">
                                Cálculo de Blindagem Sala de Arco Cirúrgico
                              </option>
                              <option value="Plano de Proteção Radiológica e Memorial Descritivo">
                                Plano de Proteção Radiológica e Memorial
                                Descritivo
                              </option>
                              <option value="Programa de Garantia da Qualidade - Raios X">
                                Programa de Garantia da Qualidade - Raios X
                              </option>
                              <option value="Programa de Garantia da Qualidade - Mamografia">
                                Programa de Garantia da Qualidade - Mamografia
                              </option>
                              <option value="Programa de Garantia da Qualidade - Tomografia">
                                Programa de Garantia da Qualidade - Tomografia
                              </option>
                              <option value="Programa de Garantia da Qualidade - Raios X Fluoroscopia">
                                Programa de Garantia da Qualidade - Raios X
                                Fluoroscopia
                              </option>
                              <option value="Programa de Garantia da Qualidade - Raios X Intraoral">
                                Programa de Garantia da Qualidade - Raios X
                                Intraoral
                              </option>
                              <option value="Memorial Descritivo">
                                Memorial Descritivo
                              </option>
                              <option value="Programa de Garantia da Qualidade - Ressonância Magnética Nuclear">
                                Programa de Garantia da Qualidade - Ressonância
                                Magnética Nuclear
                              </option>
                              <option value="Programa de Garantia da Qualidade - Ultrassom">
                                Programa de Garantia da Qualidade - Ultrassom
                              </option>
                              <option value="Programa de Educação Continuada">
                                Programa de Educação Continuada
                              </option>
                            </>
                          )}
                          {servico === "35" && (
                            <>
                              <option value="Raios X Intraoral - bienal">
                                Raios X Intraoral - bienal
                              </option>
                              <option value="Raios X Extraoral - anual">
                                Raios X Extraoral - anual
                              </option>
                              <option value="Raios X - mensal">
                                Raios X - mensal
                              </option>
                              <option value="Raios X - semestral">
                                Raios X - semestral
                              </option>
                              <option value="Raios X - anual">
                                Raios X - anual
                              </option>
                              <option value="Mamografia - diario">
                                Mamografia - diario
                              </option>
                              <option value="Mamografia - mensal">
                                Mamografia - mensal
                              </option>
                              <option value="Mamografia - semestral">
                                Mamografia - semestral
                              </option>
                              <option value="Mamografia - anual">
                                Mamografia - anual
                              </option>
                              <option value="Tomografia - mensal">
                                Tomografia - mensal
                              </option>
                              <option value="Tomografia - semanal">
                                Tomografia - semanal
                              </option>
                              <option value="Tomografia - anual">
                                Tomografia - anual
                              </option>
                              <option value="Raios X Fluoroscopia - semanal">
                                Raios X Fluoroscopia - semanal
                              </option>
                              <option value="Raios X Fluoroscopia - mensal">
                                Raios X Fluoroscopia - mensal
                              </option>
                              <option value="Raios X Fluoroscopia - semestral">
                                Raios X Fluoroscopia - semestral
                              </option>
                              <option value="Raios X Fluoroscopia - anual">
                                Raios X Fluoroscopia - anual
                              </option>
                              <option value="Raios X Telecomandado - semanal">
                                Raios X Telecomandado - semanal
                              </option>
                              <option value="Raios X Telecomandado - semestral">
                                Raios X Telecomandado - semestral
                              </option>
                              <option value="Raios X Telecomandado - anual">
                                Raios X Telecomandado - anual
                              </option>
                              <option value="EPI - semestral">
                                EPI - semestral
                              </option>
                              <option value="EPI - anual">EPI - anual</option>
                              <option value="EPI - bienal">EPI - bienal</option>
                              <option value="Negatoscópio e/ou Monitor de Laudos - anual">
                                Negatoscópio e/ou Monitor de Laudos - anual
                              </option>
                              <option value="Sistema de Imagem Filme - Raios X - anual">
                                Sistema de Imagem Filme - Raios X - anual
                              </option>
                              <option value="Sistema de Imagem Filme - Mamografia - anual">
                                Sistema de Imagem Filme - Mamografia - anual
                              </option>
                              <option value="Sistema de Imagem CR - Raios X - anual">
                                Sistema de Imagem CR - Raios X - anual
                              </option>
                              <option value="Sistema de Imagem CR - Mamografia - anual">
                                Sistema de Imagem CR - Mamografia - anual
                              </option>
                              <option value="Densitometria óssea - mensal">
                                Densitometria óssea - mensal
                              </option>
                              <option value="Densitometria óssea - semestral">
                                Densitometria óssea - semestral
                              </option>
                              <option value="Densitometria óssea - anual">
                                Densitometria óssea - anual
                              </option>
                              <option value="Ultrassom - anual">
                                Ultrassom - anual
                              </option>
                              <option value="Ressonancia Magnética Nuclear - semanal">
                                Ressonancia Magnética Nuclear - semanal
                              </option>
                              <option value="Ressonancia Magnética Nuclear - anual">
                                Ressonancia Magnética Nuclear - anual
                              </option>
                            </>
                          )}
                          {servico === "33" && (
                            <>
                              <option value="Raios X Intraoral - Levantamento Radiométrico - quadrienal">
                                Raios X Intraoral - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X Intraoral - Radiação de Fuga do cabeçote - quadrienal">
                                Raios X Intraoral - Radiação de Fuga do cabeçote
                                - quadrienal
                              </option>
                              <option value="Raios X Intraoral - Radiação Espalhada - quadrienal">
                                Raios X Intraoral - Radiação Espalhada -
                                quadrienal
                              </option>
                              <option value="Raios X Extraoral - Levantamento Radiométrico - quadrienal">
                                Raios X Extraoral - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X - Levantamento Radiométrico - quadrienal">
                                Raios X - Levantamento Radiométrico - quadrienal
                              </option>
                              <option value="Raios X - Radiação de Fuga do cabeçote - quadrienal">
                                Raios X - Radiação de Fuga do cabeçote -
                                quadrienal
                              </option>
                              <option value="Raios X - Radiação Espalhada - quadrienal">
                                Raios X - Radiação Espalhada - quadrienal
                              </option>
                              <option value="Mamografia - Levantamento Radiométrico - quadrienal">
                                Mamografia - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Mamografia - Radiação de Fuga do cabeçote - quadrienal">
                                Mamografia - Radiação de Fuga do cabeçote -
                                quadrienal
                              </option>
                              <option value="Tomografia - Levantamento Radiométrico - quadrienal">
                                Tomografia - Levantamento Radiométrico -
                                quadrienal
                              </option>
                              <option value="Raios X Fluoroscopia - Levantamento Radiométrico - quadrienal">
                                Raios X Fluoroscopia - Levantamento Radiométrico
                                - quadrienal
                              </option>
                              <option value="Raios X Fluoroscopia - Radiação de Fuga do cabeçote - quadrienal">
                                Raios X Fluoroscopia - Radiação de Fuga do
                                cabeçote - quadrienal
                              </option>
                              <option value="Raios X Fluoroscopia - Radiação Espalhada - quadrienal">
                                Raios X Fluoroscopia - Radiação Espalhada -
                                quadrienal
                              </option>
                            </>
                          )}
                        </select>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                          <label className="form-label">Equipamento:</label>
                        </b>
                        <select
                          className={`form-select`}
                          name="equipamento"
                          onChange={handleEquipamentoChange}
                          value={equipamento}
                        >
                          <option value="">Selecione</option>
                          {equipamentos.map((opcao) => (
                            <option value={opcao.id}>
                              {opcao.equipamento}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleAdicionarServico}
                      >
                        Adicionar Serviço
                      </button>
                    </div>
                    {listaServicosOs && listaServicosOs.length > 0 && (
                      <ul>
                        {listaServicosOs.map((servico, index) => (
                          <li key={index}>
                            Serviço: {servico.servico} - Descrição:{" "}
                            {servico.descricao} - Equipamento:{" "}
                            {servico.equipamento}- Área: {servico.area}
                            <button
                              className="btn btn-danger btn-sm ms-2"
                              onClick={() => handleExcluirServico(index)}
                              type="button"
                            >
                              Excluir
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                {Object.keys(equipamentoSelecionado).length > 0 && (
                  <div className="card mt-4">
                    <div className="card-header bg-secondary text-white">
                      <h5 className="card-title">Informações do Equipamento</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Equipamento:</strong>{" "}
                            {equipamentoSelecionado.equipamento}
                          </p>
                        </div>
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Fabricante:</strong>{" "}
                            {equipamentoSelecionado.fabricante}
                          </p>
                        </div>
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Andar:</strong>{" "}
                            {equipamentoSelecionado.andar}
                          </p>
                        </div>
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Sala:</strong> {equipamentoSelecionado.sala}
                          </p>
                        </div>
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Setor:</strong>{" "}
                            {equipamentoSelecionado.setor}
                          </p>
                        </div>
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Modelo:</strong>{" "}
                            {equipamentoSelecionado.modelo}
                          </p>
                        </div>
                        <div className="col-4 col-md-6">
                          <p>
                            <strong>Nº de série:</strong>{" "}
                            {equipamentoSelecionado.numserie}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="text-center mt-4">
            <Link to={"/os"}>
              <button type="button" className="btn btn-success me-md-2">
                Voltar
              </button>
            </Link>
            {retorno.type === 'success' ?
              <Redirect to={{
                pathname: '/os',
                state: {
                  type: "success",
                  mensagem: retorno.mensagem
                }
              }} /> : ""}
            <button type="button" onClick={handleSubmit} className="btn btn-success me-md-2">
                Cadastrar
            </button>
          </div>
        </div>
      </PageContent>
    </div>
  );
};
