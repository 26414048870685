import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; 
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import 'datatables.net-bs5';

export const Licenca = (props) => {

  const [idEmpresa] = useState(props.match.params.id);
  console.log("id Empresa", idEmpresa);

  const { state } = useLocation();
  console.log("state", state);

  const [data, setData] = useState([]);
  const [status, setRetorno] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const getLicenca = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/licenca/" + idEmpresa, headers)
      .then((response) => {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        console.log("licenca", response)
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      }).catch((err) => {
        setRetorno({
          type: 'error',
          mensagem: 'Erro: Tente mais tarde!'
        });
      });

      if (dataTableRef.current) {
        if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        $(dataTableRef.current).DataTable({
          "order": [[0, "desc"]]
        });
      }
  };

  const deleteLicenca = async (idLicenca) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/licenca/" + idLicenca, headers)
        .then((response) => {
          console.log("response excluir", response);
          setRetorno({
            type: 'redSuccess',
            mensagem: response.data.mensagem
          });
          getLicenca();
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  };

  useEffect(() => {
    getLicenca();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container'>
          <h1>Licencas</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}

          <Link to={"/addlicenca/" + idEmpresa}>
            <button type="button" className="btn btn-primary">
              <i className="fa-solid fa-plus"></i>Adicionar
            </button>
          </Link>{" "}
          <Link to={"/empresa"}>
            <button type="button" className="btn btn-success me-md-2">
              Voltar
            </button>
          </Link>
          <br></br><br></br>

          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Licenca</th>
                      <th scope="col">numcevs</th>
                      <th scope="col">atividadeEconomica</th>
                      <th scope="col">status</th>
                      <th scope="col">ano</th>
                      <th scope="col">renovacao</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(licenca => (
                      <tr key={licenca.id}>
                        <th scope="row">{licenca.id}</th>
                        <td>{licenca.licenca}</td>
                        <td>{licenca.numcevs}</td>
                        <td>{licenca.atividadeEconomica}</td>
                        <td>{licenca.status}</td>
                        <td>{licenca.ano}</td>
                        <td>{licenca.renovacao}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              <Link to={"/viewlicenca/" + idEmpresa + "/" + licenca.id}>
                                <button type="button" className="btn btn-warning me-md-2">
                                  <i className="fa-solid fa-eye"></i>Visualizar
                                </button>
                              </Link>{" "}
                            </div>
                            <div>
                              <Link to={"/editarlicenca/" + idEmpresa + "/" + licenca.id}>
                                <button type="button" className="btn btn-success me-md-2">
                                  <i className="fa fa-edit"></i>Editar
                                </button>
                              </Link>{" "}
                            </div>
                            <div>
                              <Link to={"#"}>
                                <button type="button" onClick={() => deleteLicenca(licenca.id)} className="btn btn-danger me-md-2">
                                  <i className="fa-solid fa-trash"></i>
                                  Deletar
                                </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
