// Página para fazer merge entre um pdf mandado pelo úsuario e um pdf vindo do backend, porém, não está totalmente funcional

// import React, { useState } from "react";
// import { NavBar } from "../../../components/NavBar";
// import { SideBar } from "../../../components/SideBar";
// import { PageContent } from "../../../components/PageComponent";
// import { PDFDocument } from "pdf-lib";
// import api from "../../../config/configApi";

// const MergePdfByApi = () => {
//   const [backendPdf, setBackendPdf] = useState(null);
//   const [mergedPdf, setMergedPdf] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleBackendPdfChange = (event) => {
//     const file = event.target.files[0];
//     setBackendPdf(file);
//   };

//   const mergePDFs = async () => {
//     setIsLoading(true);
//     setError(null);

//     try {
//       const formData = new FormData();
//       formData.append("backendPdf", backendPdf);

//       const response = await api.post("/merge-pdfs", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//         responseType: "blob",
//       });

//       const mergedPdfBlob = new Blob([response.data]);
//       const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);
//       setMergedPdf(mergedPdfUrl);
//     } catch (error) {
//       console.error("Erro ao mesclar PDFs:", error);
//       setError("Erro ao mesclar PDFs");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="sb-nav-fixed">
//       <NavBar />
//       <SideBar />
//       <PageContent>
//         <div className="container">
//           <h1>Executar Os</h1>
//           <hr />
//           <div>
//             <h1>Mesclar PDFs</h1>
//             <input
//               type="file"
//               accept=".pdf"
//               onChange={handleBackendPdfChange}
//             />
//             <button onClick={mergePDFs} disabled={!backendPdf || isLoading}>
//               {isLoading ? "Mesclando..." : "Mesclar PDFs"}
//             </button>
//             {error && <div>{error}</div>}
//             {mergedPdf && (
//               <div>
//                 <h2>PDF Mesclado:</h2>
//                 <iframe
//                   title="merged-pdf"
//                   src={mergedPdf}
//                   style={{ width: "100%", height: "600px" }}
//                 ></iframe>
//               </div>
//             )}
//           </div>
//         </div>
//       </PageContent>
//     </div>
//   );
// };

// export default MergePdfByApi;
