import React, { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Context } from '../../Context/AuthContext';


export const NavBar = () => {
  const nome = localStorage.getItem('nome');
  const foto = localStorage.getItem('foto');
  const tipo = localStorage.getItem('tipo');

  let caminho;

  if (tipo === "sistema") {
    caminho = "/perfil"
  }
  else if (tipo === "cliente") {
    caminho = "/perfilcliente"
  }

  const { handleLogout } = useContext(Context);

  const barsSidebar = async () => {
    document.getElementById("sidebarToggle").classList.toggle("sidebar-active");
    document.body.classList.toggle('sb-sidenav-toggled');
    localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
  }

  useEffect(() => {
    barsSidebar();
  }, []);

  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark sticky-left">
      {/* <!-- Navbar Brand--> */}
      <Link className="navbar-brand ps-3" to="/dashboard">REDUTO ION</Link>
      {/* <!-- Sidebar Toggle--> */}
      <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!" onClick={() => barsSidebar()}><i className="fas fa-bars"></i></button>
      {/* <!-- Navbar Search--> */}
      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group">
        </div>
      </form>
      {/* <!-- Navbar--> */}
      <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">

       


        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" id="navbarDropdown" href="/#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small span-menu">{nome}</span>
            {typeof foto === 'string' && foto.trim() !== '' && /^https?:\/\/\S+\.(?:jpg|jpeg|png|gif)$/i.test(foto) &&
              <img className="img-profile rounded-circle" src={foto} alt="Foto do perfil"/>
            }
          </a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            {/* <li>< className="dropdown-item" >Meu perfil</a></li> */}
            <li><Link className="dropdown-item" to={caminho}>Perfil</Link></li>
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item" href="#!" onClick={handleLogout}>Logout</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  )
};
