import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, Redirect, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const EmailTreinamento = () => {
    const { state } = useLocation();

    const [data, setData] = useState([]);
    const [retorno, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const dataTableRef = useRef(null);

    const [parte1, setParte1] = useState('');
    const [parte2, setParte2] = useState('');
    const [id, setId] = useState('');

    const [emailtreinamento, setEmailTreinamento] = useState({
        id: '',
        parte1: '',
        parte2: ''
    });


    const getEmailTreinamento = async () => {
        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        };

        await api.get("/emailtreinamento", headers)
            .then((response) => {
                if (response.data) {
                    console.log("get useEffect", response.data);

                    setParte1(response.data.parte1);
                    setParte2(response.data.parte2);
                    setId(response.data.id);

                } else {
                    setRetorno({
                        type: 'redWarning',
                        mensagem: "Erro: dados não encontrados !"
                    });
                }
                //console.log(response.data);

            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'redWarning',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'redWarning',
                        mensagem: "Erro: tente mais tarde !"
                    });
                }
            })
    };

    useEffect(() => {
        getEmailTreinamento();
    }, []);

    const edtiarEmailTreinamento = async (e) => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        emailtreinamento.id = id;
        emailtreinamento.parte1 = parte1;
        emailtreinamento.parte2 = parte2;

        console.log("emailtreinamento", emailtreinamento);

        await api.post("/emailtreinamento", emailtreinamento, headers)
            .then((response) => {
                //console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })


    };



    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container px-4'>
                    <h1>Email de Treinamento</h1>
                    {retorno.type === 'success' ?
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                    <form onSubmit={edtiarEmailTreinamento} className='row'>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <b>
                                <label htmlFor="textoEmail" className="form-label">
                                    Texto do Email Parte 1:
                                </label>
                            </b>
                            <CKEditor
                                editor={ClassicEditor}
                                data={parte1}  // Inicializa o CKEditor com o valor de textoEmail
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setParte1(data);  // Atualiza textoEmail com o conteúdo do CKEditor
                                }}

                            />
                        </div>
                        <div>
                            <h3>Conteúdo do Email Parte1:</h3>
                            <div dangerouslySetInnerHTML={{ __html: parte1 }} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <b>
                                <label htmlFor="textoEmail" className="form-label">
                                    Texto do Email Parte 2:
                                </label>
                            </b>
                            <CKEditor
                                editor={ClassicEditor}
                                data={parte2}  // Inicializa o CKEditor com o valor de textoEmail
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setParte2(data);  // Atualiza textoEmail com o conteúdo do CKEditor
                                }}

                            />
                        </div>
                        <div>
                            <h3>Conteúdo do Email Parte2:</h3>
                            <div dangerouslySetInnerHTML={{ __html: parte2 }} />
                        </div>






                        <div className="col-12 mb-4 text-center">
                            <Link to={"/treinamentos"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/treinamentos',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> : ""}
                            <button type="submit" className="btn btn-success">Editar</button>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    );
};
