import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


import localforage from 'localforage';

export const AdicionarTreinamento = (props) => {

    const [treinamento, setTreinamento] = useState({
        area_id: '',
        nome: '',
        validade: '',
        data: '',
        video: '',
        ata: '',
        avaliacao: '',
        textoEmail: '',
        conteudoProgramatico: ''
    });

    const valueInput = (e) => setTreinamento({ ...treinamento, [e.target.name]: e.target.value });

    //Para a ata
    const [imageAta, setImageAta] = useState('');
    const [viewMostrarAta, setViewMostrarAta] = useState(null);
    //Para a avaliação
    const [imageAvaliacao, setImageAvaliacao] = useState('');
    const [viewMostrarAvaliacao, setViewMostrarAvaliacao] = useState(null);
    //Para o conteudo
    const [imageConteudo, setImageConteudo] = useState('');
    const [viewMostrarConteudo, setViewMostrarConteudo] = useState(null);

    const [editorData, setEditorData] = useState('');

    const [endImg] = useState('./icone_usuario.png');

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [areaOptions, setAreaOptions] = useState([]);

    const adicionarTreinamento = async e => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        treinamento.ata = imageAta;
        treinamento.avaliacao = imageAvaliacao;
        treinamento.conteudoProgramatico = imageConteudo;

        console.log("treinamentos", treinamento);

        await api.post('/treinamentos', treinamento, headers)
            .then((response) => {
                console.log(response.data);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });


    }

    const verificaTipoArquivoAta = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrarAta(1);
                setImageAta(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrarAta(2);
                setImageAta(selectedFile);
            }
        }

    }

    const verificaTipoArquivoAvaliacao = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrarAvaliacao(1);
                setImageAvaliacao(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrarAvaliacao(2);
                setImageAvaliacao(selectedFile);
            }
        }

    }

    const verificaTipoArquivoConteudo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrarConteudo(1);
                setImageConteudo(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrarConteudo(2);
                setImageConteudo(selectedFile);
            }
        }

    }

    const VisualizarPDF = async (imagem) => {
        if (imagem !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', imagem);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }


    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };


        const areaOptions = async () => {
            try {
                const response = await api.get("/area", headers);
                console.log("response typeUser", response);
                const filteredData = response.data.filter(area => area.area !== 'Medicina Nuclear e Radiodiagnóstico');

                setAreaOptions(filteredData);

            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        areaOptions();
        
    }, []);

    //Adicionar o envio de uma imagem e o preview da mesma
    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Adicionar Treinamentos</h1>
                <hr />
                <form onSubmit={adicionarTreinamento} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputArea" className="form-label">Área*:</label>
                        </b>
                        <select
                            id="inputArea"
                            className="form-select"
                            name="area_id"
                            onChange={valueInput}
                            value={areaOptions.area}
                            required
                        >
                            <option value="">Selecione</option>
                            {areaOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.area}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome*:</label>
                        </b>
                        <input type="text" autoComplete="off" name="nome" placeholder="Nome completo do usuário" className="form-control" onChange={valueInput} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Data de Criação*:</label>
                        </b>
                        <input type="date" autoComplete="off" name="data" placeholder="Digite data de criação" className="form-control" onChange={valueInput} ></input>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">validade*:</label>
                        </b>
                        <input type="date" autoComplete="off" name="validade" placeholder="Digite a validade do treinamento" className="form-control" onChange={valueInput} ></input>
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">link do video*:</label>
                        </b>
                        <input type="text" autoComplete="off" name="video" placeholder="Digite o link do treinamento" className="form-control" onChange={valueInput} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione a ata</label>
                        <input className="form-control" type="file" name="ata" id="formFile" onChange={verificaTipoArquivoAta} /><br /><br />

                        {viewMostrarAta === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageAta)} className='btn btn-success' >Visualizar PDF</button>
                        </>}

                        {viewMostrarAta === 2 && <>
                            {imageAta ? <img src={URL.createObjectURL(imageAta)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={imageAta} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                        </>}

                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione a Avaliação</label>
                        <input className="form-control" type="file" name="avaliacao" id="formFile" onChange={verificaTipoArquivoAvaliacao} /><br /><br />

                        {viewMostrarAvaliacao === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageAvaliacao)} className='btn btn-success' >Visualizar PDF</button>
                        </>}

                        {viewMostrarAvaliacao === 2 && <>
                            {imageAvaliacao ? <img src={URL.createObjectURL(imageAvaliacao)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={imageAvaliacao} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                        </>}

                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione o Conteúdo do treinamento</label>
                        <input className="form-control" type="file" name="conteudo" id="formFile" onChange={verificaTipoArquivoConteudo} /><br /><br />

                        {viewMostrarConteudo === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageConteudo)} className='btn btn-success' >Visualizar PDF</button>
                        </>}

                        {viewMostrarConteudo === 2 && <>
                            {imageConteudo ? <img src={URL.createObjectURL(imageConteudo)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={imageConteudo} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                        </>}
                    </div> */}
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <b>
                            <label htmlFor="textoEmail" className="form-label">
                                Texto do Email:
                            </label>
                        </b>
                        <CKEditor
                            editor={ClassicEditor}
                            data={treinamento.textoEmail}  // Inicializa o editor com o valor de textoEmail
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTreinamento({ ...treinamento, textoEmail: data });  // Atualiza textoEmail no estado
                            }}
                            onReady={(editor) => {
                                console.log('Editor está pronto!', editor);
                            }}
                            onBlur={(event, editor) => {
                                console.log('Editor perdeu o foco.', editor);
                            }}
                            onFocus={(event, editor) => {
                                console.log('Editor está em foco.', editor);
                            }}
                        />
                    </div>
                    <div>
                        <h3>Conteúdo do Email:</h3>
                        <div dangerouslySetInnerHTML={{ __html: treinamento.textoEmail }} />
                    </div> */}


                    <div className="col-12 mb-4 text-center">
                        <Link to={"/treinamentos"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>

                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/treinamentos',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )

}