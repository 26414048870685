import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewEmpresa = (props) => {

    const [nome, setNome] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');


    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Empresa", id);

    useEffect(() => {
        const getEmpresa = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };

            await api.get("/empresa/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        setNome(response.data.nome);
                        setCnpj(response.data.cnpj);
                        setEmail(response.data.email);
                        setTelefone(response.data.telefone);
                        setRazaoSocial(response.data.razaoSocial);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getEmpresa();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Visualizar Empresa</h1>
                    <form className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome da Empresa:</label>
                            </b>
                            <input type="text" name="nome" className="form-control" value={nome} onChange={text => setNome(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label className="form-label"><b>CNPJ</b>:</label>
                            <input type="text" name="cnpj" className="form-control" value={cnpj} onChange={text => setCnpj(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label className="form-label"><b>Email</b>:</label>
                            <input type="text" name="email" className="form-control" value={email} onChange={text => setEmail(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label className="form-label"><b>Telefone:</b></label>
                            <input type="text" name="telefone" className="form-control" value={telefone} onChange={text => setTelefone(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label className="form-label"><b>Razão Social:</b></label>
                            <input type="text" name="razaoSocial" className="form-control" value={razaoSocial} onChange={text => setRazaoSocial(text.target.value)} disabled ></input>
                        </div>
                        <div className='col-12 mb-4 text-center'>
                            <Link to={"/empresa"}>
                                <button type="button" className="btn btn-success">
                                    Voltar
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    )
}