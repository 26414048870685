import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const EditarLicenca = (props) => {

    const [licenca, setLicenca] = useState('');
    const [numcevs, setNumcevs] = useState('');
    const [atividadeEconomica, setAtividadeEconomica] = useState('');
    const [detalhe, setDetalhe] = useState('');
    const [pdf, setPdf] = useState('');
    const [descricao, setDescricao] = useState('');
    const [status, setStatus] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [ano, setAno] = useState('');
    const [renovacao, setRenovacao] = useState('');
    const [classe_id, setSelectedValueClasse] = useState(null);

    const [id] = useState(props.match.params.id);
    const [idempresa] = useState(props.match.params.idempresa);
    const empresa_id = idempresa;
    console.log("id Visualizar Empresa", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const validationSchema = yup.object().shape({
        licenca: yup.string().trim().required('Necessário preencher o campo Licença!'),
        numcevs: yup.string().trim().required('Necessário preencher o campo numcevs!'),
        atividadeEconomica: yup.string().trim().required('Necessário preencher o campo de Atividade econômica!'),
        detalhe: yup.string().trim().required('Necessário preencher o campo detalhe!'),
        pdf: yup.string().trim().required('Necessário preencher o campo pdf!'),
        descricao: yup.string().trim().required('Necessário preencher o campo descrição!'),
        status: yup.string().trim().required('Necessário preencher o campo status!'),
        estado: yup.string().trim().required('Necessário preencher o campo estado!'),
        cidade: yup.string().trim().required('Necessário preencher o campo cidade!'),
        ano: yup.string().trim().required('Necessário preencher o campo ano!'),
        renovacao: yup.string().trim().required('Necessário preencher o campo renovação!'),
        empresa_id: yup.string().trim().required('Necessário preencher o campo de empresa!')
      });

    const [classeOptions, setClasseOptions] = useState([]);

    const editarLicenca = async e => {
        e.preventDefault();
        console.log("e", e);
        console.log("id, nome, cnpj, email, telefone, razaoSocial", id, idempresa, licenca, numcevs, atividadeEconomica, detalhe, pdf, descricao, status, estado, cidade, ano, renovacao, empresa_id);

        try {
            await validationSchema.validate({licenca, numcevs, atividadeEconomica, detalhe,pdf, descricao, status, estado, cidade, ano, renovacao, empresa_id}, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao adicionar nível:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put("/licenca", { id, licenca, numcevs, atividadeEconomica, detalhe, pdf, descricao, status, estado, cidade, ano, renovacao, empresa_id, classe_id }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })


    }

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSelectChangeClasse = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueClasse(event.target.value);
    };

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        const getLicenca = async () => {

            await api.get("/licenca/idlicenca/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("id Visualizar Empresa", response.data);
                        setLicenca(response.data.licenca);
                        setNumcevs(response.data.numcevs);
                        setAtividadeEconomica(response.data.atividadeEconomica);
                        setDetalhe(response.data.detalhe);
                        setPdf(response.data.pdf);
                        setDescricao(response.data.descricao);
                        setStatus(response.data.status);
                        setEstado(response.data.estado);
                        setCidade(response.data.cidade);
                        setAno(response.data.ano);
                        setRenovacao(response.data.renovacao);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const classeOptions = async () => {
            try {
                const response = await api.get("/classe", headers);
                console.log("response typeUser", response);
                setClasseOptions(response.data); // Substitua por onde os dados necessários estão no objeto de resposta
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        classeOptions();
        getLicenca();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Editar Licenca</h1>

                    <hr />
                    {retorno.type === 'err' ?
                        <div className="alert alert-error alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}

                    <form onSubmit={editarLicenca} className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome da licenca:</label>
                            </b>
                            <input type="text" name="licenca" className={`form-control ${retorno?.licenca && 'is-invalid'}`} value={licenca} onChange={text => setLicenca(text.target.value)} ></input>
                    {retorno?.licenca && <div className="invalid-feedback">{retorno.licenca}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Numero do cevs:</label>
                            </b>
                            <input type="text" name="numcevs" className={`form-control ${retorno?.numcevs && 'is-invalid'}`} value={numcevs} onChange={text => setNumcevs(text.target.value)} ></input>
                    {retorno?.numcevs && <div className="invalid-feedback">{retorno.numcevs}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Atividade Economica:</label>
                            </b>
                            <input type="text" name="atividadeEconomica" className={`form-control ${retorno?.atividadeEconomica && 'is-invalid'}`} value={atividadeEconomica} onChange={text => setAtividadeEconomica(text.target.value)} ></input>
                    {retorno?.atividadeEconomica && <div className="invalid-feedback">{retorno.atividadeEconomica}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">detalhe:</label>
                            </b>
                            <input type="text" name="detalhe" className={`form-control ${retorno?.detalhe && 'is-invalid'}`} value={detalhe} onChange={text => setDetalhe(text.target.value)} ></input>
                    {retorno?.detalhe && <div className="invalid-feedback">{retorno.detalhe}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">pdf:</label>
                            </b>
                            <input type="text" name="pdf" className={`form-control ${retorno?.pdf && 'is-invalid'}`} value={pdf} onChange={text => setPdf(text.target.value)} ></input>
                    {retorno?.pdf && <div className="invalid-feedback">{retorno.pdf}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">descricao:</label>
                            </b>
                            <input type="text" name="descricao" className={`form-control ${retorno?.descricao && 'is-invalid'}`} value={descricao} onChange={text => setDescricao(text.target.value)} ></input>
                    {retorno?.descricao && <div className="invalid-feedback">{retorno.descricao}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputStatus" className="form-label">Status:</label>
                            </b>
                            <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange}>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                            {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputClasse" className="form-label"><b>Classe:</b></label>
                            <select id="inputClasse" className="form-select" name="classe_id" value={classe_id} onChange={handleSelectChangeClasse}>
                                {classeOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.classe}</option>
                                ))}
                            </select>
                            {retorno?.classe_id && <div className="invalid-feedback">{retorno.classe_id}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">estado:</label>
                            </b>
                            <input type="text" name="estado" className={`form-control ${retorno?.estado && 'is-invalid'}`} value={estado} onChange={text => setEstado(text.target.value)} ></input>
                            {retorno?.estado && <div className="invalid-feedback">{retorno.estado}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">cidade:</label>
                            </b>
                            <input type="text" name="cidade" className={`form-control ${retorno?.cidade && 'is-invalid'}`} value={cidade} onChange={text => setCidade(text.target.value)} ></input>
                            {retorno?.cidade && <div className="invalid-feedback">{retorno.cidade}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">ano:</label>
                            </b>
                            <input type="text" name="ano" className={`form-control ${retorno?.ano && 'is-invalid'}`} value={ano} onChange={text => setAno(text.target.value)} ></input>
                            {retorno?.ano && <div className="invalid-feedback">{retorno.ano}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">renovacao:</label>
                            </b>
                            <input type="text" name="renovacao" className={`form-control ${retorno?.renovacao && 'is-invalid'}`} value={renovacao} onChange={text => setRenovacao(text.target.value)} ></input>
                            {retorno?.renovacao && <div className="invalid-feedback">{retorno.renovacao}</div>}
                        </div>




                        <div className='col-12 mb-4 text-center'>
                            <Link to={"/licenca/" + idempresa}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/licenca/' + idempresa,
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Editar</button>
                        </div>

                    </form>
                </div>
            </PageContent>
        </div>
    )
}