import React, { useState } from 'react';
import api from '../../../config/configApi';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import { Link, Redirect } from 'react-router-dom';

export const AddCliente = () => {

  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

  const [tipoInstituicao, setTipoInstituicao] = useState('');

  const [equipamentosEnviadosComSucesso, setEquipamentosEnviadosComSucesso] = useState(0);

  const [equipamentosComErro, setEquipamentosComErro] = useState([]);

  const handleTipoInstituicaoChange = e => {
    setTipoInstituicao(e.target.value);
  };

  const handleTipoClienteChange = e => {
    setTipoCliente(e.target.value);
    setUnidadePrincipal({ ...unidadePrincipal, [e.target.name]: e.target.value })
  };

  const valueInput = e => setUnidadePrincipal({ ...unidadePrincipal, [e.target.name]: e.target.value });

  const [unidadePrincipal, setUnidadePrincipal] = useState({
    nome: '',
    endereco: '',
    bairro: '',
    cep: '',
    telefone: '',
    tipo_unidade: '',
    numero: '',
    cidadeestado: '',
    estado: '',
    cnpj: '',
    cpf: '',
    email: '',
    tipoCliente: '',
    razaosocial: '',
    equipamentos: [],
  });

  const [equipamentoInstituicao, setEquipamentoInstituicao] = useState({
    sala: '',
    andar: '',
    equipamento: '',
    fabricante: '',
    modelo: '',
    numserie: '',
    setor: '',
    instituicao_id: '',
    tipo: '',
    status: '',
  });

  const [tipo, setTipo] = useState('');

  const [tipoCliente, setTipoCliente] = useState(null);

  // No momento de salvar a instituição
  const salvarUnidadePrincipal = async (e) => {
    e.preventDefault();

    // Salva a unidade com suas salas
    const novaUnidade = {
      bairro: unidadePrincipal.bairro,
      cep: unidadePrincipal.cep,
      cidadeestado: unidadePrincipal.cidadeestado,
      cnpj: unidadePrincipal.cnpj,
      cpf: unidadePrincipal.cpf,
      email: unidadePrincipal.email,
      endereco: unidadePrincipal.endereco,
      estado: unidadePrincipal.estado,
      nome: unidadePrincipal.nome,
      numero: unidadePrincipal.numero,
      telefone: unidadePrincipal.telefone,
      tipoCliente: unidadePrincipal.tipoCliente,
      tipo_unidade: tipoInstituicao,
      equipamentos: [...unidadePrincipal.equipamentos],
    };

    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    console.log("unidadePrincipal", unidadePrincipal);

    try {
      const response = await api.post('/instituicao', novaUnidade, headers);
      //console.log("response", response);
      const idInstituicao = response.data.id;

      const equipamentosComErro = [];

      for (let i = 0; i < novaUnidade.equipamentos.length; i++) {
        const equipamento = novaUnidade.equipamentos[i];
        equipamento.instituicao_id = idInstituicao;

        try {
          await api.post('/equipamento', equipamento, headers);
        } catch (err) {
          if (err.response) {
            equipamentosComErro.push(i);
          }
        }
      }

      if (equipamentosComErro.length === 0) {
        setRetorno({
          type: 'redSuccess',
          mensagem: "Instituição e equipamentos salvos com sucesso."
        });

      } else {
        setRetorno({
          type: 'error',
          mensagem: `Erro ao enviar ${equipamentosComErro.length} equipamentos. Índices dos equipamentos com erro: ${equipamentosComErro.join(', ')}.`
        });
      }
    } catch (err) {
      if (err.response) {
        setRetorno({
          type: 'error',
          mensagem: err.response.data.mensagem
        });
      } else {
        setRetorno({
          type: 'error',
          mensagem: "Erro: Tente novamente!"
        });
      }
    }
  };




  const salvarEquipamentoInstituicao = (e) => {
    e.preventDefault();

    // Adiciona a sala à lista de salas da unidade
    equipamentoInstituicao.tipo = tipo;
    const novoEquipamentoInstituicao = { ...equipamentoInstituicao };
    setUnidadePrincipal({
      ...unidadePrincipal,
      equipamentos: [...unidadePrincipal.equipamentos, novoEquipamentoInstituicao],
    });

    // Limpa os campos após adicionar a sala
    setEquipamentoInstituicao({
      sala: '',
      setor: '',
      andar: '',
      equipamento: '',
      fabricante: '',
      modelo: '',
      numserie: '',
      tipo: '',
      status: ''
    });
  };

  const valueInputUnidade = (e) =>
    setUnidadePrincipal({ ...unidadePrincipal, [e.target.name]: e.target.value });

  const valueInputSala = (e) =>
    setEquipamentoInstituicao({ ...equipamentoInstituicao, [e.target.name]: e.target.value });

  const handleTipoEquipamentoChange = (e) => {
    setTipo(e.target.value);

  };

  const estados = [
    "Acre", "Alagoas", "Amapá", "Amazonas", "Bahia", "Ceará", "Distrito Federal", "Espírito Santo",
    "Goiás", "Maranhão", "Mato Grosso", "Mato Grosso do Sul", "Minas Gerais", "Pará", "Paraíba",
    "Paraná", "Pernambuco", "Piauí", "Rio de Janeiro", "Rio Grande do Norte", "Rio Grande do Sul",
    "Rondônia", "Roraima", "Santa Catarina", "São Paulo", "Sergipe", "Tocantins"
  ];

  const [estado, setEstado] = useState("");

  const handleEstadoChange = (e) => {
    setEstado(e.target.value);
    valueInput(e);  
  };

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container' style={{ marginLeft: '220px' }}>
          <h1>Adicionar Cliente</h1>
          <hr />
          {retorno.type === 'error' ?
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{retorno.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}
          <div className='row'>
            <h1>Instituição</h1>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Tipo Cliente:</label>
              </b>
              <select
                name="tipoCliente"
                className="form-select"
                onChange={handleTipoClienteChange}
                value={tipoCliente}
              >
                <option value="">Selecione o tipo</option>
                <option value="PJ">Pessoa Juridica</option>
                <option value="PL">Profissional Liberal</option>
              </select>
            </div>
            {tipoCliente === "PL" && (<>
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">CPF:</label>
                </b>
                <input type="text" name="cpf" className={`form-control`} onChange={valueInput} ></input>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Profissional Liberal:</label>
                </b>
                <input type="text" name="nome" className="form-control" onChange={valueInput} />
              </div>
            </>)}
            {tipoCliente === "PJ" && (
              <>
                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                  <b>
                    <label className="form-label">CNPJ:</label>
                  </b>
                  <input type="text" name="cnpj" className="form-control" onChange={valueInput} />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                  <b>
                    <label className="form-label">Razão Social:</label>
                  </b>
                  <input type="text" name="nome" className="form-control" onChange={valueInput} />
                </div>
              </>
            )}

            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Estado:</label>
              </b>
              <select name="estado" className="form-select" value={estado} onChange={handleEstadoChange}>
                <option value="">Selecione um estado</option>
                {estados.map((estado) => (
                  <option key={estado} value={estado}>{estado}</option>
                ))}
              </select>
            </div>

            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Cidade:</label>
              </b>
              <input type="text" name="cidadeestado" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Endereço:</label>
              </b>
              <input type="text" name="endereco" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Número:</label>
              </b>
              <input type="text" name="numero" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Bairro:</label>
              </b>
              <input type="text" name="bairro" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Cep:</label>
              </b>
              <input type="text" name="cep" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Telefone:</label>
              </b>
              <input type="text" name="telefone" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Tipo de Instituição:</label>
              </b>
              <select
                name="tipo_instituicao"
                className="form-select"
                onChange={handleTipoInstituicaoChange}
                value={tipoInstituicao}
              >
                <option value="">Selecione o tipo</option>
                <option value="1">Matriz</option>
                <option value="2">Filial</option>
              </select>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Email:</label>
              </b>
              <input type="email" name="email" className={`form-control`} onChange={valueInput} ></input>
            </div>
            <hr></hr>
            <h1>Vincular equipamentos:</h1>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Tipo de Equipamento:</label>
                </b>
                <select
                  name="tipo"
                  className="form-select"
                  onChange={handleTipoEquipamentoChange}
                  value={tipo}
                >
                  <option value="">Selecione o tipo de equipamento</option>
                  <option value="fixo">Fixo</option>
                  <option value="movel">Móvel</option>
                </select>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Status do Equipamento:</label>
                </b>
                <select
                  name="status"
                  className="form-select"
                  onChange={valueInputSala}
                  value={equipamentoInstituicao.status}
                >
                  <option value="">Selecione o tipo de equipamento</option>
                  <option value="ativo">Ativo</option>
                  <option value="inativo">Inativo</option>
                </select>
              </div>
              {tipo === 'fixo' && (
                <>
                  {/* Campos específicos para equipamento fixo */}
                  <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                    <b>
                      <label className="form-label">Sala:</label>
                    </b>
                    <input
                      type="text"
                      name="sala"
                      className={`form-control`}
                      onChange={valueInputSala}
                      value={equipamentoInstituicao.sala}
                    ></input>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                    <b>
                      <label className="form-label">Andar:</label>
                    </b>
                    <input
                      type="text"
                      name="andar"
                      className={`form-control`}
                      onChange={valueInputSala}
                      value={equipamentoInstituicao.andar}
                    ></input>
                  </div>
                </>
              )}
              {/* Campos comuns para ambos os tipos de equipamento */}
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Setor:</label>
                </b>
                <input
                  type="text"
                  name="setor"
                  className={`form-control`}
                  onChange={valueInputSala}
                  value={equipamentoInstituicao.setor}
                ></input>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Equipamento:</label>
                </b>
                <input
                  type="text"
                  name="equipamento"
                  className={`form-control`}
                  onChange={valueInputSala}
                  value={equipamentoInstituicao.equipamento}
                ></input>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Fabricante:</label>
                </b>
                <input
                  type="text"
                  name="fabricante"
                  className={`form-control`}
                  onChange={valueInputSala}
                  value={equipamentoInstituicao.fabricante}
                ></input>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Modelo:</label>
                </b>
                <input
                  type="text"
                  name="modelo"
                  className={`form-control`}
                  onChange={valueInputSala}
                  value={equipamentoInstituicao.modelo}
                ></input>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                <b>
                  <label className="form-label">Número de Série:</label>
                </b>
                <input
                  type="text"
                  name="numserie"
                  className={`form-control`}
                  onChange={valueInputSala}
                  value={equipamentoInstituicao.numserie}
                ></input>
              </div>
              <div className="col-12 mb-4 text-center">
                <button onClick={salvarEquipamentoInstituicao} className="btn btn-success">
                  Atribuir equipamento
                </button>
              </div>
            </div>
            {/* Seção para mostrar os equipamentos atribuídos */}
            <div className="row mt-4">
              <div className="col-12">
                <h2>Equipamentos Atribuídos</h2>
                {unidadePrincipal.equipamentos.length > 0 ? (
                  <ul>
                    {unidadePrincipal.equipamentos.map((equipamento, index) => (
                      <li key={index}>
                        {equipamento.tipo === 'fixo' ? (
                          <span>
                            Sala: {equipamento.sala}, Andar: {equipamento.andar}, Setor: {equipamento.setor}, Equipamento: {equipamento.equipamento}, Fabricante: {equipamento.fabricante}, Modelo: {equipamento.modelo}, Número de Série: {equipamento.numserie}
                          </span>
                        ) : (
                          <span>
                            Setor: {equipamento.setor}, Equipamento: {equipamento.equipamento}, Fabricante: {equipamento.fabricante}, Modelo: {equipamento.modelo}, Número de Série: {equipamento.numserie}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Nenhum equipamento atribuído ainda.</p>
                )}
              </div>
            </div>
            <div className='col-12 mb-4 text-center'>
              <Link to={"/cliente"}>
                <button type="button" className="btn btn-success me-md-2">
                  Voltar
                </button>
              </Link>
              {retorno.type === 'redSuccess' ?
                <Redirect to={{
                  pathname: '/cliente',
                  state: {
                    type: "success",
                    mensagem: retorno.mensagem
                  }

                }} /> :
                ""}
              <button onClick={salvarUnidadePrincipal} className="btn btn-success">Salvar</button>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
};