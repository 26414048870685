import React, { useState, useEffect } from 'react';
import localforage from 'localforage';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const ViewPdf = () => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const newplugin = defaultLayoutPlugin();

    const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

    useEffect(() => {
        const receberDadosLocalForage = async () => {
            try {
                // Obtenha os dados do localforage
                const dados = await localforage.getItem('pdfData');
                console.log("dados", dados);

                if (typeof dados === 'string') {
                    setPdfUrl(dados);
                } else if (dados instanceof File) {
                    const fileReader = new FileReader();

                    fileReader.onload = function (e) {
                        setPdfUrl(e.target.result);
                    };

                    fileReader.readAsDataURL(dados);
                } else {
                    console.error('Tipo de dados não suportado');
                }
            } catch (error) {
                console.error('Erro ao obter os dados do localforage:', error);
            }
        };

        receberDadosLocalForage();
    }, []);

    return (
        <div>
            <Worker workerUrl={workerUrl}>
                {pdfUrl && <Viewer fileUrl={pdfUrl} plugins={[newplugin]} />}
                {!pdfUrl && <>NO PDF</>}
            </Worker>
        </div>
    );
};