import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

export const EditarFuncionarioInstituicao = (props) => {
  const [id] = useState(props.match.params.id);
  const [idinstituicao] = useState(props.match.params.idinstituicao);

  //console.log("id Visualizar Empresa", id);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [crm, setCrm] = useState('');
  const [senha, setSenha] = useState('');
  const [cargo_id, setCargo_id] = useState('');
  const [cargos, setCargos] = useState([]);
  
  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

    const editarFuncionarioInstituicao = async e => {
        e.preventDefault();        
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        
        var instituicao_id = idinstituicao;
        await api.put("/funcionario", { id, nome, cpf, email, crm, senha, cargo_id, instituicao_id}, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })
    }

    const handleCargoChange = (e) => {
      setCargo_id(e.target.value);
    };
  
    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        
        const getFuncionarioInstituicao = async () => {
            
    
            await api.get("/funcionario/selecionar/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        setNome(response.data.nome);
                        setCpf(response.data.cpf);
                        setEmail(response.data.email);
                        setCrm(response.data.crm);
                        setCargo_id(response.data.cargo_id);
                        
                    } else {
                        console.log("Erro: Funcionario não encontrado !");
                    }
    
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCargo = async () => {
          await api.get("/cargo", headers)
              .then((response) => {
                  console.log("response", response.data);
                  setCargos(response.data);
              }).catch((err) => {
                  if (err) {
                      console.log("err", err);
                  }
              });
        }

        getCargo();
        getFuncionarioInstituicao();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
            <div className='container' style={{ marginLeft: '220px' }}>
            <h1>Editar Funcionario</h1>
                <hr />
                {retorno.type === 'err' ? 
                        <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                        
                <form onSubmit={editarFuncionarioInstituicao} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nome" className={`form-control`} value={nome} onChange={text => setNome(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Cpf:</label>
                        </b>
                        <input type="text" name="cpf" className={`form-control`} value={cpf} onChange={text => setCpf(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">E-mail:</label>
                        </b>
                        <input type="text" name="email" className={`form-control`} value={email} onChange={text => setEmail(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Crm:</label>
                        </b>
                        <input type="text" name="crm" className={`form-control`} value={crm} onChange={text => setCrm(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Senha:</label>
                        </b>
                        <input type="password" name="senha" className={`form-control`} value={senha} onChange={text => setSenha(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Cargo:</label>
                        </b>
                        <select
                            name="cargo"
                            className="form-select"
                            onChange={handleCargoChange}
                            value={cargo_id}
                        >
                            <option value="">Selecione o cargo</option>
                            {cargos.map((cargoItem) => (
                                <option key={cargoItem.id} value={cargoItem.id}>
                                    {cargoItem.cargo}
                                </option>
                            ))}
                        </select>
                      </div>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/addfuncionarioinstituicao/" + idinstituicao}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/addfuncionarioinstituicao/' + idinstituicao,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Editar</button>
                    </div>

                </form>
            </div>
            </PageContent>
        </div>
    )
}