import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const EditarNivel = (props) => {

    const [selectedValue, setSelectedValue] = useState('');
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');

    const [permissionsOptions, setPermissionsOptions] = useState([]);
    const [checkedValues, setValue] = useState([]);

    const [id] = useState(props.match.params.id);
    console.log("id Editar Nivel", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const validationSchema = yup.object().shape({
        type: yup.string().trim().required('Necessário preencher o campo Nível!'),
        status: yup.string().trim().required('Necessário selecionar o campo Status!'),
        permissoes: yup.array().min(1, 'Selecione pelo menos uma permissão!'),
    });

    const editUser = async e => {
        e.preventDefault();
        console.log("e", e);
        console.log("id, type, email", id, type, status, permissionsOptions);

        try {
            await validationSchema.validate({type, status, checkedValues}, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao adicionar nível:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        console.log("olhe aqui", id, type, status, permissionsOptions);

        await api.put("/typeuser", { id, type, status, permissionsOptions }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })


    }

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const checkBoxChange = (e, index) => {

        let res = [...permissionsOptions]
        res[index].checked = e.target.checked
        setPermissionsOptions(res)

        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const getNivel = async (id) => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.get("/typeuser/" + id, headers)
            .then((response) => {
                if (response.data) {
                    console.log("typeuser", response.data);
                    setType(response.data.type);
                    setStatus(response.data.status);
                    //lectedValue(response.data.status);
                    setPermissionsOptions(response.data.permissoes);
                } else {
                    console.log("Erro: Usuário não encontrado !");
                }

            }).catch((err) => {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro  não encontrado");
                }
            })
    }

    useEffect(() => {
        getNivel(id);
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Editar Nivel</h1>

                    <hr />
                    {retorno.type === 'err' ?
                        <div className="alert alert-error alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}

                    <form onSubmit={editUser} className='row'>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nivel:</label>
                            </b>
                            <input type="text" name="type" className={`form-control ${retorno?.type && 'is-invalid'}`} value={type} onChange={text => setType(text.target.value)} ></input>
                            {retorno?.type && <div className="invalid-feedback">{retorno.type}</div>}

                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputStatus" className="form-label">Status:</label>
                            </b>
                            <select id="inputStatus" className={`form-select ${retorno?.status && 'is-invalid'}`} name="status" value={status} onChange={handleSelectChange}>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                            {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}
                        </div>
                        <div class="form-group">
                            <div class="row">
                                {permissionsOptions.map((item, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" checked={item.checked} value={item.id} id={item.id} onChange={(e) => checkBoxChange(e, index)} />
                                            <label class="form-check-label" for={item.id}>{item.descricao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>


                        <div className='col-12 mb-4 text-center'>

                            <Link to={"/niveis"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/niveis',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Editar</button>
                        </div>

                    </form>
                </div>
            </PageContent>
        </div>
    )
}