import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewEquipamentoInstituicao = (props) => {

    const [sala, setSala] = useState('');
    const [setor, setSetor] = useState('');
    const [andar, setAndar] = useState('');
    const [equipamento, setEquipamento] = useState('');
    const [fabricante, setFabricante] = useState('');
    const [modelo, setModelo] = useState('');
    const [numserie, setNumserie] = useState('');
    const [tipo, setTipo] = useState('');
    const [status, setStatus] = useState('');

    const [id] = useState(props.match.params.id);
    const [idinstituicao] = useState(props.match.params.idinstituicao);
    console.log("id Visualizar Empresa", id);

    useEffect(() => {
      const getEquipamentoInstituicao = async () => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.get("/equipamento/selecionar/" + id, headers)
            .then((response) => {
              console.log("response", response);
                if (response.data) {
                    setTipo(response.data.tipo);
                    setSala(response.data.sala);
                    setSetor(response.data.setor);
                    setAndar(response.data.andar);
                    setEquipamento(response.data.equipamento);
                    setFabricante(response.data.fabricante);
                    setModelo(response.data.modelo);
                    setNumserie(response.data.numserie);
                    setStatus(response.data.status);
                } else {
                    console.log("Erro: equipamento não encontrado !");
                }

            }).catch((err) => {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro  não encontrado");
                }
            })
          }
          getEquipamentoInstituicao();
    }, [id]);

    const handleStatusChange = (e) => {
      setStatus(e.target.value);
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container' style={{ marginLeft: '220px' }}>
                    <h1>Visualizar Equipamento</h1>
                    <form className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label className="form-label">Sala:</label>
                      </b>
                      <input
                        type="text"
                        name="sala"
                        className="form-control"
                        value={sala}
                        onChange={(e) => setSala(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Setor:</b></label>
                      <input
                        type="text"
                        name="setor"
                        className="form-control"
                        value={setor}
                        onChange={(e) => setSetor(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Andar:</b></label>
                      <input
                        type="text"
                        name="andar"
                        className="form-control"
                        value={andar}
                        onChange={(e) => setAndar(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Equipamento:</b></label>
                      <input
                        type="text"
                        name="equipamento"
                        className="form-control"
                        value={equipamento}
                        onChange={(e) => setEquipamento(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Fabricante:</b></label>
                      <input
                        type="text"
                        name="fabricante"
                        className="form-control"
                        value={fabricante}
                        onChange={(e) => setFabricante(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Modelo:</b></label>
                      <input
                        type="text"
                        name="modelo"
                        className="form-control"
                        value={modelo}
                        onChange={(e) => setModelo(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Número de Série:</b></label>
                      <input
                        type="text"
                        name="numSerie"
                        className="form-control"
                        value={numserie}
                        onChange={(e) => setNumserie(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                      <label className="form-label"><b>Tipo:</b></label>
                      <input
                        type="text"
                        name="tipo"
                        className="form-control"
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        disabled
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                          <label className="form-label">Status do Equipamento:</label>
                        </b>
                        <select name='status' value={status} disabled onChange={handleStatusChange} className='form-select'>
                          <option value="">Selecione</option>
                          <option value="ativo">Ativo</option>
                          <option value="inativo">Inativo</option>
                        </select>
                    </div>
                    <div className='col-12 mb-4 text-center'>
                      <Link to={"/addequipamentoinstituicao/" + idinstituicao}>
                        <button type="button" className="btn btn-success">
                          Voltar
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
            </PageContent>
        </div>
    )
}