import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link } from 'react-router-dom';

import localforage from 'localforage';

export const ViewEquipamentoInterno = (props) => {

    const [nome, setNome] = useState('');
    const [numeroserie, setNumeroSerie] = useState('');
    const [patrimonio, setPatrimonio] = useState('');
    const [status, setStatus] = useState('');
    const [vinculo, setVinculo] = useState('');
    const [tecnico, setTecnico] = useState('');

    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Equipamento Interno", id);

    const [empresaOptions, setEmpresaOptions] = useState([]);
    const [checkedValues, setValue] = useState([]);

    const [sondaOptions, setSondaOptions] = useState([]);
    const [tipoEquipamentoOptions, settipoEquipamentoOptions] = useState([]);
    const [tipo_equipamento_id, setSelectedValueTipoEquipamento] = useState(null);
    const [listaTecnicoEmpresa, setListaTecnicoEmpresaOptions] = useState([]);

    const [tipoSondaOptions, setTipoSondaOptions] = useState([]);


    const [aplicacaoOptions, setAplicacaoOptions] = useState([]);

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSelectChangeVinculo = (event) => {
        setVinculo(event.target.value);
    };

    const checkBoxChange = (e, index) => {

        let res = [...empresaOptions];
        res[index].checked = e.target.checked;
        setEmpresaOptions(res);

        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);

        console.log("index", index);

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const handleSelectChangeTipoEquipamento = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueTipoEquipamento(event.target.value);
    };

    const handleSelectChangeTecnico = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueTipoEquipamento(event.target.value);
    }

    const VisualizarPDF = async (e) => {
        console.log(e, "e");
        if (e !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', e);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }



    console.log("checkedValues", checkedValues);

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getEquipamentoInterno = async () => {

            await api.get("/equipamentointerno/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response olhe aqui sondas", response.data);
                        setNome(response.data.nome);
                        setNumeroSerie(response.data.numeroserie);
                        setPatrimonio(response.data.patrimonio);
                        setStatus(response.data.status);
                        setVinculo(response.data.vinculo);
                        setTecnico(response.data.usuario_id);
                        setEmpresaOptions(response.data.empresas);
                        setAplicacaoOptions(response.data.aplicacao);
                        setSondaOptions(response.data.sonda);
                        setSelectedValueTipoEquipamento(response.data.tipo_equipamento_id);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        const tipoEquipamentoOptions = async () => {
            try {
                const response = await api.get("/tipoequipamento", headers);
                console.log("response tipoequipamento", response.data);
                settipoEquipamentoOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }
        const tipoSonda = async () => {
            try {
                const response = await api.get("/tiposonda", headers);
                console.log("response classe", response);
                setTipoSondaOptions(response.data); // Substitua por onde os dados necessários estão no objeto de resposta
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }
        const listaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnico", headers);
                console.log("response usuarioTecnico", response.data);
                setListaTecnicoEmpresaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        tipoSonda();
        getEquipamentoInterno();
        tipoEquipamentoOptions();
        listaTecnicoEmpresa();

        //console.log("sondaOptions", sondaOptions);

        //empresaOptions();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Visualizar Equipamento Interno</h1>
                <hr />
                <form className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nome" className="form-control" value={nome} onChange={text => setNome(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">N° de serie:</label>
                        </b>
                        <input type="text" name="numeroserie" className="form-control" value={numeroserie} onChange={text => setNumeroSerie(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Patrimonio:</label>
                        </b>
                        <input type="text" name="patrimonio" className="form-control" value={patrimonio} onChange={text => setPatrimonio(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputStatus" className="form-label">Status:</label>
                        </b>
                        <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange} disabled>
                            <option value="vencido">Vencido</option>
                            <option value="válido">Válido</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputVinculo" className="form-label">Vínculo:</label>
                        </b>
                        <select id="inputVinculo" className="form-select" name="vinculo" value={vinculo} onChange={handleSelectChangeVinculo} disabled>
                            <option value="sede">Sede</option>
                            <option value="tecnico">Tecnico</option>
                            <option value="comodato">Comodato</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <label for="inputTipoEquipamento" className="form-label"><b>Classificação:</b></label>
                        <select id="inputTipoEquipamento" className="form-select" disabled name="tipo_equipamento_id" value={tipo_equipamento_id} onChange={handleSelectChangeTipoEquipamento}>
                            {tipoEquipamentoOptions.map((option) => (
                                <option key={option.id} value={option.id}>{option.tipo}</option>
                            ))}
                        </select>
                    </div>
                    {tecnico != undefined ? <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputUsuario" className="form-label">Técnicos*:</label>
                        </b>
                        <select
                            id="inputUsuario"
                            className="form-select"
                            name="usuario_id"
                            disabled
                            onChange={handleSelectChangeTecnico}
                            value={tecnico}
                        >
                            <option value="">Selecione</option>
                            {listaTecnicoEmpresa.map((listaUsuario) => (
                                <option value={listaUsuario.id}>{listaUsuario.nome}</option>
                            ))}
                        </select>
                    </div> : " "}
                    <hr />
                    <h1>Empresas vinculadas ao equipamento</h1>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <div class="row">
                            {empresaOptions?.map((item, index) =>
                                <div key={index} className="form-check col-md-4">
                                    <div>
                                        <input type="checkbox" className="form-check-input" disabled checked={item.checked} value={item.id} id={item.id} onChange={(e) => checkBoxChange(e, index)} />
                                        <label className="form-check-label" for={item.id}>{item.nome}</label>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                    {tipo_equipamento_id == '2' && <><hr />
                        <h1>Aplicação</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div class="row">
                                {aplicacaoOptions.map((item, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" disabled checked={item.checked} value={item.id} id={item.id} />
                                            <label className="form-check-label" for={item.id}>{item.aplicacao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </>}
                    {tipo_equipamento_id == '1' && <><hr />
                        <h2>Sondas vinculadas</h2>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div className="row">
                                {sondaOptions?.map((item) => (
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Nome: {item.nome}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted"> numero de serie: {item.numeroserie}</h6>
                                            <p class="card-text">Patrimonio: {item.patrimonio}</p>
                                            <p class="card-text">Laboratorio: {item.laboratorio}</p>
                                            <p class="card-text">N° certificado de calibração: {item.numcertificadocalibracao}</p>
                                            <label className="form-label">Data de calibração:</label>
                                            <input class="card-text" type="text" name="datarenovacaocalibracao" disabled value={item.data_calibracao} className="form-control"></input>
                                            <p class="card-text">Data de renovação da calibração:</p>
                                            <input class="card-text" type="text" name="datarenovacaocalibracao" disabled value={item.data_renovacao_calibracao} className="form-control"></input>
                                            <br />
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                                {item.tipoarquivocertificado === 'pdf' && <>
                                                    <button type='button' onClick={() => VisualizarPDF(item.endImagem)} className="btn btn-success">
                                                        Visualizar PDF
                                                    </button>
                                                </>}

                                                {item.tipoarquivocertificado === 'imagem' && <>
                                                    {item.endImagem ? <img src={item.endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={item.endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                                                </>}

                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                                <b>
                                                    <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                                                </b>
                                                <select
                                                    id="inputTipoSonda"
                                                    className="form-select"
                                                    disabled
                                                    name="tipo_sonda"
                                                    value={item.tipo_sonda_id}
                                                >
                                                    <option value="">Selecione</option>
                                                    {tipoSondaOptions.map((opcao) => (
                                                        <option value={opcao.id}>{opcao.tipo}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                <h3>Aplicação vinculadas</h3>
                                                <div class="row">
                                                    {item.aplicacao.map((aplica, index) =>
                                                        <div key={index} className="form-check col-md-4">
                                                            <div>
                                                                <input type="checkbox" className="form-check-input" disabled checked={aplica.checked} value={aplica.id} id={aplica.id} />
                                                                <label className="form-check-label" for={aplica.id}>{aplica.aplicacao}</label>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </div>
                                            {/* <label for="inputArea" className="form-label"><b>Tipo:</b></label>
                                  <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea}>
                                        {areaOptions.map((option) => (
                                            <option key={option.id} value={option.id}>{option.area}</option>
                                        ))}
                                  </select> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}

                    <div className="col-12 mb-4 text-center">
                        <Link to={"/equipamentointerno"}>
                            <button type="button" className="btn btn-success">
                                Voltar
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}