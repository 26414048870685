import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ dados }) => {
  
  const data = {
    labels: dados.map(dado => dado.mesDescricao),
    datasets: [
      {
        label: 'Em Análise',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        data: dados.map(dado => dado.statusEmAnalise)
      },
      {
        label: 'Aprovado',
        backgroundColor: 'rgba(54,162,235,1)',
        borderColor: 'rgba(54,162,235,1)',
        borderWidth: 1,
        data: dados.map(dado => dado.statusAprovado)
      },
      {
        label: 'Rejeitado',
        backgroundColor: 'rgba(255,99,132,1)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        data: dados.map(dado => dado.statusRejeitado)
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Status Mensal',
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;