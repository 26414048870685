import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

import localforage from 'localforage';

export const Financeiro = () => {
    const { state } = useLocation();

    const [data, setData] = useState([]);
    const [status, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const dataTableRef = useRef(null);

    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const getFinanceiro = async () => {

        try {
            if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable().destroy();
            }
            const response = await api.get("/orcamentoAprovado/", headers);
            console.log("orcamentoAprovado", response);
            setData(response.data);
            if (dataTableRef.current) {
                $(dataTableRef.current).DataTable({
                    retrieve: true,
                    "order": [[0, "desc"]]
                });
            }
        } catch (err) {
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    };

    useEffect(() => {
        getFinanceiro();
    }, []);

    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataNf, setSelectedDataNf] = useState(null);

    const handleViewClick = async (id) => {
        try {

            const response = await api.get("/orcamentoAprovado/" + id, headers);
            if (response.data) {
                console.log("response.data", response.data);
                setSelectedData(response.data);
            } else {
                console.log("Erro: Usuário não encontrado !");
            }
        } catch (err) {
            console.log("Erro ao buscar dados:", err);
        }
    };

    const handleViewClickNf = async (id) => {
        try {

            const response = await api.get("/orcamentoAprovadoNF/" + id, headers);
            if (response.data) {
                setSelectedDataNf(response.data);
            } else {
                console.log("Erro: Usuário não encontrado !");
            }
        } catch (err) {
            console.log("Erro ao buscar dados:", err);
        }
    };

    const groupArrayElements = (arr, groupSize) => {
        if (!Array.isArray(arr)) return [];
        const groups = [];
        for (let i = 0; i < arr.length; i += groupSize) {
            groups.push(arr.slice(i, i + groupSize));
        }
        return groups;
    };

    const parseOsGerada = (osGeradaString) => {
        try {
            return JSON.parse(osGeradaString);
        } catch (e) {
            console.error("Erro ao parsear osGerada:", e);
            return [];
        }
    };

    const VisualizarPDF = async (imagem) => {
        console.log("imagem", imagem);
        if (imagem !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', imagem);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const handleFileChange = async (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = file;
            const updatedOs = [...selectedDataNf.os];
            updatedOs[index] = { ...updatedOs[index], nfImagem: imageUrl };
            setSelectedDataNf({ ...selectedDataNf, os: updatedOs });
        }
    };

    const salvarImagem = async e => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        console.log("selectedDataNf", selectedDataNf);

        for (let index = 0; index < selectedDataNf.os.length; index++) {
            const element = selectedDataNf.os[index];

            var id = element.idos;
            var nfImagem = element.nfImagem;

            await api.put("/OsOrcamentoAprovadoImagem", { id, nfImagem }, headers)
                .then((response) => {
                    //console.log("response", response);
                    setRetorno({
                        type: 'redSuccess',
                        mensagem: response.data.mensagem
                    });
                }).catch((err) => {
                    if (err) {
                        setRetorno({
                            type: 'error',
                            mensagem: err.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'error',
                            mensagem: 'Tente novamente mais tarde!'
                        })
                    }
                })

        }

    }





    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container px-4'>
                    <h1>Financeiro</h1>

                    <div className='card mt-5'>
                        <div className='card-body'>
                            <div className="table-responsive">
                                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cidade/Estado</th>
                                            <th scope="col">cliente</th>
                                            <th scope="col">Id orçamento</th>
                                            <th scope="col">Id OS</th>
                                            <th scope="col">Valor orçamento</th>
                                            <th scope="col">N° Parcelas</th>
                                            <th scope="col">Vendedor</th>
                                            <th scope="col">status</th>
                                            <th scope="col">data Aprovação</th>
                                            <th scope="col">tipo</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(orcamentoapr => (
                                            <tr key={orcamentoapr.id}>
                                                <th scope="row">{orcamentoapr.cidade}/{orcamentoapr.estado}</th>
                                                <td>{orcamentoapr.instituicao}</td>
                                                <td>{orcamentoapr.id}</td>
                                                <td>
                                                    {groupArrayElements(parseOsGerada(orcamentoapr.osGerada), 12).map((group, index) => (
                                                        <div key={index}>
                                                            {group.join(', ')}
                                                        </div>
                                                    ))}
                                                </td>
                                                <td>R${orcamentoapr.valortotalfloat}</td>
                                                <td>{orcamentoapr.nparcelas}</td>
                                                <td>{orcamentoapr.vendedor}</td>
                                                <td>{orcamentoapr.status}</td>
                                                <td>{orcamentoapr.dataAprovacao}</td>
                                                <td>{orcamentoapr.tipoCliente}</td>
                                                <td>
                                                    <div className="d-flex flex-row mb-3">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-warning me-md-2"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                                onClick={() => handleViewClick(orcamentoapr.id)}
                                                            >
                                                                <i className="fa-solid fa-eye"></i>Visualizar
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary me-md-2"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#NF"
                                                                onClick={() => handleViewClickNf(orcamentoapr.id)}
                                                            >
                                                                <i className="fa-solid fa-eye"></i>NF
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detalhes do Orçamento</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {selectedData ? (
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-6"><strong>Cidade/Estado:</strong> {selectedData.cidade}/{selectedData.estado}</div>
                                                <div className="col-md-6"><strong>Cliente:</strong> {selectedData.instituicao}</div>
                                                <div className="col-md-6"><strong>Email:</strong> {selectedData.email}</div>
                                                <div className="col-md-6"><strong>Id Orçamento:</strong> {selectedData.id}</div>
                                                <div className="col-md-6"><strong>Id OS:</strong> {groupArrayElements(parseOsGerada(selectedData.osGerada), 9).map((group, index) => (
                                                    <div key={index}>
                                                        {group.join(', ')}
                                                    </div>
                                                ))}</div>
                                                <div className="col-md-6"><strong>Valor Orçamento:</strong> R${selectedData.valortotalfloat}</div>
                                                <div className="col-md-6"><strong>N° Parcelas:</strong> {selectedData.nparcelas}</div>
                                                <div className="col-md-6"><strong>Vendedor:</strong> {selectedData.vendedor}</div>
                                                <div className="col-md-6"><strong>Status:</strong> {selectedData.status}</div>
                                                <div className="col-md-6"><strong>Data Aprovação:</strong> {selectedData.dataAprovacao}</div>
                                                <div className="col-md-6"><strong>Tipo:</strong> {selectedData.tipoCliente}</div>
                                                <div className="col-md-6"><strong>Forma de Pagamento:</strong> {selectedData.formapagamento}</div>
                                                {selectedData.tipoCliente === "PF" && (
                                                    <div className="col-md-6"><strong>CPF:</strong> {selectedData.cpf}</div>
                                                )}
                                                {selectedData.tipoCliente === "PJ" && (
                                                    <div className="col-md-6"><strong>CNPJ:</strong> {selectedData.cnpj}</div>
                                                )}

                                                <div className="col-md-6"><strong>CEP:</strong> {selectedData.cep}</div>
                                                <div className="col-md-6"><strong>Endereço:</strong> {selectedData.endereco}</div>
                                                <div className="col-md-6"><strong>Número:</strong> {selectedData.numero}</div>
                                                <div className="col-md-6"><strong>Bairro:</strong> {selectedData.bairro}</div>
                                                <div className="col-md-6"><strong>Telefone:</strong> {selectedData.telefone}</div>
                                                <hr />
                                                <h2>Serviços Contratados</h2>
                                                {selectedData.temservico.map((servico, index) => (
                                                    <div className="card mb-3" key={index}>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-3"><strong>Area:</strong> {servico.area}</div>
                                                                <div className="col-md-3"><strong>Descrição:</strong> {servico.descricao}</div>
                                                                <div className="col-md-3"><strong>Serviço:</strong> {servico.servico}</div>
                                                                <div className="col-md-6"><strong>Quantidade:</strong> {servico.quantidade}</div>
                                                                {/* <div className="col-md-6"><strong>Valor:</strong> R${servico.valor}</div>
                                                                <div className="col-md-6"><strong>Total:</strong> R${servico.subtotal}</div>
                                                                <div className="col-md-6"><strong>Modelo de Contrato:</strong> {servico.modelocontratacao}</div>
                                                                <div className="col-md-6"><strong>Área:</strong> {servico.area}</div>
                                                                <div className="col-md-6"><strong>Serviço:</strong> {servico.servico}</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p>Carregando...</p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal */}
                    <div className="modal fade" id="NF" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Detalhes do Orçamento OS</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    {selectedDataNf ? (
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-6"><strong>Cidade/Estado:</strong> {selectedDataNf.cidade}/{selectedDataNf.estado}</div>
                                                <div className="col-md-6"><strong>Cliente:</strong> {selectedDataNf.instituicao}</div>
                                                <div className="col-md-6"><strong>Email:</strong> {selectedDataNf.email}</div>
                                                <div className="col-md-6"><strong>Id Orçamento:</strong> {selectedDataNf.id}</div>
                                                <div className="col-md-6"><strong>Id OS:</strong> {groupArrayElements(parseOsGerada(selectedDataNf.osGerada), 12).map((group, index) => (
                                                    <div key={index}>
                                                        {group.join(', ')}
                                                    </div>
                                                ))}</div>
                                                <div className="col-md-6"><strong>Valor Orçamento:</strong> R${selectedDataNf.valortotalfloat}</div>
                                                <div className="col-md-6"><strong>N° Parcelas:</strong> {selectedDataNf.nparcelas}</div>
                                                <div className="col-md-6"><strong>Vendedor:</strong> {selectedDataNf.vendedor}</div>
                                                <div className="col-md-6"><strong>Status:</strong> {selectedDataNf.status}</div>
                                                <div className="col-md-6"><strong>Data Aprovação:</strong> {selectedDataNf.dataAprovacao}</div>
                                                <div className="col-md-6"><strong>Tipo:</strong> {selectedDataNf.tipoCliente}</div>
                                                <div className="col-md-6"><strong>Forma de Pagamento:</strong> {selectedDataNf.formapagamento}</div>

                                                {selectedDataNf.tipoCliente === "PF" && (
                                                    <div className="col-md-6"><strong>CPF:</strong> {selectedDataNf.cpf}</div>
                                                )}
                                                {selectedDataNf.tipoCliente === "PJ" && (
                                                    <div className="col-md-6"><strong>CNPJ:</strong> {selectedDataNf.cnpj}</div>
                                                )}
                                                <div className="col-md-6"><strong>CEP:</strong> {selectedDataNf.cep}</div>
                                                <div className="col-md-6"><strong>Endereço:</strong> {selectedDataNf.endereco}</div>
                                                <div className="col-md-6"><strong>Número:</strong> {selectedDataNf.numero}</div>
                                                <div className="col-md-6"><strong>Bairro:</strong> {selectedDataNf.bairro}</div>
                                                <div className="col-md-6"><strong>Telefone:</strong> {selectedDataNf.telefone}</div>
                                                <hr />

                                                <div>
                                                    <h2>OS - NF</h2>
                                                    {selectedDataNf.os.map((servico, index) => (
                                                        <div className="card mb-3" key={index}>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-6"><strong>ID:</strong> {servico.idos} OS</div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                                                        <label htmlFor={`formFile-${index}`} className="form-label">Selecionar NF </label>
                                                                        <input
                                                                            className="form-control"
                                                                            type="file"
                                                                            name="ata"
                                                                            id={`formFile-${index}`}
                                                                            onChange={(event) => handleFileChange(event, index)}
                                                                        />
                                                                        <br /><br />
                                                                        {servico.nfImagem !== undefined && (
                                                                            <>

                                                                                <button type="button" onClick={() => VisualizarPDF(servico.nfImagem)} className="btn btn-success">Visualizar PDF</button>
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <p>Carregando...</p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                    <button type="button" className="btn btn-success" onClick={salvarImagem} data-bs-dismiss="modal">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>

        </div >
    );
};
