import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link } from 'react-router-dom';

export const ViewPermission = (props) => {

    const [permission, setPermission] = useState('');
    const [descricao, setDescricao] = useState('');

    const [id] = useState(props.match.params.id);
    console.log("id Visualizar Permission", id);

    useEffect(() => {
        const getPermission = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
    
            await api.get("/permission/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        setPermission(response.data.permission);
                        setDescricao(response.data.descricao);
                        
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }
    
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getPermission();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Visualizar Permissão</h1>
                <form className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Permissão:</label>
                        </b>
                        <input type="text" name="permission" className="form-control" value={permission} onChange={text => setPermission(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Descrição:</label>
                        </b>
                        <input type="text" name="descricao" className="form-control" value={descricao} onChange={text => setDescricao(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-12 mb-4 text-center">
                        <Link to={"/permission"}>
                            <button type="button" className="btn btn-success">
                                Voltar
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}