import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const AddLicenca = (props) => {

    const [idempresa] = useState(props.match.params.idempresa);
    console.log("id Visualizar Empresa", idempresa);

    const [licenca, setLicenca] = useState({
        licenca : "", 
        numcevs : "", 
        atividadeEconomica: "", 
        detalhe: "", 
        pdf: "", 
        descricao: "", 
        status: "", 
        estado: "", 
        cidade: "", 
        ano: "", 
        renovacao: "",
        empresa_id: idempresa
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const validationSchema = yup.object().shape({
        licenca: yup.string().trim().required('Necessário preencher o campo Licença!'),
        numcevs: yup.string().trim().required('Necessário preencher o campo numcevs!'),
        atividadeEconomica: yup.string().trim().required('Necessário preencher o campo de Atividade econômica!'),
        detalhe: yup.string().trim().required('Necessário preencher o campo detalhe!'),
        pdf: yup.string().trim().required('Necessário preencher o campo pdf!'),
        descricao: yup.string().trim().required('Necessário preencher o campo descrição!'),
        status: yup.string().trim().required('Necessário preencher o campo status!'),
        estado: yup.string().trim().required('Necessário preencher o campo estado!'),
        cidade: yup.string().trim().required('Necessário preencher o campo cidade!'),
        ano: yup.string().trim().required('Necessário preencher o campo ano!'),
        renovacao: yup.string().trim().required('Necessário preencher o campo renovação!'),
        empresa_id: yup.string().trim().required('Necessário preencher o campo de empresa!')
      });
    

    const [classeOptions, setClasseOptions] = useState([]);

    const valueInput = e => setLicenca({ ...licenca, [e.target.name]: e.target.value });

    const addLicenca = async e => {
        e.preventDefault();

        try {
            await validationSchema.validate(licenca, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao adicionar nível:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        console.log("licenca", licenca);

        await api.post('/licenca', licenca, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        const classeOptions = async () => {
            try {
                const response = await api.get("/classe", headers);
                console.log("response typeUser", response);
                setClasseOptions(response.data); // Substitua por onde os dados necessários estão no objeto de resposta
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        classeOptions();
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
            <div className='container'>
                <h1>Adicionar Licenca</h1>

                <hr />
                {retorno.type === 'error' ? 
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>{retorno.mensagem}</strong>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}
                <form onSubmit={addLicenca} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome da Licenca:</label>
                        </b>
                        <input type="text" name="licenca" className={`form-control ${retorno?.licenca && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.licenca && <div className="invalid-feedback">{retorno.licenca}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">numcevs:</label>
                        </b>
                        <input type="text" name="numcevs" className={`form-control ${retorno?.numcevs && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.numcevs && <div className="invalid-feedback">{retorno.numcevs}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">atividadeEconomica:</label>
                        </b>
                        <input type="text" name="atividadeEconomica" className={`form-control ${retorno?.atividadeEconomica && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.atividadeEconomica && <div className="invalid-feedback">{retorno.atividadeEconomica}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">detalhe:</label>
                        </b>
                        <input type="text" name="detalhe" className={`form-control ${retorno?.detalhe && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.detalhe && <div className="invalid-feedback">{retorno.detalhe}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">pdf:</label>
                        </b>
                        <input type="text" name="pdf" className={`form-control ${retorno?.pdf && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.pdf && <div className="invalid-feedback">{retorno.pdf}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">descricao:</label>
                        </b>
                        <input type="text" name="descricao" className={`form-control ${retorno?.descricao && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.descricao && <div className="invalid-feedback">{retorno.descricao}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">estado:</label>
                        </b>
                        <input type="text" name="estado" className={`form-control ${retorno?.estado && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.estado && <div className="invalid-feedback">{retorno.estado}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">cidade:</label>
                        </b>
                        <input type="text" name="cidade" className={`form-control ${retorno?.cidade && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.cidade && <div className="invalid-feedback">{retorno.cidade}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">ano:</label>
                        </b>
                        <input type="text" name="ano" className={`form-control ${retorno?.ano && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.ano && <div className="invalid-feedback">{retorno.ano}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">renovacao:</label>
                        </b>
                        <input type="text" name="renovacao" className={`form-control ${retorno?.renovacao && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.renovacao && <div className="invalid-feedback">{retorno.renovacao}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="status" className="form-label">Status:</label>
                        </b>
                        <select
                            id="status"
                            className={`form-select ${retorno?.status && 'is-invalid'}`}
                            name="status"
                            onChange={valueInput}
                        >
                            <option value="">Selecione</option>
                            <option value="ativo">Ativo</option>
                            <option value="inativo">Inativo</option>
                        </select> 
                        {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}                   
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputClasse" className="form-label">Classe:</label>
                        </b>
                        <select
                            id="inputClasse"
                            className={`form-select ${retorno?.status && 'is-invalid'}`}
                            name="classe_id"
                            onChange={valueInput}
                            value={classeOptions.classe}
                        >
                            <option value="">Selecione</option>
                            {classeOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.classe}</option>
                            ))}
                        </select>
                        {retorno?.classe_id && <div className="invalid-feedback">{retorno.classe_id}</div>}
                    </div>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/licenca/"+idempresa}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: "/licenca/"+idempresa,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>

                </form>
            </div>
            </PageContent>
        </div>
    );
};