import React, { useEffect, useState, useRef } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

import localforage from 'localforage';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const EditarTreinamento = (props) => {

    const [area_id, setSelectedValueArea] = useState(null);
    const [data, setData] = useState('');
    const [duracao, setDuracao] = useState('');
    const [conteudo, setConteudo] = useState([]);
    const [nome, setNome] = useState('');
    const [video, setVideo] = useState('');
    const [textoEmail, setTextoEmail] = useState('');
    //Para a ata
    const [imageAta, setImageAta] = useState('');
    const [viewMostrarAta, setViewMostrarAta] = useState(null);
    //Para a avaliação
    const [imageAvaliacao, setImageAvaliacao] = useState('');
    const [viewMostrarAvaliacao, setViewMostrarAvaliacao] = useState(null);
    //Para o conteudo
    const [imageConteudo, setImageConteudo] = useState('');
    const [viewMostrarConteudo, setViewMostrarConteudo] = useState(null);


    const [areaOptions, setAreaOptions] = useState([]);
    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Area", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [conteudoProgramatico, setConteudoProgramatico] = useState({
        conteudo: "",
        duracao: "",
        tipo: "",
        treinamento_id: id,
        duracaoTreinamento: ""
    });

    const dataTableRef = useRef(null);

    const fetchData = async () => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getArea = async () => {
            await api.get("/area/", headers)
                .then((response) => {
                    //console.log("area", response)
                    const filteredData = response.data.filter(area => area.area !== 'Medicina Nuclear e Radiodiagnóstico');
                    setAreaOptions(filteredData);
                }).catch((err) => {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                });
        };

        const getTreinamento = async () => {


            await api.get("/treinamentos/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("get useEffect", response.data);

                        setSelectedValueArea(response.data.area_id);
                        setNome(response.data.nome);
                        setData(response.data.data);
                        if (response.data.duracao === null) {
                            setDuracao(0);
                        }
                        if (response.data.duracao !== null) {
                            setDuracao(response.data.duracao);
                        }
                        setVideo(response.data.video);
                        setTextoEmail(response.data.textoEmail);
                        if (response.data.ata != null) {
                            setImageAta(response.data.imagemAta);
                            setViewMostrarAta(1);
                        }
                        if (response.data.avaliacao != null) {
                            setImageAvaliacao(response.data.imagemAvaliacao);
                            setViewMostrarAvaliacao(1);
                        }
                        if (response.data.conteudoProgramatico != null) {
                            const conteudo = response.data.imagemConteudoProgramatico;

                            // Verificação baseada na extensão do arquivo
                            const fileExtension = conteudo.split('.').pop().toLowerCase();

                            if (fileExtension === 'pdf') {
                                setViewMostrarConteudo(1); // É um PDF
                            } else if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
                                setViewMostrarConteudo(2); // É uma imagem
                            } else {
                                setViewMostrarConteudo(null); // Tipo de arquivo não suportado
                            }

                            // Definir o conteúdo para ser exibido
                            setImageConteudo(conteudo);
                        } else {
                            setViewMostrarConteudo(null);
                        }

                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado !"
                        });
                    }
                    //console.log(response.data);

                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: tente mais tarde !"
                        });
                    }
                })
        };
        const getConteudos = async () => {
            const headers = {
                'headers': {
                    'Authorization': "Bearer " + localStorage.getItem('token')
                }
            };

            try {
                if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
                    $(dataTableRef.current).DataTable().destroy();
                }
                const response = await api.get("/conteudo/" + id, headers);
                //console.log("area", response);
                setConteudo(response.data);
                if (dataTableRef.current) {
                    $(dataTableRef.current).DataTable({
                        retrieve: true,
                        "order": [[0, "desc"]]
                    });
                }
            } catch (err) {
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente mais tarde!'
                });
            }
        };
        getArea();
        getTreinamento();
        getConteudos();
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleSelectChangeArea = (event) => {
        event.preventDefault();
        setSelectedValueArea(event.target.value);
    };

    const VisualizarPDF = async (imagem) => {
        console.log("imagem", imagem);
        if (imagem !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', imagem);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const editarTreinamento = async (e) => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        var ata = imageAta;
        var avaliacao = imageAvaliacao;
        var conteudoProgramatico = imageConteudo;

        await api.put("/treinamentos", { id, area_id, nome, data, video, textoEmail, ata, avaliacao, conteudoProgramatico }, headers)
            .then((response) => {
                //console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })
    };

    const handleTextoEmailChange = (e) => {
        setTextoEmail(e.target.value);
    };

    const verificaTipoArquivoAta = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrarAta(1);
                setImageAta(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrarAta(2);
                setImageAta(selectedFile);
            }
        }

    }

    const verificaTipoArquivoAvaliacao = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrarAvaliacao(1);
                setImageAvaliacao(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrarAvaliacao(2);
                setImageAvaliacao(selectedFile);
            }
        }

    }

    const verificaTipoArquivoConteudo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];

        if (selectedFile) {
            if (fileType.includes(selectedFile.type)) {
                setViewMostrarConteudo(1); // Para PDF
                // Armazena o PDF no estado sem conversão
                setImageConteudo(selectedFile);
            } else if (fileFoto.includes(selectedFile.type)) {
                setViewMostrarConteudo(2); // Para imagens
                // Usar FileReader para converter a imagem para base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageConteudo(reader.result); // Armazena a imagem convertida
                };
                reader.readAsDataURL(selectedFile);
            } else {
                setViewMostrarConteudo(null);
                setImageConteudo(null);
                alert("Tipo de arquivo não suportado. Selecione um PDF ou uma imagem.");
            }
        }
    };

    const AdicionarColaborador = () => {
        setConteudoProgramatico({
            conteudo: "",
            duracao: "",
            tipo: "",
            treinamento_id: id
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setConteudoProgramatico({ ...conteudoProgramatico, [name]: value });
    };

    const handleCKEditorChange = (event, editor) => {
        const data = editor.getData();
        setConteudoProgramatico({ ...conteudoProgramatico, conteudo: data });
    };

    const salvarConteudo = async () => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        conteudoProgramatico.duracaoTreinamento = duracao;
        console.log("conteudoProgramatico", conteudoProgramatico);

        await api.post('/conteudo', conteudoProgramatico, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                fetchData();

            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });


    };

    function stripHTML(html) {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    }

    const [treinamentoSelecionado, setTreinamentoSelecionado] = useState(null);

    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const buscarTreinamentoPorId = async (idtreinamento) => {

        try {
            const response = await api.get("/conteudo/selecionar/" + idtreinamento, headers);
            setTreinamentoSelecionado(response.data); // Atualiza o estado com os dados recebidos

            if (response.data.tipo === "minuto") {
                var valorDuracao = response.data.duracao;

                valorDuracao = valorDuracao / 60;

                setDuracaoAntesEditar(valorDuracao);

            }

            if (response.data.tipo === "hora") {

                setDuracaoAntesEditar(response.data.duracao);

            }

        } catch (error) {
            console.error("Erro ao buscar o treinamento:", error);
        }
    };

    const salvarEdicao = async () => {
        var id = treinamentoSelecionado.id;
        var conteudo = treinamentoSelecionado.conteudo;
        var duracaoAntiga = duracao;
        var duracaoEditada = treinamentoSelecionado.duracao;
        var tipo = treinamentoSelecionado.tipo;
        var treinamento_id = treinamentoSelecionado.treinamento_id;
        await api.put("/conteudo", { id, conteudo, duracaoAntiga, duracaoEditada, duracaoAntesEditar, tipo, treinamento_id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                fetchData();
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })
    };

    const abrirModalExcluir = async (treinamento) => {
        setTreinamentoSelecionado(treinamento);
        try {
            const response = await api.get("/conteudo/selecionar/" + treinamento.id, headers);
            setTreinamentoSelecionado(response.data); // Atualiza o estado com os dados recebidos

            if (response.data.tipo === "minuto") {
                var valorDuracao = response.data.duracao;

                valorDuracao = valorDuracao / 60;

                setDuracaoAntesEditar(valorDuracao);

            }

            if (response.data.tipo === "hora") {

                setDuracaoAntesEditar(response.data.duracao);

            }

        } catch (error) {
            console.error("Erro ao buscar o treinamento:", error);
        }
    };

    // Função para confirmar a exclusão
    const confirmarExclusao = () => {
        if (treinamentoSelecionado) {
            excluirTreinamento(treinamentoSelecionado.id);
            setTreinamentoSelecionado(null); // Fechar o modal após a exclusão
        }
    };

    const excluirTreinamento = async (id) => {
        var id = treinamentoSelecionado.id;
        var duracaoAntiga = duracao;
        var treinamento_id = treinamentoSelecionado.treinamento_id;
        await api.delete(`/conteudo/${id}`, {
            headers,
            data: {
                duracaoAntesEditar,
                duracaoAntiga,
                treinamento_id
            }
        })
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                fetchData();
            })
            .catch((err) => {
                setRetorno({
                    type: 'error',
                    mensagem: err.response?.data?.mensagem || 'Tente novamente mais tarde!'
                });
            });
    };

    const [duracaoAntesEditar, setDuracaoAntesEditar] = useState(null);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Editar Treinamento</h1>
                <hr />
                {retorno.type === 'success' ?
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}
                <form onSubmit={editarTreinamento} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <label for="inputArea" className="form-label"><b>Area:</b></label>
                        <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea}>
                            {areaOptions.map((option) => (
                                <option key={option.id} value={option.id}>{option.area}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nome" className="form-control" value={nome} onChange={text => setNome(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Data:</label>
                        </b>
                        <input type="date" name="data" className="form-control" value={data} onChange={text => setData(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">link Vídeo:</label>
                        </b>
                        <input type="text" name="video" className="form-control" value={video} onChange={text => setVideo(text.target.value)} ></input>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-4 form-outline mb-4">
                        <b>
                            <label htmlFor="textoEmail" className="form-label">
                                Texto do Email:
                            </label>
                        </b>
                        <textarea
                            className="form-control"
                            id="textoEmail"
                            name="textoEmail"
                            value={textoEmail}
                            rows="9"
                            onChange={handleTextoEmailChange}
                        ></textarea>
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione a ata</label>
                        <input className="form-control" type="file" name="ata" id="formFile" onChange={verificaTipoArquivoAta} /><br /><br />

                        {viewMostrarAta === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageAta)} className='btn btn-success' >Visualizar PDF</button>
                        </>}


                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione a Avaliação</label>
                        <input className="form-control" type="file" name="ata" id="formFile" onChange={verificaTipoArquivoAvaliacao} /><br /><br />

                        {viewMostrarAvaliacao === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageAvaliacao)} className='btn btn-success' >Visualizar PDF</button>
                        </>}


                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Duração:</label>
                        </b>
                        <input type="text" name="duracao" className="form-control" value={`${duracao}h`} disabled onChange={text => setDuracao(text.target.value)} ></input>
                    </div>

                    {/* <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione o Conteúdo </label>
                        <input className="form-control" type="file" name="ata" id="formFile" onChange={verificaTipoArquivoConteudo} /><br /><br />

                        {viewMostrarConteudo === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageConteudo)} className='btn btn-success' >Visualizar PDF</button>
                        </>}

                        {viewMostrarConteudo === 2 && (
                            <>
                                {typeof imageConteudo === 'object' ? (
                                    // Se for um arquivo local, criamos uma URL temporária para exibir a imagem
                                    <img src={URL.createObjectURL(imageConteudo)} alt="Pré-visualização do conteúdo" style={{ maxWidth: '100%', height: 'auto' }} />
                                ) : (
                                    // Se já for uma URL da API, exibimos diretamente
                                    <img src={imageConteudo} alt="Pré-visualização do conteúdo" style={{ maxWidth: '100%', height: 'auto' }} />
                                )}
                            </>
                        )}


                    </div> */}
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <b>
                            <label htmlFor="textoEmail" className="form-label">
                                Texto do Email:
                            </label>
                        </b>
                        <CKEditor
                            editor={ClassicEditor}
                            data={textoEmail}  // Inicializa o CKEditor com o valor de textoEmail
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTextoEmail(data);  // Atualiza textoEmail com o conteúdo do CKEditor
                            }}
                            
                        />
                    </div> */}
                    {/* <div>
                        <h3>Conteúdo do Email:</h3>
                        <div dangerouslySetInnerHTML={{ __html: textoEmail }} />
                    </div> */}

                    <div className="col-12 mb-4 text-center">
                        <Link to={"/treinamentos"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/treinamentos',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }
                            }} /> : ""}
                        <button type="submit" className="btn btn-success">Editar</button>
                    </div>
                </form>
                <h1>Lista de Conteúdo Programático</h1>
                <div className='card-body'>
                    <div>
                        <button
                            type="button"
                            className="btn btn-warning me-md-2"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={AdicionarColaborador}
                        >
                            Criar Conteúdo Programático
                        </button>
                    </div>
                    <br />
                    <div className="table-responsive">
                        <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Id</th>
                                    <th scope="col">Conteudo</th>
                                    <th scope="col">Duracao</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {conteudo.map(treinamento => (
                                    <tr key={treinamento.id}>
                                        <th scope="row">{treinamento.id}</th>
                                        <td>{stripHTML(treinamento.conteudo)}</td>
                                        <td>{treinamento.duracao}</td>
                                        <td>{treinamento.tipo}</td>
                                        <td>
                                            <div className="d-flex flex-row mb-3">
                                                <div>
                                                    {/* Botão para abrir o modal de visualização */}
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning me-md-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#viewModal"
                                                        onClick={() => buscarTreinamentoPorId(treinamento.id)} // Chama a função passando o ID
                                                    >
                                                        <i className="fa-solid fa-eye"></i> Visualizar
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success me-md-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#editModal"
                                                        onClick={() => buscarTreinamentoPorId(treinamento.id)} // Chama a função com o ID do treinamento
                                                    >
                                                        <i className="fa fa-edit"></i> Editar
                                                    </button>
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#deleteModal"
                                                        onClick={() => abrirModalExcluir(treinamento)} // Define o treinamento a ser excluído
                                                    >
                                                        <i className="fa fa-trash"></i> Excluir
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Adicionar Conteúdo Programático
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {/* Usando Grid do Bootstrap para exibir os itens um por linha */}
                                <div className="row">
                                    <div className="col-12 mb-6">
                                        <label htmlFor="conteudo" className="form-label">
                                            Conteúdo
                                        </label>
                                        {/* CKEditor para editar o conteúdo */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={conteudoProgramatico.conteudo}
                                            onChange={handleCKEditorChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-6">
                                        <label htmlFor="duracao" className="form-label">
                                            Duração
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="duracao"
                                            name="duracao"
                                            value={conteudoProgramatico.duracao}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="tipo" className="form-label">
                                            Tipo
                                        </label>
                                        <select
                                            className="form-select"
                                            id="tipo"
                                            name="tipo"
                                            value={conteudoProgramatico.tipo}
                                            onChange={handleChange}
                                        >
                                            <option value="">Selecione o Tipo</option>
                                            <option value="hora">Hora</option>
                                            <option value="minuto">Minuto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    data-bs-dismiss="modal"
                                    onClick={salvarConteudo}
                                >
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="viewModal"
                    tabIndex="-1"
                    aria-labelledby="viewModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="viewModalLabel">
                                    Visualizar Conteúdo Programático
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {/* Exibe os detalhes do treinamento selecionado */}

                                <p><b>Conteúdo:</b><div dangerouslySetInnerHTML={{ __html: treinamentoSelecionado?.conteudo }} /></p>
                                <p><b>Duração:</b> {treinamentoSelecionado?.duracao}h</p>
                                <p><b>Tipo:</b> {treinamentoSelecionado?.tipo}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="editModal"
                    tabIndex="-1"
                    aria-labelledby="editModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="editModalLabel">
                                    Editar Conteúdo Programático
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                {treinamentoSelecionado ? (
                                    <>
                                        {/* Campo CKEditor para editar conteúdo */}
                                        <div className="mb-3">
                                            <label htmlFor="conteudo" className="form-label">Conteúdo</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={treinamentoSelecionado.conteudo}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setTreinamentoSelecionado({
                                                        ...treinamentoSelecionado,
                                                        conteudo: data,
                                                    });
                                                }}
                                            />
                                        </div>

                                        {/* Campo Duração */}
                                        <div className="mb-3">
                                            <label htmlFor="duracao" className="form-label">Duração</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="duracao"
                                                value={treinamentoSelecionado.duracao}
                                                onChange={(e) => setTreinamentoSelecionado({
                                                    ...treinamentoSelecionado,
                                                    duracao: e.target.value,
                                                })}
                                            />
                                        </div>

                                        {/* Campo Tipo com Select */}
                                        <div className="mb-3">
                                            <label htmlFor="tipo" className="form-label">Tipo</label>
                                            <select
                                                className="form-control"
                                                id="tipo"
                                                value={treinamentoSelecionado.tipo}
                                                onChange={(e) => setTreinamentoSelecionado({
                                                    ...treinamentoSelecionado,
                                                    tipo: e.target.value,
                                                })}
                                            >
                                                <option value="minuto">Minuto</option>
                                                <option value="hora">Hora</option>
                                            </select>
                                        </div>
                                    </>
                                ) : (
                                    <p>Carregando dados...</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    Fechar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-bs-dismiss="modal"
                                    onClick={salvarEdicao}>
                                    Salvar Alterações
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="deleteModal"
                    tabIndex="-1"
                    aria-labelledby="deleteModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModalLabel">Excluir Conteúdo Programático</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {treinamentoSelecionado ? (
                                    <p>
                                        Deseja realmente excluir o treinamento ? "{stripHTML(treinamentoSelecionado.conteudo)}"?
                                    </p>
                                ) : (
                                    <p>Carregando dados...</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={confirmarExclusao}
                                    data-bs-dismiss="modal"
                                >
                                    Excluir
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}