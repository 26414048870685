import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

export const AddEmpresa = () => {
    const [empresa, setEmpresa] = useState({
        nome: '',
        cnpj: '',
        email: '',
        telefone: '',
        razaoSocial: ''
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const validationSchema = yup.object().shape({
        nome: yup.string().trim().required('Necessário preencher o campo Nome da Empresa!'),
        cnpj: yup.string().trim().required('Necessário preencher o campo CNPJ!'),
        email: yup.string().trim().email('Formato de e-mail inválido').required('Necessário preencher o campo E-mail!'),
        telefone: yup.string().trim().required('Necessário preencher o campo Telefone!'),
        razaoSocial: yup.string().trim().required('Necessário preencher o campo Razão Social!')
    });

    const valueInput = e => setEmpresa({ ...empresa, [e.target.name]: e.target.value });

    const addEmpresa = async e => {
        e.preventDefault();

        try {
            await validationSchema.validate(empresa, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao adicionar empresa:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        //console.log("empresa", empresa);

        await api.post('/empresa', empresa, headers)
            .then((response) => {
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Adicionar Empresa</h1>
                    <hr />
                    {retorno.type === 'error' ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                    <form onSubmit={addEmpresa} className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome da Empresa:</label>
                            </b>
                            <input type="text" name="nome" className={`form-control ${retorno?.nome && 'is-invalid'}`} onChange={valueInput} ></input>
                            {retorno?.nome && <div className="invalid-feedback">{retorno.nome}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">cnpj:</label>
                            </b>
                            <input type="text" name="cnpj" className={`form-control ${retorno?.cnpj && 'is-invalid'}`} onChange={valueInput} ></input>
                            {retorno?.cnpj && <div className="invalid-feedback">{retorno.cnpj}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">email:</label>
                            </b>
                            <input type="text" name="email" className={`form-control ${retorno?.email && 'is-invalid'}`} onChange={valueInput} ></input>
                            {retorno?.email && <div className="invalid-feedback">{retorno.email}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">telefone:</label>
                            </b>
                            <input type="text" name="telefone" className={`form-control ${retorno?.telefone && 'is-invalid'}`} onChange={valueInput} ></input>
                            {retorno?.telefone && <div className="invalid-feedback">{retorno.telefone}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">razaoSocial:</label>
                            </b>
                            <input type="text" name="razaoSocial" className={`form-control ${retorno?.razaoSocial && 'is-invalid'}`} onChange={valueInput} ></input>
                            {retorno?.razaoSocial && <div className="invalid-feedback">{retorno.razaoSocial}</div>}
                        </div>
                        <div className='col-12 mb-4 text-center'>
                            <Link to={"/empresa"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/empresa',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Salvar</button>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    );
};