import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { Link, Redirect } from 'react-router-dom';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { addDays } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { number } from 'yup';

import axios from 'axios';

import localforage from 'localforage';

import { useHistory } from 'react-router-dom';

export const RevisarOs = (props) => {

    const history = useHistory();

    const idEmpresa = localStorage.getItem("empresa");
    const nome = localStorage.getItem('nome');
    const [telaAtual, setTelaAtual] = useState('revisaros');

    const [cliente, setCliente] = useState('');
    const [criadordaos, setCriadordaos] = useState('');
    const [datacriacao, setDatacriacao] = useState('');
    const [prazoentrega, setPrazoentrega] = useState('');
    const [tecnico, setTecnico] = useState('');
    const [detalhes, setDetalhes] = useState('');
    const [servicos, setServicos] = useState([]);
    const [status, setStatus] = useState('');

    const [clienteOptions, setClienteOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [listaTecnicos, setListaTecnicos] = useState([]);


    const [areaId, setAreaId] = useState(1);
    const [servicosDaApi, setServicosDaApi] = useState([]);
    const [servico, setServico] = useState("");
    const [descricao, setDescricao] = useState("");

    const [equipamentoSelecionado, setEquipamentoSelecionado] = useState({});
    const [equipamentos, setEquipamentos] = useState([]);
    const [equipamento, setEquipamento] = useState("");
    const [listaServicosOs, setListaServicosOs] = useState([]);

    const [dataVisita, setDataVisita] = useState('');
    const [statusCliente, setStatusCliente] = useState('');


    const [id] = useState(props.match.params.id);

    const [todosComPdfParte2, setTodosComPdfParte2] = useState(false);
    //console.log("id Visualizar Area", id);

    // const handleChange = (e) => {
    //     e.preventDefault();
    //     setCliente(e.target.value);
    // };

    const handleData = (date) => {
        //console.log("date", date);
        setDatacriacao(date);
    };

    const handleTecnicoChange = (e) => {
        setTecnico(e.target.value);
    };

    const handleDetalhesChange = (e) => {
        setDetalhes(e.target.value);
    };

    const handleAreaChange = (e) => {
        getServicos(e.target.value);
        setAreaId(e.target.value);
    };

    const handleServicoChange = (e) => {
        setServico(e.target.value);
    };

    const handleDescricaoChange = (e) => {
        setDescricao(e.target.value);
    };

    const handleEquipamentoChange = (e) => {
        setEquipamento(e.target.value);
        getEquipamentoById(e.target.value);
    };

    const handleClienteChange = (e) => {
        setCliente(e.target.value);
        getEquipamentoInstituicao(e.target.value);
    };

    const handleCliente = (e) => {
        //console.log("e handleCliente", e);
        setCliente(e);
        getEquipamentoInstituicao(e);
    };

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleDataVisita = (date) => {
        //console.log("date", date);

        var dataParaSomar = date;

        var dataAmericaSP = zonedTimeToUtc(dataParaSomar, 'America/Sao_Paulo');
        //console.log("znDate", dataAmericaSP);

        var addsetedias = addDays(dataAmericaSP, 7);

        var dataFormatada = format(addsetedias, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });

        setDataVisita(date);
        setPrazoentrega(dataFormatada);
    };

    const handleDataPrazo = (date) => {
        //console.log("date", date);
        setPrazoentrega(date);
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const getEquipamentoInstituicao = async (e) => {
        try {
            const response = await api.get("/equipamento/" + e, headers);
            if (response.data) {
                //console.log("Equipamentos", response.data);
                setEquipamentos(response.data);
            } else {
                //console.log("Erro: Equipamentos não encontrados!");
            }
        } catch (error) {
            //console.log("Erro ao buscar equipamentos:", error.message);
        }
    };



    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const getServicos = async (areaid) => {
        try {
            const response = await api.get("/servicoarea/" + areaid, headers);
            if (response.data) {
                setServicosDaApi(response.data);
            } else {
                //console.log("Erro: serviço não encontrado !");
            }
        } catch (err) {
            if (err) {
                //console.log("Erro", err);
                setServicosDaApi([]);
            } else {
                //console.log("Erro não encontrado");
            }
        }
    };

    const getEquipamentoById = async (e) => {
        try {
            const response = await api.get("/equipamento/selecionar/" + e, headers);
            //console.log("response equipamento", response.data);
            setEquipamentoSelecionado(response.data);
        } catch (error) {
            console.error("Erro ao obter opções de equipamento:", error);
        }
    };

    const handleAdicionarServico = async (e) => {
        e.preventDefault();

        const novoServicoOs = {
            descricao: descricao,
            id_servico: servico,
            servico: servicosDaApi.find((item) => item.id === parseInt(servico)).servico,
            area_id: areaId,
            area: areaOptions.find((item) => item.id === parseInt(areaId)).area,
            equipamento_id: equipamento ? equipamento : "",
            equipamento: equipamento ? equipamentos.find((item) => item.id === parseInt(equipamento)).equipamento : "",
            os_id: id
        };

        await api.post('/ostemservico', novoServicoOs, headers)
            .then((response) => {
                // setRetorno({
                //     type: 'success',
                //     mensagem: response.data.mensagem
                // });
                fetchData();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });

        //console.log("novoServicoOs", novoServicoOs);
    };

    const handleExcluirServico = (index) => {
        // Crie uma cópia da lista de serviços
        const novaListaServicos = [...listaServicosOs];

        // Remova o serviço pelo índice
        const servicoRemovido = novaListaServicos.splice(index, 1)[0];

        // Atualize o estado da lista de serviços
        setListaServicosOs(novaListaServicos);
    };

    const SalvarFinanceiroOs = async (e) => {
        e.preventDefault();

        var status = "Aguardando faturamento";


        await api.put('/osatualizarstatus', { status, id, servicos }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };

    const SalvarCorrecaoOs = async (e) => {
        e.preventDefault();

        //var status = "em correção";

        var idcliente = cliente;

        for (let index = 0; index < servicos.length; index++) {
            const element = servicos[index];

            if (element.comentarioTemporario !== null && element.comentarioTemporario.trim() !== '') {
                const variavelNova = nome + " : " + element.comentarioTemporario;


                if (element.comentarioServico === null) {

                    element.comentarioServico = variavelNova;
                } else {

                    element.comentarioServico = variavelNova + "\n" + element.comentarioServico;
                }
            }
        }



        await api.put('/osatualizarstatus', { status, servicos, statusCliente, idcliente, id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };



    const handleButtonClickEquipamento = async (statusEquipamento, nequipamento) => {

        console.log('Botão clicado!', statusEquipamento);

        var statusEqui;
        var idcliente;

        if (statusEquipamento === "inativo") {
            statusEqui = "ativo";
        }
        if (statusEquipamento === "ativo") {
            statusEqui = "inativo";
        }

        idcliente = cliente;


        await api.put('/ostemservicostatus', { statusEqui, idcliente, id, nequipamento }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    const handleButtonClickCliente = async () => {
        //e.preventDefault();

        console.log('Botão clicado!');

        var statusCli;
        var idcliente;

        if (statusCliente === "inativo") {
            statusCli = "ativo";
        }
        if (statusCliente === "ativo") {
            statusCli = "inativo";
        }

        idcliente = cliente;

        await api.put('/ostemservicostatus', { statusCli, idcliente, id }, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        // window.location.reload();

    };

    // var todosComPdfParte2;


    const fetchData = async () => {

        const getOs = async () => {


            await api.get("/os/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        handleCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazoentrega(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setStatus(response.data.statusos);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();

        console.log("status", status);
    };





    useEffect(() => {



        const getOs = async () => {


            await api.get("/os/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        handleCliente(response.data.instituicao_id);
                        setCriadordaos(response.data.criadordaos);
                        setDatacriacao(response.data.datacriacao);
                        setPrazoentrega(response.data.prazo);
                        setTecnico(response.data.tecnico);
                        setDetalhes(response.data.detalhes);
                        setServicos(response.data.temservico);
                        setDataVisita(response.data.datavisita);
                        setStatusCliente(response.data.statusCliente);
                        setStatus(response.data.statusos);


                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro", err);
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getCliente = async () => {
            try {
                const response = await api.get("/instituicao", headers);
                if (response.data) {
                    setClienteOptions(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro não encontrado");
                }
            }
        };

        const getListaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnicoPorEmpresa", headers);
                setListaTecnicos(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };

        const getArea = async () => {
            try {
                const response = await api.get("/area", headers);
                setAreaOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };


        getOs();
        getCliente();
        getListaTecnicoEmpresa();
        getArea();
        getServicos(areaId);

    }, [id]);

    useEffect(() => {
        const hasPdfParte2 = servicos.length > 0 && servicos.every((item) => item.statusServico !== "revisar" && item.statusServico !== "reprovado");
        setTodosComPdfParte2(hasPdfParte2);
    }, [servicos]);

    const handleButtonClickStatusServico = async (statusServico, idservico) => {

        console.log('Botão clicado!', statusServico, idservico);

        var statusServ;
        var idcliente;

        if (statusServico === "aprovado") {
            statusServ = "reprovado";
        }
        if (statusServico === "reprovado") {
            statusServ = "aprovado";
        }

        idcliente = cliente;

        console.log("idcliente", idcliente);
        console.log("id", id);
        console.log("statusServ", statusServ);
        console.log("idservico", idservico);


        await api.put('/ostemservicostatus', { idcliente, id, statusServ, idservico }, headers)
            .then((response) => {
                // setRetorno({
                //     type: 'success',
                //     mensagem: response.data.mensagem
                // });
                window.location.reload();
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
        window.location.reload();

    };

    const verificaPdf = async (e) => {
        console.log("e", e);

        try {
            const response = await axios.get(e, {
                responseType: 'blob', // Recebe a resposta como um objeto Blob
            });

            // Verifica o tipo do arquivo
            if (response.headers['content-type'] === 'application/pdf') {
                try {
                    // Armazene os dados no localforage
                    await localforage.setItem('pdfData', e);

                    // Abra a nova aba
                    const novaAba = window.open('/viewpdf/', '_blank');
                    novaAba.focus();
                } catch (error) {
                    console.error('Erro ao armazenar os dados no localforage:', error);
                }
                // Faça aqui o que você precisa fazer se o arquivo for um PDF
            } else {
                console.log('O arquivo não é um PDF.');
                // Faça aqui o que você precisa fazer se o arquivo não for um PDF
            }
        } catch (error) {
            console.error('Erro ao verificar o arquivo:', error);
            // Faça aqui o que você precisa fazer em caso de erro
        }
    };

    const handleStatusChange = (itemId, status) => {
        // Atualiza o estado dos serviços com base no itemId
        setServicos(prevServicos => {
            return prevServicos.map(servico => {
                if (servico.id === itemId) {
                    // Atualiza o status do serviço
                    return { ...servico, statusServico: status };
                }
                return servico;
            });
        });
    };

    const handleCommentChange = (id, newComment) => {
        setServicos((prevState) =>
            prevState.map((item) =>
                item.id === id ? { ...item, comentarioTemporario: newComment } : item
            )
        );
    };

    const handleBlur = (id) => {
        // Aqui você pode adicionar lógica adicional se precisar, por exemplo, enviar para um servidor
        //console.log(`Comentário do serviço ${id} atualizado!`);
    };



    const atualizarOs = async () => {
        if (window.confirm('Você deseja salvar os Dados?')) {
            const headers = {
                'headers': {
                    'Authorization': "Bearer " + localStorage.getItem('token')
                }
            };

            console.log("entrou no if");
            var idcliente = cliente;


            for (let index = 0; index < servicos.length; index++) {
                const element = servicos[index];

                if (element.comentarioTemporario !== null && element.comentarioTemporario.trim() !== '') {
                    const variavelNova = nome + " : " + element.comentarioTemporario;


                    if (element.comentarioServico === null) {

                        element.comentarioServico = variavelNova;
                    } else {

                        element.comentarioServico = variavelNova + "\n" + element.comentarioServico;
                    }
                }
            }

            var status = undefined;

            await api.put('/osatualizaservicos', { status, servicos, statusCliente, idcliente, id }, headers)
                .then((response) => {
                    setRetorno({
                        type: 'success',
                        mensagem: response.data.mensagem
                    });
                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'error',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'error',
                            mensagem: "Erro: Tente novamente!"
                        });
                    }
                });


        } else {

            console.log("entrou no else");

            return history.push('/os');
        }
    };


    //todosComPdfParte2 = servicos.every((item) => item.pdf_parte2 !== null);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className="container" style={{ marginLeft: "220px" }}>
                <br /><br />
                <h1>Revisar OS</h1>
                <hr />
                {retorno.type === "error" ? (
                    <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                    >
                        <strong>{retorno.mensagem}</strong>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                ) : (
                    ""
                )}
                <form className='row'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title">Dados gerais</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label for="inputCliente" className="form-label"><b>Cliente:</b></label>
                                            <select id="inputCliente" className="form-select" disabled name="cliente" value={cliente} onChange={handleClienteChange} >
                                                <option value="">Selecione</option>
                                                {clienteOptions.map((cliente) => (
                                                    <option key={cliente.id} value={cliente.id}>
                                                        {cliente.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="criador" className="form-label">
                                                Criador da OS
                                            </label>
                                            <input type="text" name="criadordaos" disabled className="form-control" value={criadordaos} onChange={text => setCriadordaos(text.target.value)}  ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                Data da criação do Orçamento
                                            </label>
                                            <input type="date" name="datacriacao" disabled className="form-control" value={datacriacao} onChange={(e) => handleData(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="datacriacao" className="form-label">
                                                Data da visita
                                            </label>
                                            <input type="date" name="dataVisita" disabled className="form-control" value={dataVisita} onChange={(e) => handleDataVisita(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                                            <label htmlFor="prazo" className="form-label">
                                                Prazo da Os
                                            </label>
                                            <input type="date" name="prazo" disabled className="form-control" value={prazoentrega} onChange={(e) => handleDataPrazo(e.target.value)} ></input>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <label for="tecnico" className="form-label">
                                                Técnicos*:
                                            </label>
                                            <select
                                                id="tecnico"
                                                className="form-select"
                                                name="tecnico"
                                                onChange={handleTecnicoChange}
                                                value={tecnico}
                                                disabled
                                            >
                                                <option value="">Selecione</option>
                                                {listaTecnicos.map((listaUsuario) => (
                                                    <option value={listaUsuario.id}>
                                                        {listaUsuario.nome}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Status:</label>
                                            </b>
                                            <select id="inputStatus" className="form-select" name="status" disabled value={status} onChange={handleSelectChange}>
                                                <option value="Agendado">Agendado</option>
                                                <option value="Aguardando agendamento">Aguardando Agendamento</option>
                                                <option value="Cancelado">Cancelado</option>
                                                <option value="em execucao">Em Execução</option>
                                                <option value="em correção">Em Correção</option>
                                                <option value="em revisão">Em Revisão</option>
                                                <option value="Aguardando Faturamento">Aguardando Faturamento</option>
                                                <option value="finalizado">Finalizado</option>
                                            </select>
                                            {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputStatus" className="form-label">Verifique os Logs da OS:</label>
                                            </b>
                                            <br />

                                            <Link to={`/logsos/${id}/${telaAtual}`}>
                                                <button type="button" className="btn btn-primary p-2 me-md-2">Logs</button>
                                            </Link>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <label htmlFor="detalhes" className="form-label">
                                                Detalhes
                                            </label>
                                            <textarea
                                                className="form-control"
                                                id="detalhes"
                                                name="detalhes"
                                                rows="3"
                                                value={detalhes}
                                                onChange={handleDetalhesChange}
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-secondary text-white">
                                    <h5 className="card-title"><b>Serviços</b></h5>
                                </div>
                                <div className="card-body">
                                    {servicos.length > 0 &&
                                        servicos.map((item) => {
                                            if (item.idservico !== 44 && item.idservico !== 43 && item.idservico !== 45) {
                                                return (
                                                    <div className="col-md-12" key={item.id}>
                                                        <div className="card mb-4">
                                                            <div className="card-body">
                                                                <h5 className="card-title">Área: {item.area}</h5>
                                                                <h6 className="card-subtitle mb-2 text-muted">Serviço: {item.servico}</h6>
                                                                <p className="card-text">Descrição: {item.descricao}</p>
                                                                {/* <p>Status do serviço: {item.statusServico}</p> */}
                                                                {item.idservico === 44 || item.idservico === 43 || item.idservico === 45 || item.servico === "Documentação" ? (
                                                                    <p className="card-subtitle mb-2 text-muted">Status do cliente : {statusCliente}</p>
                                                                ) : (
                                                                    <div>
                                                                        {item.idequipamento !== null ? (
                                                                            <>

                                                                                <p className="card-text">Equipamento: {item.equipamento}</p>
                                                                                <p className="card-text">Status do equipamento: {item.statusEquipamento}</p>
                                                                                <p className="card-text">Fabricante: {item.fabricante}</p>
                                                                                <p className="card-text">Modelo: {item.modelo}</p>
                                                                                <p className="card-text">N° de série: {item.numserie}</p>
                                                                            </>
                                                                        ) : (
                                                                            <p className="card-text">Sem informações de equipamento disponíveis.</p>
                                                                        )}
                                                                    </div>
                                                                )}
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 form-outline mb-6 ">
                                                                        <p>Status do serviço:</p>
                                                                        <div className="col-md-12">
                                                                            <div className="btn-group me-2">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`reprovado_${item.id}`}
                                                                                    name={`status_${item.id}`}
                                                                                    value="reprovado"
                                                                                    checked={item.statusServico === 'reprovado'}
                                                                                    onChange={() => handleStatusChange(item.id, 'reprovado')}
                                                                                />
                                                                                <label htmlFor={`reprovado_${item.id}`} className="mr-3">Reprovado</label>
                                                                            </div>
                                                                            <div className="btn-group me-2">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`aprovado_${item.id}`}
                                                                                    name={`status_${item.id}`}
                                                                                    value="aprovado"
                                                                                    checked={item.statusServico === 'aprovado'}
                                                                                    onChange={() => handleStatusChange(item.id, 'aprovado')}
                                                                                />
                                                                                <label htmlFor={`aprovado_${item.id}`} className="mr-3">Aprovado</label>
                                                                            </div>
                                                                            <div className="btn-group me-2">
                                                                                <input
                                                                                    type="radio"
                                                                                    id={`revisar_${item.id}`}
                                                                                    name={`status_${item.id}`}
                                                                                    value="revisar"
                                                                                    checked={item.statusServico === 'revisar'}
                                                                                    onChange={() => handleStatusChange(item.id, 'revisar')}
                                                                                />
                                                                                <label htmlFor={`revisar_${item.id}`} className="mr-3">Revisar</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br></br>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-lg-3 col-md-3 col-sm-3 form-outline mb-4">
                                                                            <div className="d-flex">
                                                                                {item.statusServico === "reprovado" ? (
                                                                                    <label className="bg-danger text-light p-2 me-md-2">Reprovado</label>
                                                                                ) : item.statusServico === "revisar" ? (
                                                                                    <label className="bg-warning text-light p-2 me-md-2">Revisar</label>
                                                                                ) : (
                                                                                    <label className="bg-success text-light p-2 me-md-2">Aprovado</label>
                                                                                )}

                                                                                {item.pdf_parte2 != null ? (
                                                                                    <button type="button" className="btn btn-success p-2 me-md-2" onClick={() => verificaPdf(item.endImagem)}>Revisar</button>
                                                                                ) : (
                                                                                    <button type="button" className="btn btn-success p-2 me-md-2">SEM PDF</button>
                                                                                )}
                                                                                <Link to={"/historico/" + item.id + "/" + id + "/" + cliente}>
                                                                                    <button type="button" className="btn btn-primary p-2 me-md-2">Histórico</button>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                                            <label htmlFor={`comentarioTemporario_${item.id}`} className="form-label">
                                                                                <b>
                                                                                    Comentario Revisor
                                                                                </b>
                                                                            </label>
                                                                            <div className="d-flex">
                                                                                <textarea
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={item.comentarioTemporario}
                                                                                    id={`comentarioTemporario_${item.id}`}
                                                                                    name="comentarioTemporario"
                                                                                    rows="3"
                                                                                    onChange={(e) => handleCommentChange(item.id, e.target.value)}
                                                                                    onBlur={() => handleBlur(item.id)}
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                                            <label htmlFor="comentarionovo" className="form-label">
                                                                                <b>
                                                                                    Histórico
                                                                                </b>
                                                                            </label>
                                                                            <div className="d-flex">
                                                                                <textarea
                                                                                    disabled
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    value={item.comentarioServico}
                                                                                    id="comentarionovo"
                                                                                    name="comentarionovo"
                                                                                    rows="3"
                                                                                ></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 mb-3 text-center">
                            <br />

                            <button type="button" onClick={() => atualizarOs()} className="btn btn-success me-md-2">
                                Voltar
                            </button>

                            {retorno.type === 'success' ?
                                <Redirect to={{
                                    pathname: '/os',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> : ""}
                            {todosComPdfParte2 ? (

                                <><button
                                    type="button"
                                    className="btn btn-success me-md-2"
                                    onClick={SalvarFinanceiroOs}
                                >
                                    Enviar para Financeiro
                                </button><button
                                    type="button"
                                    className="btn btn-warning me-md-2"
                                    disabled={true}
                                >
                                        Enviar para Revisão
                                    </button></>

                            ) : (
                                <><button
                                    type="button"
                                    className="btn btn-success me-md-2"
                                    disabled={true}
                                >
                                    Enviar para Financeiro
                                </button><button
                                    type="button"
                                    className="btn btn-warning me-md-2"
                                    disabled={false}
                                    onClick={SalvarCorrecaoOs}
                                >
                                        Enviar para Correção
                                    </button></>
                            )}
                        </div>
                    </div>
                </form>
            </div >
        </div >
    )
}