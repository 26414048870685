import React, { useState, useEffect } from 'react';
import localforage from 'localforage';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { PDFDocument } from 'pdf-lib';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const ViewVariosPdfs = () => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const workerUrl = "https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js";

    useEffect(() => {
        const fetchAndCombinePdfs = async () => {
            try {
                const pdfUrls = await localforage.getItem('pdfData');
                if (!Array.isArray(pdfUrls)) {
                    console.error('Tipo de dados não suportado');
                    return;
                }

                const pdfDocs = await Promise.all(
                    pdfUrls.map(async (url) => {
                        const response = await fetch(url);
                        const arrayBuffer = await response.arrayBuffer();
                        return PDFDocument.load(arrayBuffer);
                    })
                );

                const combinedPdf = await PDFDocument.create();

                for (const pdfDoc of pdfDocs) {
                    const copiedPages = await combinedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                    copiedPages.forEach((page) => {
                        combinedPdf.addPage(page);
                    });
                }

                const pdfBytes = await combinedPdf.save();
                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                setPdfUrl(url);
            } catch (error) {
                console.error('Erro ao combinar PDFs:', error);
            }
        };

        fetchAndCombinePdfs();
    }, []);

    return (
        <div>
            {pdfUrl ? (
                <Worker workerUrl={workerUrl}>
                    <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
                </Worker>
            ) : (
                <div>Carregando...</div>
            )}
        </div>
    );
};