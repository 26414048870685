import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewServico = (props) => {


    
    const [descricao, setDescricao] = useState('');
    const [valor, setValor] = useState('');
    const [tipo, setTipo] = useState('');
    const [servico, setServico] = useState('');
    const [area_id, setSelectedValueArea] = useState(null);
    const [areaOptions, setAreaOptions] = useState([]);
    

    const [type, setType] = useState('');
    const [status, setStatus] = useState('');

    const [id] = useState(props.match.params.id);
    console.log("id Visualizar serviço", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const handleSelectChangeArea = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueArea(event.target.value);
    };

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getServico = async () => {


            await api.get("/servico/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("get useEffect", response.data);
                        setSelectedValueArea(response.data.area_id);
                        setDescricao(response.data.descricao);
                        setValor(response.data.valor);
                        setTipo(response.data.tipo);
                        setServico(response.data.servico);
                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado !"
                        });
                    }
                    //console.log(response.data);

                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: tente mais tarde !"
                        });
                    }
                })
        };

        

       

        const getArea = async () => {
            await api.get("/area/", headers)
                .then((response) => {
                    //console.log("area", response)
                    setAreaOptions(response.data);
                }).catch((err) => {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                });
        };

        getArea();
        getServico();
        
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Visualizar Serviço</h1>
                    <form className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Descrição:</label>
                            </b>
                            <input type="text" name="descricao" className="form-control" value={descricao} onChange={text => setDescricao(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Tipo:</label>
                            </b>
                            <input type="text" name="tipo" className="form-control" value={tipo} onChange={text => setTipo(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Serviço:</label>
                            </b>
                            <input type="text" name="servico" className="form-control" value={servico} onChange={text => setServico(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Valor:</label>
                            </b>
                            <input type="text" name="valor" className="form-control" value={valor} onChange={text => setValor(text.target.value)} disabled ></input>
                        </div>
                         <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputArea" className="form-label"><b>Area:</b></label>
                            <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea} disabled>
                                {areaOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.area}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 mb-4 text-center">
                            <Link to={"/servico"}>
                                <button type="button" className="btn btn-success">
                                    Voltar
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    )
}