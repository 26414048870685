import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewCliente = (props) => {


    const [nome, setNome] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [bairro, setBairro] = useState('');
    const [cep, setCep] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tipo, setTipo] = useState('');

    const [type, setType] = useState('');
    const [status, setStatus] = useState('');

    const [cidadeestado, setCidadeEstado] = useState('');
    const [email, setEmail] = useState('');

    const [id] = useState(props.match.params.idinstituicao);
    //console.log("id", id);

    const handleTipoInstituicaoChange = e => {
        setTipo(e.target.value);
    };

    const getInstituicao = async (id) => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.get("/instituicao/" + id, headers)
            .then((response) => {
                console.log("response.data", response.data);
                if (response.data) {
                    setNome(response.data.nome);
                    setEndereco(response.data.endereco);
                    setNumero(response.data.numero);
                    setCnpj(response.data.cnpj);
                    setBairro(response.data.bairro);
                    setCep(response.data.cep);
                    setTelefone(response.data.telefone);
                    setTipo(response.data.tipo);
                    setCidadeEstado(response.data.cidadeestado);
                    setStatus(response.data.status);
                    setEmail(response.data.email);
                } else {
                    console.log("Erro: Usuário não encontrado !");
                }

            }).catch((err) => {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro  não encontrado");
                }
            })
    }

    useEffect(() => {
        getInstituicao(id);
    }, [id]);

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Visualizar Instituição</h1>
                    <form className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome:</label>
                            </b>
                            <input type="text" name="nome" className={`form-control`} disabled value={nome} onChange={text => setNome(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">CNPJ:</label>
                            </b>
                            <input type="text" name="cnpj" className={`form-control`} disabled value={cnpj} onChange={text => setCnpj(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Cidade/Estado:</label>
                            </b>
                            <input type="text" name="cidadeestado" className={`form-control`} disabled value={cidadeestado} onChange={text => setCidadeEstado(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Endereço:</label>
                            </b>
                            <input type="text" name="endereco" className={`form-control`} disabled value={endereco} onChange={text => setEndereco(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Número:</label>
                            </b>
                            <input type="text" name="numero" className={`form-control`} disabled value={numero} onChange={text => setNumero(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Bairro:</label>
                            </b>
                            <input type="text" name="bairro" className={`form-control`} disabled value={bairro} onChange={text => setBairro(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Cep:</label>
                            </b>
                            <input type="text" name="cep" className={`form-control`} disabled value={cep} onChange={text => setCep(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Telefone:</label>
                            </b>
                            <input type="text" name="telefone" className={`form-control`} disabled value={telefone} onChange={text => setTelefone(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Tipo de Instituição:</label>
                            </b>
                            <select
                                disabled
                                name="tipo_instituicao"
                                className="form-select"
                                onChange={handleTipoInstituicaoChange}
                                value={tipo}
                            >
                                <option value="">Selecione o tipo</option>
                                <option value="1">Matriz</option>
                                <option value="2">Filial</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputStatus" className="form-label">Status:</label>
                            </b>
                            <select id="inputStatus" className="form-select" disabled name="status" value={status} onChange={handleSelectChange}>
                                <option value="ativo">Ativo</option>
                                <option value="Inativo">Inativo</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Email:</label>
                            </b>
                            <input type="text" name="email" className={`form-control`} disabled value={email} onChange={text => setEmail(text.target.value)} ></input>
                        </div>
                        <div className="col-12 mb-4 text-center">
                            <Link to={"/cliente"}>
                                <button type="button" className="btn btn-success">
                                    Voltar
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    )
}