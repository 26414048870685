import React, { useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

export const AddPermission = () => {

    const [permissions, setPermission] = useState({
        permission: '',
        descricao: ''
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const valueInput = e => setPermission({ ...permissions, [e.target.name]: e.target.value });

    const validationSchema = yup.object().shape({
        permission: yup.string().trim().required('Necessário preencher o campo permissão!'),
        descricao: yup.string().trim().required('Necessário preencher o campo descrição!'),
    })

    const addPermission = async e => {
        e.preventDefault();

        try {
            await validationSchema.validate(permissions, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                // Yup Validation Error
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                // Retorna aqui para evitar a chamada à API em caso de erros de validação
                return;
            } else {
                // Handle other errors
                console.error('Erro ao adicionar usuário:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                // Retorna aqui para evitar a chamada à API em caso de outros erros
                return;
            }
        }


        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        console.log("permission", permissions);

        await api.post('/permission', permissions, headers)
            .then((response) => {
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Adicionar Permissão</h1>

                <hr />
                {retorno.type === 'error' ? 
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>{retorno.mensagem}</strong>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}
                <form onSubmit={addPermission} className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Permissão:</label>
                        </b>
                        <input type="text" name="permission" className={`form-control ${retorno?.permission && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.permission && <div className="invalid-feedback">{retorno.permission}</div>}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Descricao:</label>
                        </b>
                        <input type="text" name="descricao" className={`form-control ${retorno?.descricao && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.descricao && <div className="invalid-feedback">{retorno.descricao}</div>}
                    </div>

                    <div className="col-12 mb-4 text-center">
                        <Link to={"/permission"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/permission',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};