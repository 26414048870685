import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../config/configApi';
import { Context } from '../../Context/AuthContext';
import logo from '../../assets/img/fundo2.png';
import './Login.css';

export const Login = () => {
  const history = useHistory();
  const { authenticated, signIn } = useContext(Context);
  console.log("Situação do usuário na página login: " + authenticated);
  const [user, setUser] = useState({
      email: '',
      password: '',
      tipo: ''
  });
  const [status, setStatus] = useState({
      type: '',
      mensagem: '',
      loading: false
  });
  const valorInput = e => setUser({ ...user, [e.target.name]: e.target.value });
  const loginSubmit = async e => {
      e.preventDefault();
      setStatus({
          loading: true
      });
      const headers = {
          'Content-Type': 'application/json'
      }
      await api.post("/login", user, { headers })
          .then((response) => {
              console.log( "Dados de login", response);
              setStatus({
                  loading: false
              });
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('idUsuario', response.data.idUsuario);
              localStorage.setItem('nome', response.data.usuario);
              if (response.data.permissao === undefined) {
                localStorage.setItem('permissao', null);
              }
              else {
                localStorage.setItem('permissao', JSON.stringify(response.data.permissao));
              }
              localStorage.setItem('empresa', response.data.empresa_id);
              if (response.data.urlfoto === null) {
                localStorage.setItem('foto', null);
              }
              else {
                localStorage.setItem('foto', response.data.urlfoto);
              }
              localStorage.setItem('tipo', response.data.tipo);
              signIn(true);
              return history.push('/dashboard');
          }).catch((err) => {
              if (err.response) {
                  setStatus({
                      type: 'error',
                      mensagem: err.response.data.mensagem,
                      loading: false
                  });
              } else {
                  setStatus({
                      type: 'error',
                      mensagem: "Erro: tente mais tarde!",
                      loading: false
                  });
              }
          });
  }

    return (
      <div className="bg-gradient-primary">
        <section className="h-100 gradient-form">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-10">
                <div className="card rounded-3 text-black">
                  <div className="row g-0">
                    <div className="col-lg-6">
                      <div className="card-body p-md-5 mx-md-4">
                        <div className="text-center">
                          <img className="logo-img" src={logo} alt="logo"/>
                          <h4 className="mt-1 mb-5 pb-1">Nós somos REDUTO</h4>
                        </div>
                        <form onSubmit={loginSubmit}>
                          <div className="form-outline mb-4">
                            <label className="form-label">Email</label>
                            <input type="email" name="email" className="form-control" placeholder="Digite seu email" onChange={valorInput}/>
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label">Password</label>
                            <input type="password" name="password" className="form-control" placeholder="Digite sua senha" onChange={valorInput}/>
                          </div>
                          <div className="text-center pt-1 mb-5 pb-1">
                            {status.loading ? <button className="btn btn-block fa-lg gradient-custom-2" type="submit" disabled>Acessando...</button> : <button className="btn btn-block fa-lg gradient-custom-2" type="submit">Acessar</button>}
                          </div>
                          {status.type === 'error' ? 
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>{status.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div> : ""}
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                      <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                        <h4 className="mb-4">We are more than just a company</h4>
                        <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
};
