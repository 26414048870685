import React, { useEffect, useState } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';

import { Link, Redirect } from 'react-router-dom';

export const PerfilCliente = () => {

    const [data, setData] = useState([]);

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [cpf, setCPF] = useState('');
    const [senha, setSenha] = useState('');
    const [email, setEmail] = useState('');
    const [instituicao_id, setSelectedValueInstituicao] = useState(1);
    const [cargo_id, setSelectedValueCargo] = useState('');
    const [crm, setCrm] = useState('');
    const [nomeInstituicao, setNomeInstituicao] = useState('');	
    const [cargoOptions, setCargoOptions] = useState([]);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getPerfil();
                await getCargo();
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };

        fetchData();
    }, []);

    const headers = {
        'headers': {
            'Authorization': "Bearer " + localStorage.getItem('token')
        }
    }

    const getPerfil = async () => {

        await api.get("/funcionario-perfil", headers)
            .then((response) => {
                setId(response.data.id);
                setNome(response.data.nome);
                setCPF(response.data.cpf);
                setEmail(response.data.email);
                setSelectedValueInstituicao(response.data.instituicao_id);
                setCrm(response.data.crm);
                setSelectedValueCargo(response.data.cargo_id);
                getInstituicao(response.data.instituicao_id);
            }).catch((err) => {
                console.log("Perfil", err);
            });
    };

    const getInstituicao = async (id) => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.get("/instituicao/" + id, headers)
            .then((response) => {
                console.log("response.data", response.data);
                if (response.data) {
                    setNomeInstituicao(response.data.nome);
                } else {
                    console.log("Erro: Usuário não encontrado !");
                }

            }).catch((err) => {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro  não encontrado");
                }
            })
    }

    const getCargo = async () => {
        const headers = {
          'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        };
    
        await api.get("/cargo", headers)
            .then((response) => {
                setCargoOptions(response.data);
            }).catch((err) => {
                if (err) {
                    console.log("err", err);
                }
            });
      }        

    const EditarUsuario = async e => {
        e.preventDefault();
        console.log("e", e);
        const instituicao_id = undefined;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put("/funcionario", { id, nome, email, cpf, senha, cargo_id, crm, instituicao_id }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    console.log("err", err);
                } else {
                    console.log("Erro");
                }
            })
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                    <h1>Perfil</h1>

                    <hr />
                    <form className='row' onSubmit={EditarUsuario}>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome:</label>
                            </b>
                            <input type="text" name="nome" className={`form-control ${retorno?.nome && 'is-invalid'}`} value={nome} onChange={text => setNome(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">CPF:</label>
                            </b>
                            <input type="text" name="cpf" className={`form-control ${retorno?.cpf && 'is-invalid'}`} value={cpf} onChange={text => setCPF(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Email:</label>
                            </b>
                            <input type="text" name="email" className={`form-control ${retorno?.email && 'is-invalid'}`} value={email} onChange={text => setEmail(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Crm:</label>
                            </b>
                            <input type="text" name="crm" className={`form-control ${retorno?.crm && 'is-invalid'}`} value={crm} onChange={text => setCrm(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Senha:</label>
                            </b>
                            <input type="password" name="senha" className={`form-control ${retorno?.senha && 'is-invalid'}`} value={senha} onChange={text => setSenha(text.target.value)} ></input>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputCargo" className="form-label"><b>Cargo:</b></label>
                            <select id="inputCargo" className="form-select" name="nivel_id" value={cargo_id} onChange={text => setSelectedValueCargo(text.target.value)}>
                                {cargoOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.cargo}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Instituição:</label>
                            </b>
                            <input type="text" name="instituicao" className={`form-control ${retorno?.nomeInstituicao && 'is-invalid'}`} value={nomeInstituicao} disabled ></input>
                        </div>

                        <div className="col-12 mb-4 text-center">
                            <Link to={"/dashboard"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/dashboard',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Editar</button>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    );
}
