// Página para fazer merge entre dois pdfs mandados pelo úsuario

// import React, { useState } from 'react';
// import { NavBar } from "../../../components/NavBar";
// import { SideBar } from "../../../components/SideBar";
// import { PageContent } from "../../../components/PageComponent";
// import { PDFDocument } from 'pdf-lib';


// const MergePdf = () => {
//   const [pdfFile1, setPdfFile1] = useState(null);
//     const [pdfFile2, setPdfFile2] = useState(null);
//     const [mergedPDF, setMergedPDF] = useState(null);

//     const handleFile1Change = (e) => {
//         setPdfFile1(e.target.files[0]);
//     };

//     const handleFile2Change = (e) => {
//         setPdfFile2(e.target.files[0]);
//     };

//     const mergePDFs = async () => {
//         if (!pdfFile1 || !pdfFile2) {
//             alert('Por favor, selecione dois arquivos PDF.');
//             return;
//         }

//         const pdfBytes1 = await pdfFile1.arrayBuffer();
//         const pdfBytes2 = await pdfFile2.arrayBuffer();

//         const pdfDoc1 = await PDFDocument.load(pdfBytes1);
//         const pdfDoc2 = await PDFDocument.load(pdfBytes2);

//         const mergedPdf = await PDFDocument.create();

//         const copiedPages1 = await mergedPdf.copyPages(pdfDoc1, pdfDoc1.getPageIndices());
//         copiedPages1.forEach((page) => mergedPdf.addPage(page));

//         const copiedPages2 = await mergedPdf.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
//         copiedPages2.forEach((page) => mergedPdf.addPage(page));

//         const mergedPdfBytes = await mergedPdf.save();

//         const mergedPdfUrl = URL.createObjectURL(new Blob([mergedPdfBytes], { type: 'application/pdf' }));
//         setMergedPDF(mergedPdfUrl);
//     };


//     return (
//       <div className="sb-nav-fixed">
//       <NavBar />
//       <SideBar />
//       <PageContent>
//         <div className="container">
//           <h1>Executar Os</h1>
//           <hr />
//           <div>
//             <input type="file" accept=".pdf" onChange={handleFile1Change} />
//             <input type="file" accept=".pdf" onChange={handleFile2Change} />
//             <button onClick={mergePDFs}>Mesclar PDFs</button>
//             {mergedPDF && (
//                 <div>
//                     <iframe src={mergedPDF} width="100%" height="600px" title="Merged PDF"></iframe>
//                     <a href={mergedPDF} download="merged_pdf.pdf">Baixar PDF Mesclado</a>
//                 </div>
//             )}
//         </div>

//         </div>
//       </PageContent>
//     </div>
//     );
// };

// export default MergePdf;
