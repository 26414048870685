import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';

export const EditarBanco = (props) => {


    const [idEmpresa] = useState(props.match.params.idempresa);
    const [idbanco] = useState(props.match.params.idconta);
    console.log("id Visualizar idEmpresa", idEmpresa);
    console.log("id Visualizar idbanco", idbanco);

    const [banco, setBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [conta, setConta] = useState('');
    const [tipoconta, setTipoConta] = useState('');

    const handleSelectChange = (event) => {
        setTipoConta(event.target.value);
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const editarBanco = async (e) => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put("/banco", { idbanco, idEmpresa, banco, agencia, conta, tipoconta }, headers)
            .then((response) => {
                //console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })
    };


    useEffect(() => {
        const getConta = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };

            await api.get("/selecionarbanco/" + idbanco, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response", response);
                        setBanco(response.data.banco);
                        setAgencia(response.data.agencia);
                        setConta(response.data.conta);
                        setTipoConta(response.data.tipoconta);

                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getConta();
    }, [idEmpresa]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Editar Dados Bancarios</h1>

                <hr />
                {retorno.type === 'error' ?
                    <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}

                <form onSubmit={editarBanco} className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Banco:</label>
                        </b>
                        <input type="text" name="banco" className="form-control" value={banco} onChange={text => setBanco(text.target.value)}  ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Agencia:</label>
                        </b>
                        <input type="text" name="agencia" className="form-control" value={agencia} onChange={text => setAgencia(text.target.value)}  ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Conta:</label>
                        </b>
                        <input type="text" name="conta" className="form-control" value={conta} onChange={text => setConta(text.target.value)}  ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputTipoConta" className="form-label">Tipo Conta:</label>
                        </b>
                        <select id="inputTipoConta" className="form-select" name="tipoconta" value={tipoconta} onChange={handleSelectChange} >
                            <option value="contacorrente">Conta Corrente</option>
                            <option value="poupanca">Poupança</option>
                        </select>
                    </div>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/banco/" + idEmpresa}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/banco/' + idEmpresa,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }
                            }} /> : ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}