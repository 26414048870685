import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import localforage from 'localforage';

export const ViewUsuario = (props) => {

    //verificar campos que vai receber valor para editar
    const [nome, setNome] = useState('');
    const [cpf, setCPF] = useState('');
    const [foto, setFoto] = useState('');
    const [endImg, setEndImg] = useState('');
    const [email, setEmail] = useState('');
    const [titulacao, setTitulacao] = useState('');
    const [titulacaocnen, setTitulacaocnen] = useState('');
    const [complementocnen, setComplementocnen] = useState('');
    const [vinculo, setVinculo] = useState('');
    const [qualificacao, setQualificacao] = useState('');
    const [tipoarquivocertificado, setTipoarquivocertificado] = useState('');
    const [certificado, setCertificado] = useState('');
    const [empresa_id, setSelectedValueEmpresa] = useState(null);
    const [nivel_id, setSelectedValueNivel] = useState(null);
    const [area_id, setSelectedValueArea] = useState(null);
    const [email_enviado, setEmailEnviado] = useState(null);
    const [retornoemail, setRetornoEmail] = useState(null);

    const [possui, setPossui] = useState([
        { value: 'selecione', label: 'Selecione' },
        { value: 'possui', label: 'Possui' },
        { value: 'naopossui', label: 'Não Possui' }
    ]);

    const [id] = useState(props.match.params.id);
    //console.log("id Editar Usuario", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [empresaOptions, setEmpresaOptions] = useState([]);
    const [nivelOptions, setNivelOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [verificacnen, setVerificacnen] = useState([]);
    const [verificaNivel, setverificaNivel] = useState([null]);

    const handleSelectChangeEmpresa = (event) => {
        event.preventDefault();
        setSelectedValueEmpresa(event.target.value);
    };

    const handleSelectChangeNivel = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueNivel(event.target.value);
    };

    const handleSelectChangeArea = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueArea(event.target.value);
    };


    // const onChangeComboEmpresa = (e) => {
    //     console.log("e", e);
    //     const selectedIdEmpresa = e.target.value;
    //     console.log("selectedIdEmpresa", selectedIdEmpresa);
    //     const selectEmpresaState = empresaOptions.filter((d) => d.id == selectedIdEmpresa);
    //     console.log("selectEmpresaState", selectEmpresaState);
    //     // // 
    //     // setEmpresaOptions(selectEmpresaState); 
    // }

    const handleSelectTitulacao = (event) => {
        setTitulacao(event.target.value);
    };

    // const verificaPossuiCnen = (event) => {
    //     event.preventDefault();
    //     console.log("Possui", event.target.value);
    //     console.log("verificacnen", verificacnen);
    //     console.log("titulacaocnen", titulacaocnen);
    //     setTitulacaocnen(event.target.value);
    // };

    const VisualizarPDF = async (e) => {
        console.log(e, "e");
        if (e !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', e);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getUsuario = async () => {


            await api.get("/usuario/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("get useEffect", response.data);
                        setNome(response.data.nome);
                        setCPF(response.data.cpf);
                        setEmail(response.data.email);
                        setTitulacao(response.data.titulacao);
                        setTitulacaocnen(response.data.titulacao_cnen);
                        setVinculo(response.data.vinculo);
                        setComplementocnen(response.data.complementocnen);
                        setVerificacnen(response.data.titulacao_cnen);
                        setSelectedValueEmpresa(response.data.empresa_id);
                        setSelectedValueNivel(response.data.typeUser_id);
                        setverificaNivel(response.data.typeUser_id);
                        setSelectedValueArea(response.data.area_id);
                        setEndImg(response.data.endImagem);
                        setQualificacao(response.data.qualificacao);
                        setTipoarquivocertificado(response.data.tipoarquivocertificado);
                        setCertificado(response.data.endCertificado);
                        if (response.data.email_enviado === 1) {
                            setRetornoEmail("Email enviado com sucesso!");
                        }
                        setEmailEnviado(response.data.email_enviado);
                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado !"
                        });
                    }
                    //console.log(response.data);

                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: tente mais tarde !"
                        });
                    }
                })
        };

        const empresaOptions =
            async () => {
                try {
                    const response = await api.get("/empresa/", headers);
                    setEmpresaOptions(response.data);
                    //console.log("response empresa", response);
                } catch (error) {
                    console.error('Erro ao obter opções de empresa:', error);
                }
            };

        const nivelOptions = async () => {
            try {
                const response = await api.get("/typeuser/", headers);
                //console.log("response typeUser", response);
                setNivelOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        };

        const getArea = async () => {
            await api.get("/area/", headers)
                .then((response) => {
                    //console.log("area", response)
                    setAreaOptions(response.data);
                }).catch((err) => {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                });
        };

        getArea();
        empresaOptions();
        nivelOptions();
        getUsuario();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Visualizar Usuário</h1>

                    <hr />
                    <form className='row'>
                        {email_enviado === 1 && (
                            <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                <label htmlFor="detalhes" className="form-label">
                                    <b>Email de Confirmação de cadastrado</b>
                                </label>
                                <input type="text" name="envioTreinamento" className="form-control" value={retornoemail} disabled ></input>
                            </div>
                        )}
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome:</label>
                            </b>
                            <input type="text" name="nome" className="form-control" value={nome} onChange={text => setNome(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">CPF:</label>
                            </b>
                            <input type="text" name="cpf" className="form-control" value={cpf} onChange={text => setCPF(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Email:</label>
                            </b>
                            <input type="text" name="email" className="form-control" value={email} onChange={text => setEmail(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputNivel" className="form-label"><b>Nivel:</b></label>
                            <select id="inputNivel" className="form-select" name="nivel_id" value={nivel_id} onChange={handleSelectChangeNivel} disabled>
                                {nivelOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.type}</option>
                                ))}
                            </select>
                        </div>
                        {verificaNivel !== 25 && <>
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label for="inputTitulacao" className="form-label">Titulação:</label>
                                </b>
                                <select
                                    id="inputTitulacao"
                                    className="form-select"
                                    name="titulacao"
                                    onChange={handleSelectTitulacao}
                                    value={titulacao}
                                    disabled
                                >
                                    <option value="">Selecione</option>
                                    <option value="graduacao">Graduação</option>
                                    <option value="mestrado">Mestrado</option>
                                    <option value="doutorado">Doutorado</option>
                                    <option value="especialista">Especialista</option>
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label for="inputTitulacao" className="form-label">Titulação CNEN:</label>
                                </b>
                                <select
                                    id="inputTitulacao"
                                    className="form-select"
                                    name="titulacaocnen"
                                    defaultValue={verificacnen}
                                    value={titulacaocnen}
                                    disabled
                                >
                                    {possui.map((ps) => (
                                        <option value={ps.value}>{ps.label}</option>
                                    ))}
                                </select>
                            </div>
                            {verificacnen === 'possui' && <>
                                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                    <b>
                                        <label className="form-label">Regristo CNEN*:</label>
                                    </b>
                                    <input type="text" autoComplete="off" name="complementocnen" value={complementocnen} placeholder="Digite o Regristo CNEN" className="form-control" disabled ></input>
                                </div>
                            </>}
                        </>}

                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Vínculo:</label>
                            </b>
                            <select
                                disabled
                                className="form-select"
                                name="vinculo"
                                onChange={text => setVinculo(text.target.value)}
                                value={vinculo}
                            >
                                <option value="">{vinculo}</option>
                                <option value="proprio">Próprio</option>
                                <option value="terceiro">Terceiro</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputEmpresa" className="form-label"><b>Empresa:</b></label>
                            <select id="inputEmpresa" className="form-select" name="empresa_id" value={empresa_id} onChange={handleSelectChangeEmpresa} disabled>
                                {empresaOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.nome}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label for="inputArea" className="form-label"><b>Area:</b></label>
                            <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea} disabled>
                                {areaOptions.map((option) => (
                                    <option key={option.id} value={option.id}>{option.area}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <label>Foto*: </label><br /><br />

                            {/* Verifica se há uma foto ou exibe a mensagem "Sem foto" */}
                            {foto ? (
                                <img
                                    src={URL.createObjectURL(foto)}
                                    alt="Imagem do usuário"
                                    className="rounded-circle"
                                    width="150"
                                    height="150"
                                />
                            ) : (
                                endImg ? (
                                    <img
                                        src={endImg}
                                        className="rounded-circle"
                                        alt="Imagem do usuário"
                                        width="150"
                                        height="150"
                                    />
                                ) : (
                                    <p>Sem foto</p>
                                )
                            )}
                            <br /><br />
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-4 form-outline mb-4">
                            <label htmlFor="qualificacao" className="form-label">
                                Qualificações
                            </label>
                            <textarea
                                type="text"
                                disabled
                                className="form-control"
                                value={qualificacao}
                                id="qualificacao"
                                name="qualificacao"
                                rows="3"
                                onChange={text => setQualificacao(text.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                            {tipoarquivocertificado === 'pdf' && <>
                                <button type='button' onClick={() => VisualizarPDF(certificado)} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                            </>}

                        </div>

                        <div className="col-12 mb-4 text-center">
                            <Link to={"/usuarios"}>
                                <button type="button" className="btn btn-success">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/usuarios',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }
                                }} /> :
                                ""}
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    )
}