import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import { number } from 'yup';
import { addYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';

import localforage from 'localforage';

export const EditarEquipamentoInterno = (props) => {

    // Acrescentar a opção de envio de foto/PDF (ok) e verificar as variaveis da foto: variaveis foto, viewMostrar, tipoarquivocertificado
    // Fazer um post na api de sondas para criar sondas enviando o id do equipamento interno e o objeto sonda
    // Acrescentar a foto/pdf na tela e fazer um teste enviando uma imagem ou um pdf e o tipo do arquivo.
    // colocar um contador para enviar sondas de acordo com a quantidade de sondas.

    const [nome, setNome] = useState('');
    const [numeroserie, setNumeroSerie] = useState('');
    const [patrimonio, setPatrimonio] = useState('');
    const [status, setStatus] = useState('');
    const [vinculo, setVinculo] = useState('');

    const [id] = useState(props.match.params.id);
    console.log("id Visualizar Equipamento Interno", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [empresaOptions, setEmpresaOptions] = useState([]);
    const [checkedValues, setValue] = useState([]);

    const [sondaOptions, setSondaOptions] = useState([]);

    const [tipoEquipamentoOptions, settipoEquipamentoOptions] = useState([]);
    const [tipo_equipamento_id, setSelectedValueTipoEquipamento] = useState(null);

    const [tipoSondaOptions, setTipoSondaOptions] = useState([]);


    const [aplicacaoOptions, setAplicacaoOptions] = useState([]);
    const [novaSondaAplicacaoOptions, setNovaSondaAplicacaoOptions] = useState([]);
    const [dataRenovacao, setdataRenovacao] = useState([]);
    const [checkedValuesAplicacao, setValueAplicacao] = useState([]);
    const [aplicacaoselecionadaOptions, setAplicacaoSelecionadaOptions] = useState([]);
    const [listaTecnicoEmpresa, setListaTecnicoEmpresaOptions] = useState([]);
    const [tecnico, setTecnico] = useState('');
    const [viewMostrar, setViewMostrar] = useState(null);
    const [arquivocertificado, setArquivocertificado] = useState('');
    const [image, setImage] = useState('');
    const [endImg] = useState('./icone_usuario.png');


    const [sondas, setSondas] = useState([])
    const [sondasNovas, setSondasNovas] = useState([])

    const [sonda, setSonda] = useState({
        nomeSonda: '',
        numeroserieSonda: '',
        patrimonioSonda: '',
        laboratorio: '',
        numerocertificadocalibracao: '',
        datacalibracao: '',
        datarenovacaocalibracao: '',
        tipo_sonda: number,
        aplicacao: ''
    });
    const salvarSonda = async e => {
        e.preventDefault();
        setViewMostrar(0);
        setImage('');
        console.log("check box", aplicacaoselecionadaOptions);
        console.log("salvar sonda", sonda);
        console.log("salvar sonda", checkedValuesAplicacao);
        console.log("checkbox sonda", aplicacaoOptions);
        console.log("novaSondaAplicacaoOptions ", novaSondaAplicacaoOptions);
        const novaSonda = { ...sonda, aplicacao: novaSondaAplicacaoOptions, foto: image, tipoarquivocertificado: arquivocertificado };
        console.log("novaSonda", novaSonda);
        setSondasNovas([...sondasNovas, novaSonda])
        setSonda({
            nomeSonda: '',
            numeroserieSonda: '',
            patrimonioSonda: '',
            laboratorio: '',
            numerocertificadocalibracao: '',
            datacalibracao: '',
            datarenovacaocalibracao: '',
            tipoarquivocertificado: '',
            tipo_sonda: null,
        });
        limparCamposAplicacao();
        setdataRenovacao('');

        console.log("sonda", sonda);

    }

    const limparCamposAplicacao = () => {
        setNovaSondaAplicacaoOptions(novaSondaAplicacaoOptions.map(option => ({ ...option, checked: false })));
    };

    const salvarSondaAntiga = async e => {
        e.preventDefault();
        sonda.aplicacao = novaSondaAplicacaoOptions;
        setSondas([...sondas, sonda])
        setSonda({
            nomeSonda: '',
            numeroserieSonda: '',
            patrimonioSonda: '',
            laboratorio: '',
            numerocertificadocalibracao: '',
            datacalibracao: '',
            datarenovacaocalibracao: '',
            tipoarquivocertificado: '',
            tipo_sonda: null,
            aplicacao: ''
        });
        setdataRenovacao('');
    }

    const valueInputSonda = e => setSonda({ ...sonda, [e.target.name]: e.target.value });

    const geraDataRenovacao = (event) => {
        var datadecalibracao = event.target.value;

        sonda.datacalibracao = datadecalibracao;

        var znDate = zonedTimeToUtc(datadecalibracao, 'America/Sao_Paulo');
        console.log("znDate", znDate);

        var adddoisanos = addYears(znDate, 2);

        var dataFormatada = format(adddoisanos, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });
        console.log("dataFormatada", dataFormatada);
        setdataRenovacao(dataFormatada);

        sonda.datarenovacaocalibracao = dataFormatada;

    }

    console.log("checkedValues", checkedValues);

    const handleSelectChangeTipoEquipamento = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueTipoEquipamento(event.target.value);
    };



    const editarEquipamentoInterno = async e => {
        e.preventDefault();
        //console.log("e", e);
        //console.log("id, nome, numeroserie, patrimonio, status, vinculo", id, nome, numeroserie, patrimonio, status, vinculo, empresaOptions);

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const headersFoto = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        console.log(" { id, nome, numeroserie, patrimonio, status, vinculo, empresaOptions }" + id, nome, numeroserie, patrimonio, status, vinculo, aplicacaoselecionadaOptions, aplicacaoOptions, empresaOptions);
        console.log("sondasNovas", sondasNovas);

        var verificarEnvioEquipamentoInterno = false;
        await api.put("/equipamentointerno", { id, nome, numeroserie, patrimonio, status, vinculo, tipo_equipamento_id, empresaOptions, aplicacaoOptions }, headers)
            .then((response) => {
                console.log("response", response);
                verificarEnvioEquipamentoInterno = true;
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            });
        // Fazer um post na api de sondas para criar sondas enviando o id do equipamento interno e o objeto sonda
        // Acrescentar a foto/pdf na tela e fazer um teste enviando uma imagem ou um pdf e o tipo do arquivo.
        // colocar um contador para enviar sondas de acordo com a quantidade de sondas.
        var contador = 0;
        var contadorSucesso = 0;
        for (let index = 0; index < sondasNovas.length; index++) {
            const element = sondasNovas[index];
            element.id = id;
            contador = contador + 1;
            console.log("response sondaAplicacao", element);
            await api.post('/sondaAplicacao', element, headersFoto)
                .then((response) => {
                    console.log("response equipamentointerno", response);
                    contadorSucesso = contadorSucesso + 1;

                })
                .catch((err) => {

                });
        }

        console.log("contador", contador);
        console.log("sondasNovas.length", sondasNovas.length);
        console.log("verificarEnvioEquipamentoInterno", verificarEnvioEquipamentoInterno);
        console.log("contadorSucesso", contadorSucesso);

        if (contador === sondasNovas.length && contadorSucesso === sondasNovas.length && verificarEnvioEquipamentoInterno) {
            setRetorno({
                type: 'redSuccess',
                mensagem: 'Atualizado com sucesso'
            });
        } else {
            setRetorno({
                type: 'erro',
                mensagem: 'Algum dos cadastro de sonda deu erro'
            });
        }
        // if (verificarEnvioEquipamentoInterno) {
        //     setRetorno({
        //         type: 'redSuccess',
        //         mensagem: 'Equipamento Interno Editado com sucesso'
        //     });
        // }    

    }

    const deletarSonda = async (idItem) => {
        console.log("idSonda", idItem);
        if (window.confirm('Tem certeza que deseja excluir este registro?')) {

            const headers = {
                'headers': {
                    'Authorization': "Bearer " + localStorage.getItem('token')
                }
            }

            await api.delete("/sonda/" + idItem, headers)
                .then((response) => {
                    console.log("response", response);
                    setStatus({
                        type: 'redSuccess',
                        mensagem: response.data.mensagem
                    });
                }).catch((err) => {
                    if (err.response) {
                        console.log("response", err.response);
                    } else {
                        console.log("'Erro: Tente mais tarde!");
                    }
                })
        }

    }

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSelectChangeVinculo = (event) => {
        setVinculo(event.target.value);
    };

    const checkBoxChange = (e, index) => {

        let res = [...empresaOptions];
        res[index].checked = e.target.checked;
        setEmpresaOptions(res);

        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);

        console.log("index", index);

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const checkBoxChangeAplicacaoselecionada = (event, index) => {
        const { value, checked } = event.target;

        let res = [...aplicacaoOptions];
        res[index].checked = event.target.checked;
        setAplicacaoOptions(res);

        if (checked) {
            setAplicacaoSelecionadaOptions(pre => [...pre, value])
        } else {
            setAplicacaoSelecionadaOptions(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const checkBoxChangeAplicacao = (event, index) => {
        const { value, checked } = event.target;

        let res = [...novaSondaAplicacaoOptions]
        res[index].checked = event.target.checked
        setNovaSondaAplicacaoOptions(res)

        if (checked) {
            setValueAplicacao(pre => [...pre, value])
        } else {
            setValueAplicacao(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const handleSelectChangeTecnico = (event) => {
        event.preventDefault();
        console.log(event.target.value);
        setSelectedValueTipoEquipamento(event.target.value);
    }

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrar(1);
                setArquivocertificado('pdf');
                setImage(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrar(2);
                setArquivocertificado('imagem');
                setImage(selectedFile);
            }
        }

    }

    const VisualizarPDF = async () => {
        if (image !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', image);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const VisualizarExistente = async (e) => {
        if (e !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', e);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getEquipamentoInterno = async () => {
            await api.get("/equipamentointerno/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response olhe aqui sondas", response.data);
                        setNome(response.data.nome);
                        setNumeroSerie(response.data.numeroserie);
                        setPatrimonio(response.data.patrimonio);
                        setStatus(response.data.status);
                        setVinculo(response.data.vinculo);
                        setTecnico(response.data.usuario_id);
                        setEmpresaOptions(response.data.empresas);
                        setAplicacaoOptions(response.data.aplicacao);
                        setSondaOptions(response.data.sonda);
                        setSelectedValueTipoEquipamento(response.data.tipo_equipamento_id);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const tipoEquipamentoOptions = async () => {
            try {
                const response = await api.get("/tipoequipamento", headers);
                console.log("response tipoequipamento", response.data);
                settipoEquipamentoOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        const getNovaSondaAplicacaoOptions = async () => {
            try {
                const response = await api.get("/aplicacaoValidando", headers);
                console.log("response aplicacao", response.data);
                setNovaSondaAplicacaoOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        const tipoSonda = async () => {
            try {
                const response = await api.get("/tiposonda", headers);
                console.log("response classe", response);
                setTipoSondaOptions(response.data); // Substitua por onde os dados necessários estão no objeto de resposta
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        const listaTecnicoEmpresa = async () => {
            try {
                const response = await api.get("/usuarioTecnico", headers);
                console.log("response usuarioTecnico", response.data);
                setListaTecnicoEmpresaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        tipoSonda();
        getEquipamentoInterno();
        tipoEquipamentoOptions();
        getEquipamentoInterno();
        getNovaSondaAplicacaoOptions();
        listaTecnicoEmpresa();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Editar Equipamento Interno</h1>
                <hr />
                <form className='row' onSubmit={editarEquipamentoInterno}>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nome" className="form-control" value={nome} onChange={text => setNome(text.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">N° de serie:</label>
                        </b>
                        <input type="text" name="numeroserie" className="form-control" value={numeroserie} onChange={text => setNumeroSerie(text.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Patrimonio:</label>
                        </b>
                        <input type="text" name="patrimonio" className="form-control" value={patrimonio} onChange={text => setPatrimonio(text.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputStatus" className="form-label">Status:</label>
                        </b>
                        <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange}>
                            <option value="vencido">Vencido</option>
                            <option value="válido">Válido</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputVinculo" className="form-label">Vínculo:</label>
                        </b>
                        <select id="inputVinculo" className="form-select" name="vinculo" value={vinculo} onChange={handleSelectChangeVinculo}>
                            <option value="sede">Sede</option>
                            <option value="tecnico">Tecnico</option>
                            <option value="comodato">Comodato</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <label for="inputTipoEquipamento" className="form-label"><b>Classificação:</b></label>
                        <select id="inputTipoEquipamento" className="form-select" disabled name="tipo_equipamento_id" value={tipo_equipamento_id} onChange={handleSelectChangeTipoEquipamento}>
                            {tipoEquipamentoOptions.map((option) => (
                                <option key={option.id} value={option.id}>{option.tipo}</option>
                            ))}
                        </select>
                    </div>
                    {tecnico != undefined ? <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputUsuario" className="form-label">Técnicos*:</label>
                        </b>
                        <select
                            id="inputUsuario"
                            className="form-select"
                            name="usuario_id"
                            disabled
                            onChange={handleSelectChangeTecnico}
                            value={tecnico}
                        >
                            <option value="">Selecione</option>
                            {listaTecnicoEmpresa.map((listaUsuario) => (
                                <option value={listaUsuario.id}>{listaUsuario.nome}</option>
                            ))}
                        </select>
                    </div> : " "}
                    <hr />
                    <h1>Empresas vinculadas ao equipamento</h1>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <div class="row">
                            {empresaOptions?.map((itemEmpresa, index) =>
                                <div key={index} className="form-check col-md-4">
                                    <div>
                                        <input type="checkbox" className="form-check-input" checked={itemEmpresa.checked} value={itemEmpresa.id} id={itemEmpresa.id} onChange={(e) => checkBoxChange(e, index)} />
                                        <label className="form-check-label" for={itemEmpresa.id}>{itemEmpresa.nome}</label>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                    {tipo_equipamento_id == '2' && <><hr />
                        <h1>Aplicação</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div class="row">
                                {aplicacaoOptions.map((itemAplicacao, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" checked={itemAplicacao.checked} value={itemAplicacao.id} id={itemAplicacao.id} onChange={(e) => checkBoxChangeAplicacaoselecionada(e, index)} />
                                            <label className="form-check-label" for={itemAplicacao.id}>{itemAplicacao.aplicacao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </>}
                    {tipo_equipamento_id == '1' && <><hr />
                        <h2>Vincular nova sonda</h2>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nome:</label>
                            </b>
                            <input type="text" name="nomeSonda" className="form-control" value={sonda.nomeSonda} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">N° de serie:</label>
                            </b>
                            <input type="text" name="numeroserieSonda" className="form-control" value={sonda.numeroserieSonda} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Patrimonio:</label>
                            </b>
                            <input type="text" name="patrimonioSonda" className="form-control" value={sonda.patrimonioSonda} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Laboratorio:</label>
                            </b>
                            <input type="text" name="laboratorio" className="form-control" value={sonda.laboratorio} onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">N° Certificado de calibração:</label>
                            </b>
                            <input type="text" name="numerocertificadocalibracao" value={sonda.numerocertificadocalibracao} className="form-control" onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Data calibração:</label>
                            </b>
                            <input type="date" name="datacalibracao" className="form-control" value={sonda.datacalibracao} onChange={geraDataRenovacao}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Data da renovação de calibração:</label>
                            </b>
                            <input type="date" name="datarenovacaocalibracao" value={dataRenovacao} className="form-control" onChange={valueInputSonda}></input>
                        </div><div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                            </b>
                            <select
                                id="inputTipoSonda"
                                className="form-select"
                                name="tipo_sonda"
                                onChange={valueInputSonda}
                                value={tipoSondaOptions.tipo}
                            >
                                <option value="">Selecione</option>
                                {tipoSondaOptions.map((opcao) => (
                                    <option value={opcao.id}>{opcao.tipo}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                            <label for="formFile" className="form-label">Selecione uma foto/pdf do certificado</label>
                            <input className="form-control" type="file" name="sonda.foto" id="formFile" onChange={veirificaTipoArquivo} /><br /><br />

                            {viewMostrar === 1 && <>
                                <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                                {/* <button type='submit' className='btn btn-success' >Visualizar PDF</button> */}
                            </>}

                            {viewMostrar === 2 && <>
                                {image ? <img src={URL.createObjectURL(image)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={endImg} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                            </>}

                        </div>

                        <h1>Aplicação</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div class="row">
                                {novaSondaAplicacaoOptions.map((itemNovaSonda, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" checked={itemNovaSonda.checked} value={itemNovaSonda.id} id={itemNovaSonda.id} onChange={(e) => checkBoxChangeAplicacao(e, index)} />
                                            <label className="form-check-label" for={itemNovaSonda.id}>{itemNovaSonda.aplicacao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                        <button type="button" className="btn btn-success" onClick={salvarSonda}>Salvar Sonda</button>
                        <hr />
                        <h2>Sondas vinculadas</h2>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div className="row">
                                {sondaOptions?.map((ItemSonda) => (
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Nome: {ItemSonda.nome}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted"> numero de serie: {ItemSonda.numeroserie}</h6>
                                            <p class="card-text">Patrimonio: {ItemSonda.patrimonio}</p>
                                            <p class="card-text">Laboratorio: {ItemSonda.laboratorio}</p>
                                            <p class="card-text">N° certificado de calibração: {ItemSonda.numcertificadocalibracao}</p>
                                            <label className="form-label">Data de calibração:</label>
                                            <input class="card-text" type="text" name="datarenovacaocalibracao" disabled value={ItemSonda.data_calibracao} className="form-control"></input>
                                            <p class="card-text">Data de renovação da calibração:</p>
                                            <input class="card-text" type="text" name="datarenovacaocalibracao" disabled value={ItemSonda.data_renovacao_calibracao} className="form-control"></input>
                                            <br />
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                                                {ItemSonda.tipoarquivocertificado === 'pdf' && <>

                                                    <button type='button' onClick={() => VisualizarExistente(ItemSonda.endImagem)} className="btn btn-success">
                                                        Visualizar PDF
                                                    </button>

                                                </>}

                                                {ItemSonda.tipoarquivocertificado === 'imagem' && <>
                                                    {ItemSonda.endImagem ? <img src={ItemSonda.endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={ItemSonda.endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                                                </>}

                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                                <b>
                                                    <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                                                </b>
                                                <select
                                                    id="inputTipoSonda"
                                                    className="form-select"
                                                    disabled
                                                    name="tipo_sonda"
                                                    value={ItemSonda.tipo_sonda_id}
                                                >
                                                    <option value="">Selecione</option>
                                                    {tipoSondaOptions.map((opcao) => (
                                                        <option value={opcao.id}>{opcao.tipo}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                                <h3>Aplicação vinculadas</h3>
                                                <div class="row">
                                                    {ItemSonda.aplicacao.map((aplica, index) =>
                                                        <div key={index} className="form-check col-md-4">
                                                            <div>
                                                                <input type="checkbox" className="form-check-input" disabled checked={aplica.checked} value={aplica.id} id={aplica.id} />
                                                                <label className="form-check-label" for={aplica.id}>{aplica.aplicacao}</label>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </div>
                                            <div className="col-12 mb-4 text-center">
                                                <Link to={"/editarSondaEquipamentoInterno/" + ItemSonda.id + "/" + id}>
                                                    <button type="button" className="btn btn-success me-md-2">
                                                        Editar
                                                    </button>
                                                </Link>
                                                <Link to={"#"}>
                                                    <button type="button" onClick={() => deletarSonda(ItemSonda.id)} className="btn btn-danger">
                                                        Excluir
                                                    </button>
                                                </Link>
                                            </div>
                                            {/* <label for="inputArea" className="form-label"><b>Tipo:</b></label>
                                    <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea}>
                                            {areaOptions.map((option) => (
                                                <option key={option.id} value={option.id}>{option.area}</option>
                                            ))}
                                    </select> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="row">
                            {sondasNovas?.map((item) => (
                                <div className="card">
                                    <h3>Novas sondas selecionadas</h3>
                                    <div className="card-body">
                                        <h5 className="card-title">Nome: {item.nomeSonda}</h5>
                                        <h6 className="card-subtitle mb-2 text-muted"> numero de serie: {item.numeroserieSonda}</h6>
                                        <p class="card-text">Patrimonio: {item.patrimonioSonda}</p>
                                        <p class="card-text">Laboratorio: {item.laboratorio}</p>
                                        <p class="card-text">N° certificado de calibração: {item.numerocertificadocalibracao}</p>
                                        <label className="form-label">Data de calibração:</label>
                                        <input class="card-text" type="date" name="datarenovacaocalibracao" disabled value={item.datacalibracao} className="form-control"></input>
                                        <p class="card-text">Data de renovação da calibração:</p>
                                        <input class="card-text" type="date" name="datarenovacaocalibracao" disabled value={item.datarenovacaocalibracao} className="form-control"></input>
                                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                            <b>
                                                <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                                            </b>
                                            <select
                                                id="inputTipoSonda"
                                                className="form-select"
                                                disabled
                                                name="tipo_sonda"
                                                onChange={valueInputSonda}
                                                value={item.tipo_sonda}
                                            >
                                                <option value="">Selecione</option>
                                                {tipoSondaOptions.map((opcao) => (
                                                    <option value={opcao.id}>{opcao.tipo}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                                            <h3>Aplicação</h3>
                                            <div class="row">
                                                {item.aplicacao.map((aplica, index) =>
                                                    <div key={index} className="form-check col-md-4">
                                                        <div>
                                                            <input type="checkbox" className="form-check-input" disabled checked={aplica.checked} value={aplica.id} id={aplica.id} onChange={(e) => checkBoxChangeAplicacaoselecionada(e, index)} />
                                                            <label className="form-check-label" for={aplica.id}>{aplica.aplicacao}</label>
                                                        </div>
                                                    </div>)}
                                            </div>
                                        </div>
                                        {/* <label for="inputArea" className="form-label"><b>Tipo:</b></label>
                                    <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea}>
                                            {areaOptions.map((option) => (
                                                <option key={option.id} value={option.id}>{option.area}</option>
                                            ))}
                                    </select> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>}

                    <div className="col-12 mb-4 text-center">
                        <Link to={"/equipamentointerno"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/equipamentointerno',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Editar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}