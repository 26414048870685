import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewFuncionarioInstituicao = (props) => {

  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [crm, setCrm] = useState('');
  const [cargo, setCargo] = useState('');
  const [cargos, setCargos] = useState([]);
  const [cargo_id, setCargo_id] = useState('');

  const [id] = useState(props.match.params.id);
  const [idinstituicao] = useState(props.match.params.idinstituicao);
  console.log("id Visualizar Empresa", id);

  const handleCargoChange = (e) => {
    setCargo_id(e.target.value);
  };

  useEffect(() => {
    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };
    const getCargo = async () => {
      await api.get("/cargo", headers)
        .then((response) => {
          console.log("response", response.data);
          setCargos(response.data);
        }).catch((err) => {
          if (err) {
            console.log("err", err);
          }
        });
    }


    const getFuncionarioInstituicao = async () => {


      await api.get("/funcionario/selecionar/" + id, headers)
        .then((response) => {
          console.log("response", response);
          if (response.data) {
            setNome(response.data.nome);
            setCpf(response.data.cpf);
            setEmail(response.data.email);
            setCrm(response.data.crm);
            setCargo(response.data.cargo_id);
          } else {
            console.log("Erro: funcionario não encontrado !");
          }

        }).catch((err) => {
          if (err) {
            console.log("Erro");
          } else {
            console.log("Erro  não encontrado");
          }
        })
    }
    getFuncionarioInstituicao();
    getCargo();
  }, [id]);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className='container' style={{ marginLeft: '220px' }}>
          <h1>Visualizar Funcionario</h1>
          <form className='row'>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Nome:</label>
              </b>
              <input
                type="text"
                name="nome"
                className="form-control"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <label className="form-label"><b>Cpf:</b></label>
              <input
                type="text"
                name="cpf"
                className="form-control"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <label className="form-label"><b>E-mail:</b></label>
              <input
                type="text"
                name="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <label className="form-label"><b>Crm:</b></label>
              <input
                type="text"
                name="crm"
                className="form-control"
                value={crm}
                onChange={(e) => setCrm(e.target.value)}
                disabled
              />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Cargo:</label>
              </b>
              <select
                name="cargo"
                className="form-select"
                onChange={handleCargoChange}
                value={cargo}
                disabled
              >
                <option value="">Selecione o cargo</option>
                {cargos.map((cargoItem) => (
                  <option key={cargoItem.id} value={cargoItem.id}>
                    {cargoItem.cargo}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-12 mb-4 text-center'>
              <Link to={"/addfuncionarioinstituicao/" + idinstituicao}>
                <button type="button" className="btn btn-success">
                  Voltar
                </button>
              </Link>
            </div>
          </form>
        </div>
      </PageContent>
    </div>
  )
}