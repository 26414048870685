import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const AddServico = () => {

    const [valorSelecionado, setValorSelecionado] = useState('');
    const opcoes = ['calculo de blindagem', 'contratos', 'controle de qualidade', 'levantamento radiométrico'];

    const handleChange = (event) => {
        console.log("event", event.target.value);
        var tipoServico = event.target.value;
        setValorSelecionado(event.target.value);
        servicos.tipo = tipoServico;
    };

    const [servicos, setServico] = useState({
        tipo: '',
        area_id: '',
        servico: ''
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [areaOptions, setAreaOptions] = useState([]);


    const valueInput = e => setServico({ ...servicos, [e.target.name]: e.target.value });

    const addServico = async e => {
        e.preventDefault();

        console.log("serviço", servicos);

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.post('/servico', servicos, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const areaOptions = async () => {
            try {
                const response = await api.get("/area", headers);
                console.log("response typeUser", response);
                setAreaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        areaOptions();
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Adicionar Serviço</h1>

                    <hr />
                    {retorno.type === 'error' ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                    <form onSubmit={addServico} className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputArea" className="form-label">Área :</label>
                            </b>
                            <select
                                id="inputArea"
                                className="form-select"
                                name="area_id"
                                onChange={valueInput}
                                value={areaOptions.area}
                            >
                                <option value="">Selecione</option>
                                {areaOptions.map((opcao) => (
                                    <option value={opcao.id}>{opcao.area}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Serviço:</label>
                            </b>
                            <input type="text" autoComplete="off" name="servico" placeholder="Digite o Serviço" className="form-control" onChange={valueInput} ></input>
                        </div>
                        <div className="col-12 mb-4 text-center">
                            <Link to={"/servico"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'success' ?
                                <Redirect to={{
                                    pathname: '/servico',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Salvar</button>
                        </div>
                    </form>
                </div>
            </PageContent >
        </div >
    );
};