import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Usuarios = () => {

  const { state } = useLocation();

  const [data, setData] = useState([]);

  const [status, setStatus] = useState({
    type: state ? state.type : "",
    mensagem: state ? state.mensagem : ""
  });

  const dataTableRef = useRef(null);

  const permissao = JSON.parse(localStorage.getItem('permissao'));

  const Adicionar = [];
  const Editar = [];
  const Excluir = [];
  const View = [];

  const [TelaUsuario] = useState([
    { permission_id: 21, permissao: "viewusuario", titulo: "Visualizar" },
    { permission_id: 22, permissao: "editarusuario", titulo: "Editar" },
    { permission_id: 20, permissao: "addusuario", titulo: "Adicionar" },
    { permission_id: 23, permissao: "excluirusuario", titulo: "Excluir" }
  ]);

  const [TelaUsuarioAux] = useState([
    { permission_id: 21, permissao: "viewusuario", titulo: "Visualizar" },
    { permission_id: 22, permissao: "editarusuario", titulo: "Editar" },
    { permission_id: 20, permissao: "addusuario", titulo: "Adicionar" },
    { permission_id: 23, permissao: "excluirusuario", titulo: "Excluir" }
  ]);

  permissao.forEach(z => {
    var retornoForEach = TelaUsuarioAux.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
      TelaUsuarioAux.splice(retornoForEach, 1)
    }
  })

  TelaUsuarioAux.forEach(z => {
    var retornoForEach = TelaUsuario.findIndex(x => {
      return x.permission_id === z.permission_id
    });
    
    if (retornoForEach > -1) {
      TelaUsuario.splice(retornoForEach, 1)
    }
  })

  for (let index = 0; index < TelaUsuario.length; index++) {
    const element = TelaUsuario[index];
    
    if (element.permission_id === 21) {
      View.push(element);
    }
    if (element.permission_id === 22) {
      Editar.push(element);
    }
    if (element.permission_id === 20) {
      Adicionar.push(element);
    }
    if (element.permission_id === 23) {
      Excluir.push(element);
    }
  }

  const getUsuarios = async () => {
    const headers = {
      'headers': {
        'Authorization': "Bearer " + localStorage.getItem('token')
      }
    }

    await api.get("/usuario/", headers)
      .then((response) => {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        console.log("usuario", response)
        setData(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      }).catch((err) => {
        console.log("Lista de usuários", err);
      });
  };

  const deletarUsuario = async (idUsuario) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/usuario/" + idUsuario, headers)
        .then((response) => {
          console.log("response", response);
          getUsuarios();
          setStatus({
            type: 'success',
            mensagem: response.data.mensagem
          });
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  }

  useEffect(() => {
    getUsuarios();
  }, []);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <PageContent>
        <div className="container">
          <h1>Usuários</h1>
          {status.type === 'success' ?
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <strong>{status.mensagem}</strong>
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div> : ""}


          {Adicionar.map((item) => (
            <Link to={"/adduser"}>
              <button type="button" className="btn btn-primary">
                <i className="fa-solid fa-plus"></i>Adicionar
              </button>
            </Link>
          ))}
          <br></br><br></br>
          <div className='card mt-5'>
            <div className='card-body'>
              <div className="table-responsive">
                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nome</th>
                      <th scope="col">email</th>
                      <th scope="col">Empresa</th>
                      <th scope="col">Vinculo</th>
                      <th scope="col">nivel</th>
                      <th scope="col">area</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(usuarios => (
                      <tr key={usuarios.idUsuario}>
                        <th scope="row">{usuarios.idUsuario}</th>
                        <td>{usuarios.nome}</td>
                        <td>{usuarios.email}</td>
                        <td>{usuarios.nomeEmpresa}</td>
                        <td>{usuarios.vinculo}</td>
                        <td>{usuarios.nivel}</td>
                        <td>{usuarios.area}</td>
                        <td>
                          <div className="d-flex flex-row mb-3">
                            <div>
                              {View.map((item) => (
                                <Link to={"/viewuser/" + usuarios.idUsuario}>
                                  <button type="button" className="btn btn-warning  me-md-2">
                                    <i className="fa-solid fa-eye"></i>Visualizar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Editar.map((item) => (
                                <Link to={"/editaruser/" + usuarios.idUsuario}>
                                  <button type="button" className="btn btn-success  me-md-2">
                                    <i className="fa fa-edit"></i>Editar
                                  </button>
                                </Link>
                              ))}
                            </div>
                            <div>
                              {Excluir.map((item) => (
                                <Link to={"#"}>
                                  <button type="button" onClick={() => deletarUsuario(usuarios.idUsuario)} className="btn btn-danger">
                                    <i className="fa-solid fa-trash"></i>
                                    Deletar
                                  </button>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </div>
  );
}
