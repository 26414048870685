import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import api from '../../../config/configApi';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

export const EditarArea = (props) => {
  const [area, setArea] = useState('');
  const [descricao, setDescricao] = useState('');
  const [sigla, setSigla] = useState('');
  const [id] = useState(props.match.params.id);

  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

  const validationSchema = yup.object().shape({
    area: yup.string().trim().required('Necessário preencher o campo área!'),
    descricao: yup.string().trim().required('Necessário preencher o campo descrição!'),
    sigla: yup.string().trim().required('Necessário preencher o campo sigla!')
  });

  const editarArea = async (e) => {
    e.preventDefault();
    
    try {
        await validationSchema.validate({area, descricao, sigla}, { abortEarly: false });
    } catch (error) {
        if (error instanceof yup.ValidationError) {
            const yupErrors = {};
            error.inner.forEach((e) => {
                yupErrors[e.path] = e.message;
            });
            setRetorno(yupErrors);
            return;
        } else {
            console.error('Erro ao adicionar nível:', error);
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente novamente!',
            });
            return;
        }
    }

    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    await api.put("/area", { id, area, descricao, sigla }, headers)
        .then((response) => {
            
            setRetorno({
                type: 'redSuccess',
                mensagem: response.data.mensagem
            });
        }).catch((err) => {
            if (err) {
                setRetorno({
                    type: 'error',
                    mensagem: err.data.mensagem
                });
            } else {
                setRetorno({
                    type: 'error',
                    mensagem: 'Tente novamente mais tarde!'
                })
            }
        })
  };

  useEffect(() => {
    const getArea = async () => {
      const headers = {
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      };

      try {
        const response = await api.get("/area/" + id, headers);
        if (response.data) {
          setArea(response.data.area);
          setDescricao(response.data.descricao);
          setSigla(response.data.sigla);
        } else {
          console.log("Erro: Usuário não encontrado !");
        }

      } catch (err) {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro não encontrado");
        }
      }
    };
    getArea();
  }, [id]);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
        <h1>Editar Área</h1>

        <hr />
        {retorno.type === 'error' ?
          <div className="alert alert-error alert-dismissible fade show" role="alert">
            <strong>{retorno.mensagem}</strong>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div> : ""}

        <form onSubmit={editarArea} className='row'>
          <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
            <b>
              <label className="form-label">Área:</label>
            </b>
            <input type="text" name="area" className="form-control" value={area} onChange={e => setArea(e.target.value)} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
            <b>
              <label className="form-label">Descrição:</label>
            </b>
            <input type="text" name="descricao" className="form-control" value={descricao} onChange={e => setDescricao(e.target.value)} />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
            <b>
              <label className="form-label">Sigla:</label>
            </b>
            <input type="text" name="sigla" className="form-control" value={sigla} onChange={e => setSigla(e.target.value)} />
          </div>
          <div className='col-12 mb-4 text-center'>
            <Link to={"/area"}>
              <button type="button" className="btn btn-success me-md-2">
                Voltar
              </button>
            </Link>
            {retorno.type === 'redSuccess' ?
              <Redirect to={{
                pathname: '/area',
                state: {
                  type: "success",
                  mensagem: retorno.mensagem
                }
              }} /> : ""}
            <button type="submit" className="btn btn-success">Editar</button>
          </div>
        </form>
      </div>
    </div>
  );
};