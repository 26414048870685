import React, { useEffect, useState, useRef } from 'react';
import api from '../../../config/configApi';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import { Link, Redirect } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const AddEquipamentoInstituicao = (props) => {
  const [idinstituicao] = useState(props.match.params.idinstituicao);

  const [novoEquipamentoInstituicao, setNovoEquipamentoInstituicao] = useState({
    sala: '',
    setor: '',
    andar: '',
    equipamento: '',
    fabricante: '',
    modelo: '',
    numserie: '',
    tipo: '',
    status: '',
  });

  const [novosEquipamentos, setNovosEquipamentos] = useState([]);
  const [tipoEquipamento, setTipoEquipamento] = useState('');
  const [equipamentos, setEquipamentos] = useState([]);
  const [equipamentosEnviadosComSucesso, setEquipamentosEnviadosComSucesso] = useState(0);
  const [equipamentosComErro, setEquipamentosComErro] = useState([]);
  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

  const salvarEquipamentoInstituicao = (e) => {
    e.preventDefault();

    novoEquipamentoInstituicao.tipo = tipoEquipamento;
    setNovosEquipamentos([...novosEquipamentos, novoEquipamentoInstituicao]);
    setNovoEquipamentoInstituicao({
      sala: '',
      setor: '',
      andar: '',
      equipamento: '',
      fabricante: '',
      modelo: '',
      numserie: '',
      status: '',
    });
  };

  const salvarEquipamentosInstituicao = async (e) => {
    e.preventDefault();

    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    try {
      const equipamentosComErro = [];
    for (let i = 0; i < novosEquipamentos.length; i++) {
      const equipamento = novosEquipamentos[i];
      equipamento.instituicao_id = idinstituicao;
      try {
        await api.post('/equipamento', equipamento, headers);
      } catch (err) {
        if (err.response) {
          equipamentosComErro.push(i);
        }
      }
    }

    if (equipamentosComErro.length === 0) {
      setRetorno({
        type: 'redSuccess',
        mensagem: "Equipamentos salvos com sucesso."
      });
      //window.location.reload(true);
    } else {
      setRetorno({
        type: 'error',
        mensagem: `Erro ao enviar ${equipamentosComErro.length} equipamentos. Índices dos equipamentos com erro: ${equipamentosComErro.join(', ')}.`
      });
    }
  } catch (err) {
    if (err.response) {
      setRetorno({
        type: 'error',
        mensagem: err.response.data.mensagem
      });
    } else {
      setRetorno({
        type: 'error',
        mensagem: "Erro: Tente novamente!"
      });
    }
    }
  };

  const valueInputNovoEquipamento = (e) => {
    setNovoEquipamentoInstituicao({ ...novoEquipamentoInstituicao, [e.target.name]: e.target.value });
  }
    


  const handleTipoEquipamentoChange = (e) => {
    setTipoEquipamento(e.target.value);
    // Limpar os campos específicos ao mudar o tipo de equipamento
    setNovoEquipamentoInstituicao({
      sala: '',
      setor: '',
      andar: '',
      equipamento: '',
      fabricante: '',
      modelo: '',
      numserie: '',
    });
  };

  // const handleStatusChange = (e) => {
  //   setNovo
  // };

  const dataTableRef = useRef(null);

  const deleteEquipamentoInstituicao = async (idLicenca) => {
    if (window.confirm('Tem certeza que deseja excluir este registro?')) {

      const headers = {
        'headers': {
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }

      await api.delete("/equipamento/" + idLicenca, headers)
        .then((response) => {
          //console.log("response excluir", response);
          setRetorno({
            type: 'redSuccess',
            mensagem: response.data.mensagem
          });
          getEquipamentoInstituicao();
        }).catch((err) => {
          if (err.response) {
            console.log("response", err.response);
          } else {
            console.log("'Erro: Tente mais tarde!");
          }
        })
    }

  };

  const getEquipamentoInstituicao = async () => {
    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    };

    try {
      const response = await api.get("/equipamento/" + idinstituicao, headers);
      if (response.data) {
        if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
          $(dataTableRef.current).DataTable().destroy();
        }
        setEquipamentos(response.data);
        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable({
            retrieve: true,
            "order": [[0, "desc"]]
          });
        }
      } else {
        console.log("Erro: Equipamentos não encontrados!");
      }
    } catch (error) {
      console.log("Erro ao buscar equipamentos:", error.message);
    }
  };
  useEffect(() => {
    

    getEquipamentoInstituicao();

  }, [idinstituicao]);

  var tipo;

  return (
    <div className="sb-nav-fixed">
    <NavBar />
    <SideBar />
    <PageContent>
      <div className="container" style={{ marginLeft: '220px' }}>
        <h2>Lista de Equipamentos</h2>
        <ul className="list-group">
        {equipamentos && equipamentos.length > 0 ? (
            <div className="table-responsive">
            <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered" >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Sala</th>
                  <th scope="col">Setor</th>
                  <th scope="col">Andar</th>
                  <th scope="col">Equipamento</th>
                  <th scope="col">Fabricante</th>
                  <th scope="col">Modelo</th>
                  <th scope="col">Número de Série</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
              {equipamentos.map(equipamento => (
                  <tr key={equipamento.id}>
                    <th scope="row">{equipamento.id}</th>
                    <td>{equipamento.sala}</td>
                    <td>{equipamento.setor}</td>
                    <td>{equipamento.andar}</td>
                    <td>{equipamento.equipamento}</td>
                    <td>{equipamento.fabricante}</td>
                    <td>{equipamento.modelo}</td>
                    <td>{equipamento.numserie}</td>
                    <td>{equipamento.tipo}</td>
                    <td>
                    <div className="d-flex flex-row mb-3">
                      <div>
                        <Link to={"/viewequipamento/" + equipamento.id + "/" + idinstituicao}>
                          <button type="button" className="btn btn-warning me-md-2">
                            <i className="fa-solid fa-eye"></i>Visualizar
                          </button>
                        </Link>
                      </div>
                      <div>
                        <Link to={"/editarequipamento/" + equipamento.id + "/" + idinstituicao}>
                          <button type="button" className="btn btn-success  me-md-2">
                            <i className="fa fa-edit"></i>Editar
                          </button>
                        </Link>
                      </div>
                      <div>
                      <Link to={"#"}>
                        <button type="button" onClick={() => deleteEquipamentoInstituicao(equipamento.id)} className="btn btn-danger me-md-2">
                          <i className="fa-solid fa-trash"></i>
                          Deletar
                        </button>
                      </Link>
                      </div>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          ) : (
            <li className="list-group-item">Nenhum equipamento disponível.</li>
          )}
        </ul>
        <div className="row">
            <h2>Vincular novos equipamentos</h2>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Tipo de Equipamento:</label>
              </b>
              <select
                name="tipoEquipamento"
                className="form-select"
                onChange={handleTipoEquipamentoChange}
                value={tipoEquipamento}
              >
                <option value="">Selecione o tipo de equipamento</option>
                <option value="fixo">Fixo</option>
                <option value="movel">Móvel</option>
              </select>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Status:</label>
              </b>
              <select
                name="status"
                className="form-select"
                onChange={valueInputNovoEquipamento}
                value={novoEquipamentoInstituicao.status}
              >
                <option value="">Selecione o status do equipamento</option>
                <option value="ativo">Ativo</option>
                <option value="inativo">Inativo</option>
              </select>
            </div>
            {tipoEquipamento === 'fixo' && (
              <>
                {/* Campos específicos para equipamento fixo */}
                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                  <b>
                    <label className="form-label">Sala:</label>
                  </b>
                  <input
                    type="text"
                    name="sala"
                    className={`form-control`}
                    onChange={valueInputNovoEquipamento}
                    value={novoEquipamentoInstituicao.sala}
                  ></input>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                  <b>
                    <label className="form-label">Andar:</label>
                  </b>
                  <input
                    type="text"
                    name="andar"
                    className={`form-control`}
                    onChange={valueInputNovoEquipamento}
                    value={novoEquipamentoInstituicao.andar}
                  ></input>
                </div>
              </>
            )}
            {/* Campos comuns para ambos os tipos de equipamento */}
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Setor:</label>
              </b>
              <input
                type="text"
                name="setor"
                className={`form-control`}
                onChange={valueInputNovoEquipamento}
                value={novoEquipamentoInstituicao.setor}
              ></input>
            </div>
            
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Equipamento:</label>
              </b>
              <input
                type="text"
                name="equipamento"
                className={`form-control`}
                onChange={valueInputNovoEquipamento}
                value={novoEquipamentoInstituicao.equipamento}
              ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Fabricante:</label>
              </b>
              <input
                type="text"
                name="fabricante"
                className={`form-control`}
                onChange={valueInputNovoEquipamento}
                value={novoEquipamentoInstituicao.fabricante}
              ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Modelo:</label>
              </b>
              <input
                type="text"
                name="modelo"
                className={`form-control`}
                onChange={valueInputNovoEquipamento}
                value={novoEquipamentoInstituicao.modelo}
              ></input>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
              <b>
                <label className="form-label">Número de Série:</label>
              </b>
              <input
                type="text"
                name="numserie"
                className={`form-control`}
                onChange={valueInputNovoEquipamento}
                value={novoEquipamentoInstituicao.numserie}
              ></input>
            </div>
            <div className="col-12 mb-4 text-center">
              <button type="submit" onClick={salvarEquipamentoInstituicao} className="btn btn-success">
                Atribuir novo equipamento
              </button>
            </div>
            <div className="row mt-4">
            <div className="col-12">
              <h2>Novos Equipamentos Atribuídos</h2>
              {novosEquipamentos && novosEquipamentos.length > 0 ? (
                <ul>
                  {novosEquipamentos.map((equipamento, index) => (
                    <li key={index}>
                      {equipamento.tipo === 'fixo' ? (
                        <span>
                          Sala: {equipamento.sala}, Tipo de equipamento: {equipamento.tipo}, Status: {equipamento.status}, Andar: {equipamento.andar}, Setor: {equipamento.setor}, Equipamento: {equipamento.equipamento}, Fabricante: {equipamento.fabricante}, Modelo: {equipamento.modelo}, Número de Série: {equipamento.numserie}
                        </span>
                      ) : (
                        <span>
                          Setor: {equipamento.setor}, Tipo de equipamento: {equipamento.tipo}, Status: {equipamento.status},  Equipamento: {equipamento.equipamento}, Fabricante: {equipamento.fabricante}, Modelo: {equipamento.modelo}, Número de Série: {equipamento.numserie}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Nenhum novo equipamento atribuído ainda.</p>
              )}
            </div>
          </div>
        </div>
        <hr></hr>
        <br></br>
        <div className="col-12 mb-4 text-center">
          <Link to={"/cliente"}>
              <button type="button" className="btn btn-success me-md-2">
                Voltar
              </button>
          </Link>
          {retorno.type === 'redSuccess' ?
              <Redirect to={{
                  pathname: '/cliente',
                  state: {
                      type: "success",
                      mensagem: retorno.mensagem
                  }
              }} /> :
              ""}
              <button onClick={salvarEquipamentosInstituicao} className="btn btn-success">Salvar</button>
        </div>
      </div>
    </PageContent>
  </div>
);
};