import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';
import api from '../../../config/configApi';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

export const AddArea = () => {
  const [area, setArea] = useState({
    area: '',
    descricao: '',
    sigla: ''
  });

  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

  const valueInput = (e) => setArea({ ...area, [e.target.name]: e.target.value });

  const validationSchema = yup.object().shape({
    area: yup.string().trim().required('Necessário preencher o campo área!'),
    descricao: yup.string().trim().required('Necessário preencher o campo descrição!'),
    sigla: yup.string().trim().required('Necessário preencher o campo sigla!')
  });

  const addArea = async (e) => {
    e.preventDefault();

    try {
        await validationSchema.validate(area, { abortEarly: false });
    } catch (error) {
        if (error instanceof yup.ValidationError) {
            const yupErrors = {};
            error.inner.forEach((e) => {
                yupErrors[e.path] = e.message;
            });
            setRetorno(yupErrors);
            return;
        } else {
            console.error('Erro ao adicionar nível:', error);
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente novamente!',
            });
            return;
        }
    }

    const headers = {
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };

    await api.post('/area', area, headers)
      .then((response) => {
        setRetorno({
          type: 'redSuccess',
          mensagem: response.data.mensagem
        });
      }).catch((err) => {
        if (err.response) {
          setRetorno({
            type: 'error',
            mensagem: err.response.data.mensagem
          });
        } else {
          setRetorno({
            type: 'error',
            mensagem: "Erro: Tente novamente!"
          });
        }
      });
  }

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
        <h1>Adicionar Área</h1>

        <hr />
        {retorno.type === 'error' ?
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{retorno.mensagem}</strong>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div> : ""}
        <form onSubmit={addArea} className='row'>
          <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
            <b>
              <label className="form-label">Área:</label>
            </b>
            <input type="text" name="area" className={`form-control ${retorno?.area && 'is-invalid'}`} onChange={valueInput}></input>
            {retorno?.area && <div className="invalid-feedback">{retorno.area}</div>}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
            <b>
              <label className="form-label">Descrição:</label>
            </b>
            <input type="text" name="descricao" className={`form-control ${retorno?.descricao && 'is-invalid'}`} onChange={valueInput}></input>
            {retorno?.descricao && <div className="invalid-feedback">{retorno.descricao}</div>}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
            <b>
              <label className="form-label">Sigla:</label>
            </b>
            <input type="text" name="sigla" className={`form-control ${retorno?.sigla && 'is-invalid'}`} onChange={valueInput}></input>
            {retorno?.sigla && <div className="invalid-feedback">{retorno.sigla}</div>}
          </div>

          <div className='col-12 mb-4 text-center'>
            <Link to={"/area"}>
              <button type="button" className="btn btn-success me-md-2">
                Voltar
              </button>
            </Link>
            {retorno.type === 'redSuccess' ?
              <Redirect to={{
                pathname: '/area',
                state: {
                  type: "success",
                  mensagem: retorno.mensagem
                }
              }} /> : ""}
            <button type="submit" className="btn btn-success">Salvar</button>
          </div>
        </form>
      </div>
    </div>
  );
};