import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link } from 'react-router-dom';

export const ViewNivel = (props) => {

    const [type, setType] = useState('');
    const [status, setStatus] = useState('');

    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Nivel", id);

    const [permissionsOptions, setPermissionsOptions] = useState([]);
    const [checkedValues, setValue] = useState([]);

    const checkBoxChange = (e, index) => {

        let res = [...permissionsOptions]
        res[index].checked = e.target.checked
        setPermissionsOptions(res)

        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    useEffect(() => {


        const getNivel = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };

            await api.get("/typeuser/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response", response.data);
                        setType(response.data.type);
                        setStatus(response.data.status);
                        setPermissionsOptions(response.data.permissoes);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getNivel();
        //console.log("permissionsOptions", permissionsOptions);
        //permissionsOptions();
    }, [id]);

    console.log("checkedValues", checkedValues);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Visualizar Nivel</h1>
                    <form className='row'>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nivel:</label>
                            </b>
                            <input type="text" name="type" className="form-control" value={type} onChange={text => setType(text.target.value)} disabled ></input>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Status:</label>
                            </b>
                            <input type="text" name="status" className="form-control" value={status} onChange={text => setStatus(text.target.value)} disabled ></input>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                {permissionsOptions.map((item, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" disabled checked={item.checked} value={item.id} id={item.id} onChange={(e) => checkBoxChange(e, index)} />
                                            <label class="form-check-label" for={item.id}>{item.descricao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                        <div className="col-12 mb-4 text-center">
                            <Link to={"/niveis"}>
                                <button type="button" className="btn btn-success">
                                    Voltar
                                </button>
                            </Link>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    )
}