import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import { addYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';

import localforage from 'localforage';

export const EditarServicoOrcamento = (props) => {



    const [descricao, setDescricao] = useState('');
    const [qt, setQt] = useState('');
    const [valor, setValor] = useState('');
    const [subtotal, setSubtotal] = useState('');
    const [modelocontratacao, setModelocontratacao] = useState('');
    const [area, setArea] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [servico, setServico] = useState('');

    const [selectedValue, setSelectedValue] = useState('');
    const [treinamentoOptions, setTreinamentoOptions] = useState([]);
    const [selectedValueTreinamentos, setSelectedValueTreinamentos] = useState('');





    const [idorcamento] = useState(props.match.params.idorcamento);
    const [idservicoorcamento] = useState(props.match.params.idservicoorcamento);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });


    const setmc = (e) => {
        var modeloContratacao = e;
        setSelectedValue(modeloContratacao);
        setModelocontratacao(modeloContratacao);
    }

    const handlePeriodicidadeChange = (e) => {
        setSelectedValue(e.target.value);
        setModelocontratacao(e.target.value);
    }

    const handleServicosChange = (e) => {

        const { name, value } = e.target;

        console.log("name", name);
        console.log("value", value);

        if (name == "quantidade") {
            var quantidade = value;

            setSubtotal(quantidade * valor);
            setQt(quantidade);
        }

        if (name == "valor") {
            var numerodigitado = value;

            console.log("quantidade", qt);
            console.log("valor", valor);
            console.log("numerodigitado", numerodigitado);
            setSubtotal(qt * numerodigitado);
            setValor(numerodigitado);
        }


    };

    const editarOrcamento = async e => {
        e.preventDefault();
        console.log("e", e);



        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        setModelocontratacao(selectedValue);

        const orcamento = {
            idorcamento,
            idservicoorcamento,
            qt,
            valor,
            subtotal,
            modelocontratacao
        }

        var treinamento_id = selectedValueTreinamentos;

        await api.put("/orcamentotemservico/" + idservicoorcamento, { idorcamento, idservicoorcamento, qt, valor, subtotal, modelocontratacao, treinamento_id, periodo }, headers)
            .then((response) => {
                //console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    });
                }
            });



    }


    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        const getServicoOrcamento = async (idservicoorcamento) => {

            await api.get("/orcamentotemservico/" + idservicoorcamento, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response.data", response.data);
                        setDescricao(response.data.descricao);
                        setQt(response.data.quantidade);
                        setValor(response.data.valor);
                        setSubtotal(response.data.subtotal);
                        setmc(response.data.modelocontratacao);
                        setArea(response.data.area);
                        setPeriodo(response.data.periodo);
                        if (response.data.treinamento_id != null) {
                            getTreinamentos(response.data.idarea);
                            setSelectedValueTreinamentos(response.data.treinamento_id);
                        }
                        setServico(response.data.servico);
                    } else {
                        console.log("Erro: getServicoOrcamento não encontrado !");
                    }
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }

        const getTreinamentos = async (area_id) => {

            try {
                const response = await api.post("/treinamentosPorArea", { area_id }, headers);
                console.log("treinamentosPorArea", response.data);
                setTreinamentoOptions(response.data);
            } catch (error) {
                console.error("Erro ao obter opções de vinculo2:", error);
            }
        };
        getServicoOrcamento(idservicoorcamento);

    }, []);

    const handleChangeTreinamento = (e) => {
        setSelectedValueTreinamentos(e.target.value);
    };

    const handlePeriodChange = (e) => {
        setPeriodo(e.target.value);
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                {retorno.type === 'err' ?
                    <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}

                <h1>Editar Serviço Orçamento</h1>
                <hr />
                <form onSubmit={editarOrcamento}>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Area:</label>
                            </b>
                            <input type="text" name="area" disabled className="form-control" value={area} onChange={text => setArea(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Serviço:</label>
                            </b>
                            <input type="text" name="servico" disabled className="form-control" value={servico} onChange={text => setServico(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Descrição:</label>
                            </b>
                            <input type="text" name="descricao" disabled className="form-control" value={descricao} onChange={text => setDescricao(text.target.value)} ></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Quantidade:</label>
                            </b>
                            <input type="number"
                                name="quantidade"
                                className="form-control"
                                value={qt}
                                onChange={handleServicosChange} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b><label htmlFor="valor" className="form-label">Valor</label></b>
                            <div className="input-group mb-3">
                                <span className="input-group-text">R$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="valor"
                                    name="valor"
                                    value={valor}
                                    onChange={handleServicosChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b><label htmlFor="subtotal" className="form-label">Subtotal</label></b>
                            <div className="input-group mb-3">
                                <span className="input-group-text">R$</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="subtotal"
                                    name="subtotal"
                                    value={subtotal}
                                    readOnly
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        {selectedValueTreinamentos !== 0 && (
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label htmlFor="inputTreinamento" className="form-label">
                                        Selecione o Treinamento:
                                    </label>
                                </b>
                                <select
                                    id="inputTreinamento"
                                    className="form-select"
                                    name="treinamentoPorArea"
                                    onChange={handleChangeTreinamento}
                                    value={selectedValueTreinamentos}
                                >
                                    <option value="">Selecione</option>
                                    {treinamentoOptions.map((opcao) => (
                                        <option value={opcao.id}>{opcao.nome}</option>
                                    ))}
                                </select>
                            </div>
                        )}

                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Modelo de Contratação:</label>
                            </b>
                            <select
                                className={`form-select`}
                                name="periodicidade"
                                onChange={handlePeriodicidadeChange}
                                value={selectedValue}
                            >
                                <option value="">Selecione</option>
                                <option value="Avulso">Avulso</option>
                                <option value="Contrato">Contrato</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row">
                            <div className="row">
                                <label htmlFor="vendedor" className="form-label">
                                    <b>
                                        Selecione um Período:
                                    </b>
                                </label>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        value="Anual"
                                        checked={periodo === 'Anual'}
                                        onChange={handlePeriodChange}
                                        
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Anual
                                    </label>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        value="Semestral"
                                        checked={periodo === 'Semestral'}
                                        onChange={handlePeriodChange}
                                        
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        Semestral
                                    </label>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault3"
                                        value="Trimestral"
                                        checked={periodo === 'Trimestral'}
                                        onChange={handlePeriodChange}
                                        
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                        Trimestral
                                    </label>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault4"
                                        value="Mensal"
                                        checked={periodo === 'Mensal'}
                                        onChange={handlePeriodChange}
                                        

                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault4">
                                        Mensal
                                    </label>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-12 form-outline mb-4">
                                    <input
                                        className="form-check-input me-2"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        value="Avulso"
                                        checked={periodo === 'Avulso'}
                                        onChange={handlePeriodChange}
                                        
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Avulso
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/editarorcamento/" + idorcamento}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/editarorcamento/' + idorcamento,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}