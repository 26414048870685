import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';
import { addYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';

import axios from 'axios';

import localforage from 'localforage';

export const VincularColaborador = (props) => {

    const nome = localStorage.getItem('nome');

    const [idservicoos] = useState(props.match.params.idservicoos);
    const [idos] = useState(props.match.params.idos);
    const [idcliente] = useState(props.match.params.idcliente);
    const [idtreinamento] = useState(props.match.params.idtreinamento);
    const [telaAnterior] = useState(props.match.params.telaAnterior);
    const [quantidadeServico] = useState(props.match.params.quantidadeServico);

    const [listaColaboradores, setListaColaboradores] = useState([]);
    const [nomeCliente, setNomeCliente] = useState([]);
    const [cargos, setCargos] = useState([]);
    const [contadorChecked, setContadorChecked] = useState(0);
    const [checkedValues, setValue] = useState([]);
    const [QuantidadeAtual, setQuantidadeAtual] = useState(0);
    const [contatorMaiorServico, setContadorMaiorServico] = useState(null); // Adicionado o estado para contadorMaiorServico

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const headers = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };

    const [colaborador, setColaborador] = useState({
        nome: '',
        cpf: '',
        email: '',
        crm: '',
        senha: '',
        cargo_id: '',
        instituicao_id: idcliente
    });

    const [cargo, setCargo] = useState('');

    const AdicionarColaborador = () => {
        setColaborador({ nome: '', cpf: '', email: '', crm: '', senha: '', cargo: '' });
    };

    const fetchData = async () => {
        const getColaboradores = async () => {
            try {
                const response = await api.post("/os/listarColabores", { idcliente, idservicoos, idos }, headers);
                if (response.data) {
                    setListaColaboradores(response.data);
                    atualizarContadorChecked(response.data);
                } else {
                    console.log("Erro: instituicao não encontrado !");
                }
            } catch (err) {
                console.log("Erro não encontrado", err);
            }
        };

        const getInstituicao = async (idcliente) => {
            await api.get("/instituicao/" + idcliente, headers)
                .then((response) => {
                    if (response.data) {
                        setNomeCliente(response.data.nome);
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }
                }).catch((err) => {
                    console.log("Erro não encontrado", err);
                })
        };

        const getCargo = async () => {
            await api.get("/cargo", headers)
                .then((response) => {
                    setCargos(response.data);
                }).catch((err) => {
                    console.log("err", err);
                });
        };

        getInstituicao(idcliente);
        getColaboradores();
        getCargo();
    };

    const atualizarContadorChecked = (listaColaboradores) => {
        const totalChecked = listaColaboradores.filter(colaborador => colaborador.checked).length;
        setContadorChecked(totalChecked);
        const novoContadorMaiorServico = calcularContadorMaiorServico(quantidadeServico, totalChecked, QuantidadeAtual);
        setContadorMaiorServico(novoContadorMaiorServico); // Corrigido aqui
    };

    useEffect(() => {
        fetchData();
        // Defina algum valor para a quantidade atual; você precisa calcular ou atribuir um valor real.
        const algumValorCalculado = 0; // Por exemplo, você pode inicializar com 0 ou outro valor
        setQuantidadeAtual(algumValorCalculado);
    }, [idcliente]);

    const checkBoxChange = (e, index) => {
        let res = [...listaColaboradores];
        res[index].checked = e.target.checked;
        setListaColaboradores(res);

        const { value, checked } = e.target;
        if (checked) {
            setValue(prev => [...prev, value]);
        } else {
            setValue(prev => prev.filter(val => val !== value));
        }

        atualizarContadorChecked(res);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setColaborador({ ...colaborador, [name]: value });
    };

    const salvarColaborador = async () => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        colaborador.cargo_id = cargo;
        colaborador.instituicao_id = idcliente;

        await api.post('/funcionario', colaborador, headers)
            .then((response) => {
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
                fetchData();
                
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };

    const isFormValid = colaborador.nome && colaborador.cpf;

    const handleCargoChange = (e) => {
        setCargo(e.target.value);
    };

    const handleVincularColaborador = async (e) => {
        e.preventDefault();

        const dadosGerais = {
            idsColaborados: listaColaboradores,
            idOs: idos,
            idServico: idservicoos,
            idcliente: idcliente,
            idtreinamento: idtreinamento,
        };

        await api.post('/os/vincularColaborador', dadosGerais, headers)
            .then((response) => {
                setRetorno({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    };

    const calcularContadorMaiorServico = (quantidadeServico, contadorChecked, QuantidadeAtual) => {
        let subtracao = quantidadeServico - contadorChecked - QuantidadeAtual;

        if (subtracao === 0) {
            return null; // Pode retornar null ou uma string vazia
        }

        if (subtracao < 0) {
            subtracao = Math.abs(subtracao);
        }

        return subtracao;
    };
    
    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Vincular Colaborador</h1>

                    <hr />
                    <div>
                        <button
                            type="button"
                            className="btn btn-warning me-md-2"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={AdicionarColaborador}
                        >
                            Adicionar Colaborador
                        </button>
                    </div>
                    <br /><br />
                    <h5>Cliente:  {nomeCliente}</h5>
                    <h5>Quantidade de treinamentos contratado via orçamento {quantidadeServico}</h5>
                    <h5>Quantidade : {contadorChecked} </h5>
                    <form className='row' onSubmit={handleVincularColaborador}>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <ol className="breadcrumb mb-4">
                                <li className="breadcrumb-item active"><strong>Colaboradores</strong></li>
                            </ol>
                            <div class="row">
                                <div className='container'>
                                    {listaColaboradores.map((item, index) =>
                                        <div key={index} className="form-check col-md-6">
                                            <div>
                                                <input type="checkbox" className="form-check-input" checked={item.checked} value={item.idfuncionario} id={item.idfuncionario} onChange={(e) => checkBoxChange(e, index)} />
                                                <label className="form-check-label" for={item.idfuncionario}>{item.nomefuncionario}</label>
                                            </div>
                                        </div>)}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3 text-center">
                                <br />
                                <Link to={`/${telaAnterior}/` + idos}>
                                    <button type="button" className="btn btn-success me-md-2">
                                        Voltar
                                    </button>
                                </Link>
                                {retorno.type === 'success' ?
                                    <Redirect to={{
                                        pathname: '/' + telaAnterior + '/' + idos,
                                        state: {
                                            type: "success",
                                            mensagem: retorno.mensagem
                                        }
                                    }} /> : ""}
                                <button type="submit" className="btn btn-success me-md-2">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Adicionar Colaborador
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    {/* Usando Grid do Bootstrap para campos lado a lado */}
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="nome" className="form-label">
                                                Nome
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="nome"
                                                name="nome"
                                                value={colaborador.nome}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="cpf" className="form-label">
                                                CPF
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cpf"
                                                name="cpf"
                                                value={colaborador.cpf}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="email" className="form-label">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={colaborador.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="crm" className="form-label">
                                                CRM
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="crm"
                                                name="crm"
                                                value={colaborador.crm}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="senha" className="form-label">
                                                Senha
                                            </label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="senha"
                                                name="senha"
                                                value={colaborador.senha}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <b>
                                                <label className="form-label">Cargo:</label>
                                            </b>
                                            <select
                                                name="cargo"
                                                className="form-select"
                                                onChange={handleCargoChange}
                                                value={cargo}
                                            >
                                                <option value="">Selecione o cargo</option>
                                                {cargos.map((cargoItem) => (
                                                    <option key={cargoItem.id} value={cargoItem.id}>
                                                        {cargoItem.cargo}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal">
                                        Fechar
                                    </button>
                                    <button type="button" className="btn btn-success" onClick={salvarColaborador} disabled={!isFormValid} data-bs-dismiss="modal">Salvar</button>
                                    {/* <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={salvarColaborador}
                                        disabled={!isFormValid} // Desabilita o botão se os campos obrigatórios não estiverem preenchidos
                                    >
                                        Salvar Colaborador
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        </div>
    );
}