import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import { addYears } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';

import localforage from 'localforage';

export const EditarSondaEquipamentoInterno = (props) => {
    const [nomeSonda, setNomeSonda] = useState('');
    const [numeroSerieSonda, setNumeroSerieSonda] = useState('');
    const [patrimonioSonda, setPatrimonioSonda] = useState('');
    const [laboratorio, setLaboratorio] = useState('');
    const [numerocertificadocalibracao, setNumeroCertificadoCalibracao] = useState('');
    const [tipo_sonda, setTipo_sonda] = useState('');
    const [tipoarquivocertificado, setTipoarquivocertificado] = useState('');
    const [endImagem, setEndImagem] = useState('');
    const [foto, setFoto] = useState('');
    const [arquivocertificado, setArquivocertificado] = useState('');
    const [viewMostrar, setViewMostrar] = useState(null);
    const [datacalibracao, setDataCalibracao] = useState(null);
    const [datarenovacaocalibracao, setDataRenovacaoCalibracao] = useState(null);
    const [aplicacao, setAplicacao] = useState([]);
    const [checkedValues, setValue] = useState([]);
    const [tipoSondaOptions, setTipoSondaOptions] = useState([]);

    const [id] = useState(props.match.params.id);
    const [idEquipamentoInterno] = useState(props.match.params.idEquipamentoInterno);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const checkBoxChange = (e, index) => {

        let res = [...aplicacao];
        res[index].checked = e.target.checked;
        setAplicacao(res);

        const { value, checked } = e.target;

        console.log(`${value} is ${checked}`);

        console.log("index", index);

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    const geraDataRenovacao = (data_calibracao) => {
        var datadecalibracao = data_calibracao;

        var znDate = zonedTimeToUtc(datadecalibracao, 'America/Sao_Paulo');
        console.log("znDate", znDate);

        var adddoisanos = addYears(znDate, 2);

        var dataFormatada = format(adddoisanos, 'yyyy-MM-dd', { timeZone: 'America/Sao_Paulo' });
        console.log("dataFormatada", dataFormatada);
        setDataRenovacaoCalibracao(dataFormatada);
    }

    const handleDataCalibracaoChange = (date) => {
        setDataCalibracao(date);
        geraDataRenovacao(date);
    };

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                setViewMostrar(1);
                setArquivocertificado('pdf');
                setFoto(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                setViewMostrar(2);
                setArquivocertificado('imagem');
                setFoto(selectedFile);
            }
        }

    }


    const editarSondaEquipamentoInterno = async e => {

        e.preventDefault();
        //console.log("e", e);
        console.log("id", id, "nomeSonda", nomeSonda, "numeroSerieSonda", numeroSerieSonda, "patrimonioSonda", patrimonioSonda);
        console.log("laboratorio", laboratorio, "numerocertificadocalibracao", numerocertificadocalibracao, "tipo_sonda", tipo_sonda, "datacalibracao", datacalibracao);
        console.log("datarenovacaocalibracao", datarenovacaocalibracao, "aplicacao", aplicacao);
        console.log("tipoarquivocertificado", arquivocertificado, "foto", foto);

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const headersFoto = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        if (foto != undefined) {
            await api.put("/sonda/" + id, { nomeSonda, patrimonioSonda, numeroSerieSonda, laboratorio, numerocertificadocalibracao, tipo_sonda, datacalibracao, datarenovacaocalibracao, aplicacao, arquivocertificado, foto }, headersFoto)
                .then((response) => {
                    console.log("response", response);
                    setRetorno({
                        type: 'redSuccess',
                        mensagem: response.data.mensagem
                    });
                }).catch((err) => {
                    if (err) {
                        setRetorno({
                            type: 'error',
                            mensagem: err.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'error',
                            mensagem: 'Tente novamente mais tarde!'
                        });
                    }
                });
        } else {
            await api.put("/sonda/" + id, { nomeSonda, patrimonioSonda, numeroSerieSonda, laboratorio, numerocertificadocalibracao, tipo_sonda, datacalibracao, datarenovacaocalibracao, aplicacao, arquivocertificado }, headers)
                .then((response) => {
                    console.log("response", response);
                    setRetorno({
                        type: 'redSuccess',
                        mensagem: response.data.mensagem
                    });
                }).catch((err) => {
                    if (err) {
                        setRetorno({
                            type: 'error',
                            mensagem: err.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'error',
                            mensagem: 'Tente novamente mais tarde!'
                        });
                    }
                });
        }


    }

    const getSonda = async (id) => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.get("/sonda/" + id, headers)
            .then((response) => {
                console.log("id", id);
                console.log("response.data", response.data);
                if (response.data) {
                    setNomeSonda(response.data.nome);
                    setNumeroSerieSonda(response.data.numeroserie);
                    setPatrimonioSonda(response.data.patrimonio);
                    setLaboratorio(response.data.laboratorio);
                    setNumeroCertificadoCalibracao(response.data.numcertificadocalibracao);
                    setTipo_sonda(response.data.tipo_sonda_id);
                    setDataCalibracao(response.data.data_calibracao);
                    setDataRenovacaoCalibracao(response.data.data_renovacao_calibracao);
                    setAplicacao(response.data.aplicacao);
                    setTipoarquivocertificado(response.data.tipoarquivocertificado);
                    setEndImagem(response.data.endImagem);
                    console.log("response.data", response.data);
                } else {
                    console.log("Erro: Usuário não encontrado !");
                }
            }).catch((err) => {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro  não encontrado");
                }
            })
    }

    const VisualizarExistente = async () => {
        if (endImagem !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', endImagem);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const VisualizarPDF = async () => {
        if (foto !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', foto);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    const handleSelectChangeTipoSonda = (event) => {
        // if (typeof event === "number") {
        //     var nivel = event.toString();
        //     setverificaNivel(nivel);
        // }
        // if (typeof event === "object") {
        //     event.preventDefault();
        //     setSelectedValueNivel(event.target.value);
        //     setverificaNivel(event.target.value);
        // }

        event.preventDefault();
        setTipo_sonda(event.target.value);
    };

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        const tipoSonda = async () => {
            try {
                const response = await api.get("/tiposonda", headers);
                console.log("response classe", response);
                setTipoSondaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }
        tipoSonda();
        getSonda(id);
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                {retorno.type === 'err' ?
                    <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : ""}

                <h1>Editar Sonda</h1>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nomeSonda" className="form-control" value={nomeSonda} onChange={text => setNomeSonda(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">N° de serie:</label>
                        </b>
                        <input type="text" name="numeroserieSonda" className="form-control" value={numeroSerieSonda} onChange={text => setNumeroSerieSonda(text.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Patrimonio:</label>
                        </b>
                        <input type="text" name="patrimonioSonda" className="form-control" value={patrimonioSonda} onChange={text => setPatrimonioSonda(text.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Laboratorio:</label>
                        </b>
                        <input type="text" name="laboratorio" className="form-control" value={laboratorio} onChange={text => setLaboratorio(text.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">N° Certificado de calibração:</label>
                        </b>
                        <input type="text" name="numerocertificadocalibracao" value={numerocertificadocalibracao} onChange={text => setNumeroCertificadoCalibracao(text.target.value)} className="form-control" ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Data calibração:</label>
                        </b>
                        <input type="date" name="datacalibracao" className="form-control" value={datacalibracao} onChange={(e) => handleDataCalibracaoChange(e.target.value)}></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Data da renovação de calibração:</label>
                        </b>
                        <input type="date" name="datarenovacaocalibracao" value={datarenovacaocalibracao} disabled className="form-control"></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputTipoSonda" className="form-label">Tipo Sonda:</label>
                        </b>
                        <select
                            id="inputTipoSonda"
                            className="form-select"
                            name="tipo_sonda"
                            value={tipo_sonda}
                            onChange={handleSelectChangeTipoSonda}
                        >
                            <option value="">Selecione</option>
                            {tipoSondaOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.tipo}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione uma foto/pdf do certificado</label>
                        <input className="form-control" type="file" name="foto" id="formFile" onChange={veirificaTipoArquivo} /><br /><br />

                        {viewMostrar === null && <>
                            {tipoarquivocertificado === 'pdf' && <>
                                <button type='button' onClick={() => VisualizarExistente()} className="btn btn-success">
                                    Visualizar PDF
                                </button>
                            </>}

                            {tipoarquivocertificado === 'imagem' && <>
                                {endImagem ? <img src={endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                            </>}
                        </>}


                        {viewMostrar === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                Visualizar PDF
                            </button>
                        </>}

                        {viewMostrar === 2 && <>
                            {foto ? <img src={URL.createObjectURL(foto)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={endImagem} alt="Imagem" className="rounded-circle" width="150" height="150" />}
                        </>}

                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <h3>Aplicação vinculadas</h3>
                        <div class="row">
                            {aplicacao.map((aplica, index) =>
                                <div key={index} className="form-check col-md-4">
                                    <div>
                                        <input type="checkbox" className="form-check-input" checked={aplica.checked} value={aplica.id} id={aplica.id} onChange={(e) => checkBoxChange(e, index)} />
                                        <label className="form-check-label" for={aplica.id}>{aplica.aplicacao}</label>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>

                <div className='col-12 mb-4 text-center'>
                    <Link to={"/editarequipamentointerno/" + idEquipamentoInterno}>
                        <button type="button" className="btn btn-success me-md-2">
                            Voltar
                        </button>
                    </Link>
                    {retorno.type === 'redSuccess' ?
                        <Redirect to={{
                            pathname: '/editarequipamentointerno/' + idEquipamentoInterno,
                            state: {
                                type: "success",
                                mensagem: retorno.mensagem
                            }

                        }} /> :
                        ""}
                    <button type="submit" className="btn btn-success" onClick={editarSondaEquipamentoInterno}>Editar</button>
                </div>
            </div>
        </div>
    )
}