import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const AddNivel = () => {

    const [nivel, setNivel] = useState({
        typeUser: '',
        status: '',
        permissoes: ''
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [permissionsOptions, setPermissionsOptions] = useState([]);
    const [checkedValues, setValue] = useState([]);

    const validationSchema = yup.object().shape({
        typeUser: yup.string().trim().required('Necessário preencher o campo Nível!'),
        status: yup.string().trim().required('Necessário selecionar o campo Status!'),
        permissoes: yup.array().min(1, 'Selecione pelo menos uma permissão!'),
    });

    const checkBoxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setValue(pre => [...pre, value])
        } else {
            setValue(pre => {
                return [...pre.filter(skill => skill === value)]
            })
        }
    };

    console.log("checkedValues", checkedValues);


    const valueInput = e => setNivel({ ...nivel, [e.target.name]: e.target.value });

    const addNivel = async e => {
        e.preventDefault();

        try {
            await validationSchema.validate(nivel, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao adicionar nível:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }

        if (checkedValues.length === 0) {
            setRetorno({
                type: 'error',
                mensagem: 'Selecione pelo menos uma permissão!',
            });
            return;
        }

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        nivel.permissoes = checkedValues;
        console.log("nivel", nivel);
        console.log("checkedValues", checkedValues);

        await api.post('/typeuser', nivel, headers)
            .then((response) => {
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const permissionsOptions = async () => {
            try {
                const response = await api.get("/permission", headers);
                console.log("response permission", response.data);
                setPermissionsOptions(response.data); // Substitua por onde os dados necessários estão no objeto de resposta
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        permissionsOptions();
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Adicionar Nivel</h1>

                    <hr />
                    {retorno.type === 'error' ?
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                    <form onSubmit={addNivel} className='row'>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Nivel:</label>
                            </b>
                            <input type="text" name="typeUser" className={`form-control ${retorno?.typeUser && 'is-invalid'}`} onChange={valueInput} ></input>
                            {retorno?.typeUser && <div className="invalid-feedback">{retorno.typeUser}</div>}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Status:</label>
                            </b>
                            <select
                                className={`form-select ${retorno?.status && 'is-invalid'}`}
                                name="status"
                                onChange={valueInput}
                            >
                                <option value="">Selecione</option>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                            {retorno?.status && <div className="invalid-feedback">{retorno.status}</div>}
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                            <div class="row">
                                {permissionsOptions.map((item, index) =>
                                    <div key={index} className="form-check col-md-4">
                                        <div>
                                            <input type="checkbox" className="form-check-input" value={item.id} id={item.id} onChange={checkBoxChange} />
                                            <label className="form-check-label" for={item.id}>{item.descricao}</label>
                                        </div>
                                    </div>)}
                            </div>
                        </div>


                        <div className="col-12 mb-4 text-center">
                            <Link to={"/niveis"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/niveis',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Salvar</button>
                        </div>
                    </form>
                </div>
            </PageContent>
        </div>
    );
};