import React, { useState, useEffect } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

import localforage from 'localforage';


export const AdicionarUsuario = (props) => {
    
    const [usuario, setUsuario] = useState({
        nome: '',
        cpf: '',
        email: '',
        foto: '',
        titulacao: '',
        titulacaocnen: '',
        complementocnen: '',
        senha: '',
        vinculo: '',
        qualificacao: '',
        certificadocnen: '',
        arquivocertificado: '',
        arquivoimagem: '',
        empresa_id: '',
        area_id: '',
        typeUser_id: '',
        precisamudarsenha: false
    });
    const validationSchema = yup.object().shape({
        nome: yup.string().trim().required('Necessário preencher o campo nome!'),
        cpf: yup.string().matches(/^\d{11}$/, 'CPF inválido').required('Necessário preencher o campo CPF!'),
        email: yup.string().trim().email('Digite um e-mail válido').required('Necessário preencher o campo e-mail!'),
    });
    
    const [image, setImage] = useState('');

    const [endImg] = useState('./icone_usuario.png');

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const [empresaOptions, setEmpresaOptions] = useState([]);
    const [nivelOptions, setNivelOptions] = useState([]);
    const [areaOptions, setAreaOptions] = useState([]);
    const [verificacnen, setVerificacnen] = useState([]);
    const [verificaNivel, setverificaNivel] = useState([null]);

    const [possui, setPossui] = useState([
        { value: 'selecione', label: 'Selecione' },
        { value: 'possui', label: 'Possui' },
        { value: 'naopossui', label: 'Não Possui' }
    ]);

    const [viewMostrar, setViewMostrar] = useState(null);
    const [viewMostrarpdf, setViewMostrarpdf] = useState(null);
    const [arquivocertificado, setArquivocertificado] = useState('');
    const [arquivoimagem, setArquivoImagem] = useState('');
    const [certificadoCnen, setCertificadoCnen] = useState('');

    const valueInput = (e) => {
        const { name, value } = e.target;
        setUsuario((prevState) => ({ ...prevState, [name]: value }));
        // Limpar mensagem de erro para o campo alterado
        setRetorno((prevRetorno) => ({ ...prevRetorno, [name]: '' }));
    };

    const verificaPossuiCnen = (event) => {
        event.preventDefault();
        console.log("Possui", event.target.value);
        setVerificacnen(event.target.value);
        usuario.titulacaocnen = event.target.value;

    };

    const verificarNivel = (event) => {
        event.preventDefault();
        setverificaNivel(event.target.value);
        usuario.typeUser_id = event.target.value;
    };

    // const validationSchema = yup.object().shape({
    //     nome: yup.string().trim().required('Necessário preencher o campo nome!'),
    //     cpf: yup.string().matches(/^\d{11}$/, 'CPF inválido').required('Necessário preencher o campo CPF!'),
    //     email: yup.string().trim().email('Digite um e-mail válido').required('Necessário preencher o campo e-mail!'),
    //     titulacao: yup.string().trim().required('Necessário preencher o campo titulacao!'),
    //     titulacaocnen: yup.string().trim().required('Necessário preencher o campo titulacaocnen!'),
    //     password: yup.string().trim().required('Necessário preencher o campo senha!')
    //         .min(6, "A senha deve ter no mínimo 6 caracteres!"),
    //     vinculo: yup.string().trim().required('Necessário preencher o campo de vinculo!'),
    //     empresa_id: yup.string().trim().required('Necessário preencher o campo de empresa!'),
    //     area_id: yup.string().trim().required('Necessário preencher o campo de área!'),
    //     typeUser_id: yup.string().trim().required('Necessário preencher o campo de nível de usúario!'),
    // });

    const adicionarUsuario = async (e) => {
        e.preventDefault();

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        usuario.foto = image;
        usuario.certificadocnen = certificadoCnen;
        usuario.arquivocertificado = arquivocertificado;
        usuario.arquivoimagem = arquivoimagem;
        if (usuario.empresa_id === '1') {
            usuario.vinculo = 'proprio';
        } if (usuario.empresa_id != '1') {
            usuario.vinculo = 'terceiro';
        }
        if (usuario.typeUser_id === '25') {
            usuario.titulacao = "";
            usuario.titulacaocnen = "";
            usuario.complementocnen = "";
        }

        await api.post('/usuario', usuario, headers)
            .then((response) => {
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    const confirmarCadastro = async e => {
        e.preventDefault();

        try {
            await validationSchema.validate(usuario, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao confirmar cadastro:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        usuario.foto = image;
        usuario.certificadocnen = certificadoCnen;
        usuario.arquivocertificado = arquivocertificado;
        usuario.arquivoimagem = arquivoimagem;
        if (usuario.empresa_id == '1') {
            usuario.vinculo = 'proprio';
        }
        if (usuario.empresa_id != '1') {
            usuario.vinculo = 'terceiro';
        }
        if (usuario.typeUser_id === '25') {
            usuario.titulacao = "";
            usuario.titulacaocnen = "";
            usuario.complementocnen = "";
        }

        usuario.precisamudarsenha = true;

        console.log("usuario", usuario);

        await api.post('/confirmarcadastro', usuario, headers)
            .then((response) => {
                console.log(response.data);
                console.log(usuario);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    const veirificaTipoArquivo = (e) => {
        let selectedFile = e.target.files[0];

        console.log("selectedFile", selectedFile);

        const fileType = ['application/pdf'];
        const fileFoto = ['image/png', 'image/jpg', 'image/jpeg'];
        if (selectedFile) {
            console.log("selectedFile", selectedFile);
            if (selectedFile && fileType.includes(selectedFile.type)) {
                console.log("selectedFile pdf", selectedFile);
                setViewMostrarpdf(1);
                setArquivocertificado('pdf');
                setCertificadoCnen(selectedFile);
            }
            if (selectedFile && fileFoto.includes(selectedFile.type)) {
                console.log("selectedFile imagem", selectedFile);
                setViewMostrar(2);
                setArquivoImagem('imagem');
                setImage(selectedFile);
            }
        }

    }

    const VisualizarPDF = async () => {
        if (certificadoCnen !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', certificadoCnen);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }


    useEffect(() => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const empresaOptions =
            async () => {
                try {
                    const response = await api.get("/empresa/", headers);
                    setEmpresaOptions(response.data);
                    console.log("response empresa", response);
                } catch (error) {
                    console.error('Erro ao obter opções de empresa:', error);
                }
            };

        const nivelOptions = async () => {
            try {
                const response = await api.get("/typeuser/", headers);
                console.log("response typeUser", response);
                setNivelOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        };

        const areaOptions = async () => {
            try {
                const response = await api.get("/area", headers);
                console.log("response typeUser", response);
                setAreaOptions(response.data);
            } catch (error) {
                console.error('Erro ao obter opções de vinculo2:', error);
            }
        }

        areaOptions();
        empresaOptions();
        nivelOptions();
    }, []);

    //Adicionar o envio de uma imagem e o preview da mesma
    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Adicionar Usuário</h1>


                <hr />
                <form onSubmit={adicionarUsuario} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome*:</label>
                        </b>
                        <input type="text" autoComplete="off" name="nome" placeholder="Nome completo do usuário" className={`form-control ${retorno?.nome && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.nome && <div className="invalid-feedback d-block">{retorno.nome}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">CPF*:</label>
                        </b>
                        <input type="number" autoComplete="off" name="cpf" placeholder="Digite o CPF do usuário" className={`form-control ${retorno?.cpf && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.cpf && <div className="invalid-feedback">{retorno.cpf}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Email*:</label>
                        </b>
                        <input type="text" autoComplete="off" name="email" placeholder="Digite o E-mail do usuário" className={`form-control ${retorno?.email && 'is-invalid'}`} onChange={valueInput} ></input>
                        {retorno?.email && <div className="invalid-feedback">{retorno.email}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Senha*:</label>
                        </b>
                        <input type="password" autoComplete="off" name="senha" placeholder="Digite o Senha do usuário" className="form-control" onChange={valueInput} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputNivel" className="form-label">Nível*:</label>
                        </b>
                        <select
                            id="inputNivel"
                            className="form-select"
                            name="typeUser_id"
                            onChange={verificarNivel}
                            value={nivelOptions.type}
                            required
                        >
                            <option value="">Selecione</option>
                            {nivelOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.type}</option>
                            ))}
                        </select>
                    </div>
                    {verificaNivel !== '25' && <>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputTitulacao" className="form-label">Titulação:</label>
                            </b>
                            <select
                                id="inputTitulacao"
                                className="form-select"
                                name="titulacao"
                                onChange={valueInput}
                                value={usuario.titulacao}
                            >
                                <option value="">Selecione</option>
                                <option value="graduacao">Graduação</option>
                                <option value="mestrado">Mestrado</option>
                                <option value="doutorado">Doutorado</option>
                                <option value="especialista">Especialista</option>
                            </select>
                            {retorno?.titulacao && <div className="invalid-feedback">{retorno.titulacao}</div>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputTitulacaocnen" className="form-label">Titulação CNEN:</label>
                            </b>
                            <select
                                id="inputTitulacaocnen"
                                className="form-select"
                                defaultValue={"selecione"}
                                name="titulacao"
                                onChange={verificaPossuiCnen}
                                value={possui.value}
                            >
                                {possui.map((ps) => (
                                    <option value={ps.value}>{ps.label}</option>
                                ))}
                            </select>
                            {retorno?.titulacao && <div className="invalid-feedback">{retorno.titulacao}</div>}
                        </div>
                    </>}
                    {verificacnen === 'possui' && <>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Regristo CNEN*:</label>
                            </b>
                            <input type="text" autoComplete="off" name="complementocnen" placeholder="Digite o Regristo CNEN" className="form-control" onChange={valueInput} ></input>
                        </div>
                    </>}
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputEmpresa" className="form-label">Empresa*:</label>
                        </b>
                        <select
                            id="inputEmpresa"
                            className="form-select"
                            name="empresa_id"
                            onChange={valueInput}
                            value={empresaOptions.nome}
                            required
                        >
                            <option value="">Selecione</option>
                            {empresaOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.nome}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputArea" className="form-label">Área*:</label>
                        </b>
                        <select
                            id="inputArea"
                            className="form-select"
                            name="area_id"
                            onChange={valueInput}
                            value={areaOptions.area}
                            required
                        >
                            <option value="">Selecione</option>
                            {areaOptions.map((opcao) => (
                                <option value={opcao.id}>{opcao.area}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione uma foto</label>
                        <input className="form-control" type="file" name="foto" id="formFile" onChange={veirificaTipoArquivo} /><br /><br />

                        {image ? <img src={URL.createObjectURL(image)} alt="Imagem" className="rounded-circle" width="150" height="150" /> : <img src={endImg} alt="Imagem" className="rounded-circle" width="150" height="150" />}

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 form-outline mb-4">
                        <label htmlFor="qualificacao" className="form-label">
                            Qualificações
                        </label>
                        <textarea
                            className="form-control"
                            id="qualificacao"
                            name="qualificacao"
                            rows="3"
                            onChange={valueInput}
                            
                        ></textarea>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="certificadocnen" className="form-label">Selecione uma foto/pdf do certificado</label>
                        <input className="form-control" type="file" name="certificadocnen" id="certificadocnen" onChange={veirificaTipoArquivo} /><br /><br />

                        {viewMostrarpdf === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF()} className="btn btn-success">
                                Visualizar PDF
                            </button>
                        </>}
                        
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione um pdf do certificado CNEN</label>
                        <input className="form-control" type="file" name="sonda.foto" id="formFile" /><br /><br />

                        
                            <button type='button' className="btn btn-success">
                                Visualizar PDF
                            </button>
                        

                    </div> */}
                    <div className="col-12 mb-4 text-center">
                        <Link to={"/usuarios"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        
                            <button type="button" onClick={confirmarCadastro} className="btn btn-primary me-md-2">
                                Confirmar Cadastro
                            </button>
                        
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/usuarios',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}