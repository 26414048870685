import React, { useEffect, useState } from "react";
import api from "../../../config/configApi";

import { NavBar } from "../../../components/NavBar";
import { SideBar } from "../../../components/SideBar";

import { Link } from "react-router-dom";

export const ViewOrcamento = (props) => {
  const idEmpresa = localStorage.getItem("empresa");

  const [cliente, setCliente] = useState("");
  const [clientes, setClientes] = useState("");
  const [clienteOptions, setClienteOptions] = useState([]);
  const [vendedor, setVendedor] = useState("");
  const [dataAtual, setDataAtual] = useState(null);
  const [aoscuidadosde, setAoscuidadosde] = useState("");
  const [validade, setValidade] = useState("");
  const [detalhes, setDetalhes] = useState("");
  const [status, setStatus] = useState("");
  const [servicos, setServicos] = useState("");
  const [produtos, setProdutos] = useState("");
  const [desconto, setDesconto] = useState("");
  const [valorTotal, setValorTotal] = useState(0);
  const [formaPagamento, setFormaPagamento] = useState("");
  const [nparcelas, setNparcelas] = useState("");
  const [parcelas, setParcelas] = useState("");
  const [datapagamento, setDataPagamento] = useState("");
  const [datapagamentoparcelado, setDataPagamentoParcelado] = useState("");
  const [parcelasDb, setParcelasDb] = useState([]);

  const [observacaogeral, setObservacaogeral] = useState("");
  const [observacaointerna, setObservacaointerna] = useState("");

  const [bancoOptions, setBancoOptions] = useState([]);
  const [dadosBancariosSelecionado, setDadosBancariosSelecionado] =
    useState("");

  const formatarMoeda = (valor) => {
    return `${valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    })}`;
  };

  const [id] = useState(props.match.params.id);
  //console.log("id Visualizar Area", id);

  const handleSelectTitulacao = (event) => {
    setStatus(event.target.value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setCliente(e.target.value);
  };

  const handleData = (date) => {
    console.log("date", date);
    setDataAtual(date);
  };

  const setDadosBancarios = (event) => {
    setDadosBancariosSelecionado(event);
  };

  const setDadosBancariosParcelado = (event) => {
    setDadosBancariosSelecionado(event);
  };

  useEffect(() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };

    const getOrcamento = async () => {
      await api
        .get("/orcamento/" + id, headers)
        .then((response) => {
          if (response.data) {
            setCliente(response.data.instituicao_id);
            setVendedor(response.data.vendedor);
            setDataAtual(response.data.dataAtual);
            setAoscuidadosde(response.data.aoscuidadosde);
            setValidade(response.data.validade);
            setDetalhes(response.data.detalhes);
            setStatus(response.data.status);
            setObservacaogeral(response.data.observacaogeral);
            setObservacaointerna(response.data.observacaointerna);
            setServicos(response.data.temservico);
            setProdutos(response.data.nprodutos);
            setDesconto(response.data.desconto);
            setValorTotal(response.data.valortotalfloat);
            setFormaPagamento(response.data.formapagamento);
            setNparcelas(response.data.nparcelas);
            setParcelas(response.data.parcelas);
            setDataPagamento(response.data.datapagamento);
            setParcelasDb(response.data.parcelasDb);
            setDataPagamentoParcelado(response.data.datapagamentoparcelado);
            if (response.data.formapagamento === "avista") {
              setDadosBancarios(response.data.dadosbancarios);
            } else if (response.data.formapagamento === "parcelado") {
              setDadosBancariosParcelado(response.data.dadosbancariosparcelado);
            }
          } else {
            console.log("Erro: Usuário não encontrado !");
          }
        })
        .catch((err) => {
          if (err) {
            console.log("Erro", err);
          } else {
            console.log("Erro  não encontrado");
          }
        });
    };

    const getCliente = async () => {
      try {
        const response = await api.get("/instituicao", headers);
        if (response.data) {
          setClienteOptions(response.data);
        } else {
          console.log("Erro: instituicao não encontrado !");
        }
      } catch (err) {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro não encontrado");
        }
      }
    };

    const getBanco = async () => {
      try {
        const response = await api.get("/banco/" + idEmpresa, headers);
        if (response.data) {
          setBancoOptions(response.data);
        } else {
          console.log("Erro: instituicao não encontrado !");
        }
      } catch (err) {
        if (err) {
          console.log("Erro");
        } else {
          console.log("Erro não encontrado");
        }
      }
    };
    getOrcamento();
    getCliente();
    getBanco();
  }, [id]);

  return (
    <div className="sb-nav-fixed">
      <NavBar />
      <SideBar />
      <div className="container" style={{ marginLeft: "220px" }}>
        <br />
        <br />
        <h1>Visualizar Orçamento</h1>
        <hr />
        <form className="row">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Dados gerais</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label for="inputCliente" className="form-label">
                        <b>Cliente:</b>
                      </label>
                      <select
                        id="inputCliente"
                        className="form-select"
                        name="cliente"
                        value={cliente}
                        onChange={handleChange}
                        disabled
                      >
                        <option value="">Selecione</option>
                        {clienteOptions.map((cliente) => (
                          <option key={cliente.id} value={cliente.id}>
                            {cliente.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label htmlFor="vendedor" className="form-label">
                        Vendedor/Responsável
                      </label>
                      <input
                        type="text"
                        name="vendedor"
                        className="form-control"
                        value={vendedor}
                        onChange={(text) => setVendedor(text.target.value)}
                        disabled
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 form-outline mb-4">
                      <label htmlFor="data" className="form-label">
                        Data
                      </label>
                      <input
                        type="date"
                        name="dataAtual"
                        className="form-control"
                        value={dataAtual}
                        onChange={(e) => handleData(e.target.value)}
                        disabled
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <label htmlFor="aosCuidados" className="form-label">
                        Aos Cuidados de
                      </label>
                      <input
                        type="text"
                        name="aoscuidadosde"
                        className="form-control"
                        value={aoscuidadosde}
                        onChange={(text) => setAoscuidadosde(text.target.value)}
                        disabled
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <label htmlFor="validade" className="form-label">
                        Validade(dias)
                      </label>
                      <input
                        type="number"
                        name="validade"
                        className="form-control"
                        value={validade}
                        onChange={(text) => setValidade(text.target.value)}
                        disabled
                      ></input>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 form-outline mb-4">
                      <b>
                        <label for="status" className="form-label">
                          Status:
                        </label>
                      </b>
                      <select
                        id="status"
                        className="form-select"
                        name="status"
                        onChange={handleSelectTitulacao}
                        value={status}
                        disabled
                      >
                        <option value="">Selecione</option>
                        <option value="em analise">em analise</option>
                        <option value="aprovado">Aprovado</option>
                        <option value="rejeitado">Rejeitado</option>
                      </select>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                      <label htmlFor="introducao" className="form-label">
                        Detalhes
                      </label>
                      <textarea
                        type="text"
                        name="detalhes"
                        className="form-control"
                        value={detalhes}
                        onChange={(text) => setDetalhes(text.target.value)}
                        disabled
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Serviços</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    {servicos.length > 0 && (
                      <>
                        {servicos?.map((item) => (
                          <div className="col">
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title">
                                  Area: {item.area}
                                </h5>
                                <h6 className="card-subtitle mb-2 text-muted">
                                  {" "}
                                  Serviço: {item.servico}
                                </h6>
                                <p class="card-text">
                                  Descrição: {item.descricao}
                                </p>
                                <p class="card-text">
                                  quantidade: {item.quantidade}
                                </p>
                                <p class="card-text">valor: {item.valor}</p>
                                <p class="card-text">
                                  subtotal: {item.subtotal}
                                </p>
                                <p class="card-text">
                                  Modelo de Contratação:{" "}
                                  {item.modelocontratacao}
                                </p>
                                <p class="card-text">
                                  Período:{" "}
                                  {item.periodo}
                                </p>
                                {item.nomeTreinamento &&
                                  < p class="card-text">
                                    Treinamento:{" "}
                                    {item.nomeTreinamento}
                                  </p>
                                }

                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Total</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="produtos" className="form-label">
                          Produtos
                        </label>
                        <input
                          type="number"
                          name="produtos"
                          className="form-control"
                          value={produtos}
                          onChange={(text) => setProdutos(text.target.value)}
                          disabled
                        ></input>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label
                          htmlFor="descontoProdutos"
                          className="form-label"
                        >
                          Desconto (%)
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="desconto"
                          name="desconto"
                          value={desconto}
                          onChange={(text) => setDesconto(text.target.value)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="total" className="form-label">
                          Total
                        </label>
                        <input
                          type="text"
                          name="total"
                          className="form-control"
                          value={formatarMoeda(valorTotal)}
                          onChange={(event) =>
                            setValorTotal(event.target.value)
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Card de Pagamento */}
              <div className="card">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Pagamento</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="pagamentoAVista"
                            name="formaPagamento"
                            value="avista"
                            checked={formaPagamento === "avista"}
                            disabled
                          />
                          <label
                            className="form-check-label"
                            htmlFor="pagamentoAVista"
                          >
                            À Vista
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            id="pagamentoParcelado"
                            name="formaPagamento"
                            value="parcelado"
                            checked={formaPagamento === "parcelado"}
                            disabled
                          />
                          <label
                            className="form-check-label"
                            htmlFor="pagamentoParcelado"
                          >
                            Parcelado
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "avista" && (
                            <>
                              <label>
                                Valor Total a ser Pago a vista é R${valorTotal}
                              </label>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "avista" &&
                            produtos.total !== 0 && (
                              <>
                                <label>Data de Pagamento</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="datapagamento"
                                  name="datapagamento"
                                  value={datapagamento}
                                  disabled
                                />
                              </>
                            )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "avista" &&
                            produtos.total !== 0 && (
                              <>
                                {bancoOptions && bancoOptions.length > 0 && (
                                  <div>
                                    <label
                                      htmlFor="dadosbancarios"
                                      className="form-label"
                                    >
                                      Dados Bancarios
                                    </label>
                                    <select
                                      className="form-select"
                                      id="dadosbancarios"
                                      name="dadosbancarios"
                                      value={dadosBancariosSelecionado}
                                      disabled
                                    >
                                      <option value="">Selecione</option>
                                      {bancoOptions.map((banco) => (
                                        <option key={banco.id} value={banco.id}>
                                          {banco.banco}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-6">
                        <div className="form-check form-check-inline">
                          {formaPagamento === "parcelado" && (
                            <>
                              <div className="mb-6">
                                <b>
                                  <label
                                    htmlFor="nparcelas"
                                    className="form-label"
                                  >
                                    Digite o numero de parcelas
                                  </label>
                                </b>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="nparcelas"
                                  name="nparcelas"
                                  value={nparcelas}
                                  disabled
                                />
                              </div>
                              <div className="md-6">
                                <b>
                                  <label
                                    htmlFor="totalProdutos"
                                    className="form-label"
                                  >
                                    Total a pagar em cada parcela é:{" "}
                                  </label>
                                </b>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="parcelas"
                                  name="parcelas"
                                  value={`R$${parcelas}`}
                                  disabled
                                />
                              </div>
                              {bancoOptions && bancoOptions.length > 0 && (
                                <>
                                  <label
                                    htmlFor="dadosbancariosparcelado"
                                    className="form-label"
                                  >
                                    Dados Bancários
                                  </label>
                                  <select
                                    className="form-select"
                                    id="dadosbancariosparcelado"
                                    name="dadosbancariosparcelado"
                                    value={dadosBancariosSelecionado}
                                    required
                                    disabled
                                  >
                                    <option value="">Selecione</option>
                                    {bancoOptions.map((banco) => (
                                      <option key={banco.id} value={banco.id}>
                                        {banco.banco}
                                      </option>
                                    ))}
                                  </select>
                                </>
                              )}
                              <label>Data de Pagamento</label>
                              <input
                                type="date"
                                className="form-control"
                                id="datapagamentoparcelado"
                                name="datapagamentoparcelado"
                                value={datapagamentoparcelado}
                                required
                                disabled
                              />
                              <ul>
                                {parcelasDb.map((data, index) => (
                                  <li key={index}>
                                    {data.datapagamento +
                                      " - R$" +
                                      data.valorParcela}
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Card de Observações Internas */}
              <div className="card mb-3">
                <div className="card-header bg-secondary text-white">
                  <h5 className="card-title">Observações Internas</h5>
                </div>
                <div className="card-body">
                  <div className="mb-3">
                    <label htmlFor="observacoesInternas" className="form-label">
                      Observações Internas
                    </label>
                    <textarea
                      type="text"
                      name="observacaointerna"
                      className="form-control"
                      value={observacaointerna}
                      onChange={(text) =>
                        setObservacaointerna(text.target.value)
                      }
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3 text-center">
              <br />
              <Link to={"/orcamento"}>
                <button type="button" className="btn btn-success">
                  Voltar
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div >
    </div >
  );
};
