import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import localforage from 'localforage';

export const ViewTreinamentos = (props) => {

    const [area_id, setSelectedValueArea] = useState(null);
    const [data, setData] = useState('');
    const [nome, setNome] = useState('');
    const [video, setVideo] = useState('');
    const [textoEmail, setTextoEmail] = useState('');
    //Para a ata
    const [imageAta, setImageAta] = useState('');
    const [viewMostrarAta, setViewMostrarAta] = useState(null);
    //Para a avaliação
    const [imageAvaliacao, setImageAvaliacao] = useState('');
    const [viewMostrarAvaliacao, setViewMostrarAvaliacao] = useState(null);
    //Para o conteudo
    const [imageConteudo, setImageConteudo] = useState('');
    const [viewMostrarConteudo, setViewMostrarConteudo] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);


    const [areaOptions, setAreaOptions] = useState([]);
    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Area", id);

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    useEffect(() => {
        const getArea = async () => {
            await api.get("/area/", headers)
                .then((response) => {
                    //console.log("area", response)
                    setAreaOptions(response.data);
                }).catch((err) => {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                });
        };

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        const getTreinamento = async () => {


            await api.get("/treinamentos/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("get useEffect", response.data);

                        setSelectedValueArea(response.data.area_id);
                        setNome(response.data.nome);
                        setData(response.data.data);
                        setVideo(response.data.video);
                        setTextoEmail(response.data.textoEmail);
                        if (response.data.ata != null) {
                            setImageAta(response.data.imagemAta);
                            setViewMostrarAta(1);
                        }
                        if (response.data.avaliacao != null) {
                            setImageAvaliacao(response.data.imagemAvaliacao);
                            setViewMostrarAvaliacao(1);
                        }
                        if (response.data.conteudoProgramatico != null) {
                            setImageConteudo(response.data.imagemConteudoProgramatico);
                            setViewMostrarConteudo(1);
                        }

                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado !"
                        });
                    }
                    //console.log(response.data);

                }).catch((err) => {
                    if (err.response) {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setRetorno({
                            type: 'redWarning',
                            mensagem: "Erro: tente mais tarde !"
                        });
                    }
                })
        };
        getArea();
        getTreinamento();
    }, [id]);

    const handleSelectChangeArea = (event) => {
        event.preventDefault();
        setSelectedValueArea(event.target.value);
    };

    const VisualizarPDF = async (imagem) => {
        console.log("imagem", imagem);
        if (imagem !== null) {
            try {
                // Armazene os dados no localforage
                await localforage.setItem('pdfData', imagem);

                // Abra a nova aba
                const novaAba = window.open('/viewpdf/', '_blank');
                novaAba.focus();
            } catch (error) {
                console.error('Erro ao armazenar os dados no localforage:', error);
            }
        }
    }

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Visualizar Treinamentos</h1>
                <hr />
                <form className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <label for="inputArea" className="form-label"><b>Area:</b></label>
                        <select id="inputArea" className="form-select" name="area_id" value={area_id} onChange={handleSelectChangeArea} disabled>
                            {areaOptions.map((option) => (
                                <option key={option.id} value={option.id}>{option.area}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome:</label>
                        </b>
                        <input type="text" name="nome" className="form-control" value={nome} onChange={text => setNome(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Data:</label>
                        </b>
                        <input type="date" name="data" className="form-control" value={data} onChange={text => setData(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">link Vídeo:</label>
                        </b>
                        <input type="text" name="video" className="form-control" value={video} onChange={text => setVideo(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione a ata</label>
                        <input className="form-control" type="file" name="ata" id="formFile" /><br /><br />

                        {viewMostrarAta === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageAta)} className='btn btn-success' >Visualizar PDF</button>
                        </>}


                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione a Avaliação</label>
                        <input className="form-control" type="file" name="ata" id="formFile" /><br /><br />

                        {viewMostrarAvaliacao === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageAvaliacao)} className='btn btn-success' >Visualizar PDF</button>
                        </>}


                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">

                        <label for="formFile" className="form-label">Selecione o Conteúdo </label>
                        <input className="form-control" type="file" name="ata" id="formFile" /><br /><br />

                        {viewMostrarConteudo === 1 && <>
                            <button type='button' onClick={() => VisualizarPDF(imageConteudo)} className='btn btn-success' >Visualizar PDF</button>
                        </>}

                    </div>
                    {/* <div className="col-lg-12 col-md-12 col-sm-12 form-outline mb-4">
                        <b>
                            <label htmlFor="textoEmail" className="form-label">
                                Texto do Email:
                            </label>
                        </b>
                        <CKEditor
                            editor={ClassicEditor}
                            data={textoEmail}  // Inicializa o CKEditor com o valor de textoEmail
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTextoEmail(data);  // Atualiza textoEmail com o conteúdo do CKEditor
                            }}
                            config={{
                                readOnly: isDisabled, // Configura o CKEditor como somente leitura quando isDisabled é true
                            }}
                        />
                    </div>
                    <div>
                        <h3>Conteúdo do Email:</h3>
                        <div dangerouslySetInnerHTML={{ __html: textoEmail }} />
                    </div> */}

                    <div className="col-12 mb-4 text-center">
                        <Link to={"/treinamentos"}>
                            <button type="button" className="btn btn-success">
                                Voltar
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}