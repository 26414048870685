import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const EditarEmpresa = (props) => {

    const [nome, setNome] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');

    const [id] = useState(props.match.params.id);
    //console.log("id Visualizar Empresa", id);

    const validationSchema = yup.object().shape({
        nome: yup.string().trim().required('Necessário preencher o campo Nome da Empresa!'),
        cnpj: yup.string().trim().required('Necessário preencher o campo CNPJ!'),
        email: yup.string().trim().email('Formato de e-mail inválido').required('Necessário preencher o campo E-mail!'),
        telefone: yup.string().trim().required('Necessário preencher o campo Telefone!'),
        razaoSocial: yup.string().trim().required('Necessário preencher o campo Razão Social!')
    });

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const editarEmpresa = async e => {
        e.preventDefault();
        console.log("e", e);
        console.log("id, nome, cnpj, email, telefone, razaoSocial", id, nome, cnpj, email, telefone, razaoSocial);

        try {
            await validationSchema.validate({nome, cnpj, email, telefone, razaoSocial}, { abortEarly: false });
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const yupErrors = {};
                error.inner.forEach((e) => {
                    yupErrors[e.path] = e.message;
                });
                setRetorno(yupErrors);
                return;
            } else {
                console.error('Erro ao adicionar empresa:', error);
                setRetorno({
                    type: 'error',
                    mensagem: 'Erro: Tente novamente!',
                });
                return;
            }
        }
        
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.put("/empresa", { id, nome, cnpj, email, telefone, razaoSocial }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })


    }

    useEffect(() => {
        const getEmpresa = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
    
            await api.get("/empresa/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        setNome(response.data.nome);
                        setCnpj(response.data.cnpj);
                        setEmail(response.data.email);
                        setTelefone(response.data.telefone);
                        setRazaoSocial(response.data.razaoSocial);
                        
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }
    
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getEmpresa();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
            <div className='container'>
            <h1>Editar Empresa</h1>

                <hr />
                {retorno.type === 'err' ? 
                        <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                        
                <form onSubmit={editarEmpresa} className='row'>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Nome da Empresa:</label>
                        </b>
                        <input type="text" name="nome" className={`form-control ${retorno?.nome && 'is-invalid'}`} value={nome} onChange={text => setNome(text.target.value)} ></input>
                        {retorno?.nome && <div className="invalid-feedback">{retorno.nome}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">CNPJ:</label>
                        </b>
                        <input type="text" name="cnpj" className={`form-control ${retorno?.cnpj && 'is-invalid'}`} value={cnpj} onChange={text => setCnpj(text.target.value)} ></input>
                        {retorno?.cnpj && <div className="invalid-feedback">{retorno.cnpj}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Email:</label>
                        </b>
                        <input type="text" name="email" className={`form-control ${retorno?.email && 'is-invalid'}`} value={email} onChange={text => setEmail(text.target.value)} ></input>
                        {retorno?.email && <div className="invalid-feedback">{retorno.email}</div>}
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Email:</label>
                        </b>
                        <input type="text" name="email" className={`form-control ${retorno?.nome && 'is-invalid'}`} value={email} onChange={text => setEmail(text.target.value)} ></input>
                        {retorno?.nome && <div className="invalid-feedback">{retorno.nome}</div>}
                    </div> */}
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Telefone:</label>
                        </b>
                        <input type="text" name="telefone" className={`form-control ${retorno?.telefone && 'is-invalid'}`} value={telefone} onChange={text => setTelefone(text.target.value)} ></input>
                        {retorno?.telefone && <div className="invalid-feedback">{retorno.telefone}</div>}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Razão Social:</label>
                        </b>
                        <input type="text" name="razaoSocial" className={`form-control ${retorno?.razaoSocial && 'is-invalid'}`} value={razaoSocial} onChange={text => setRazaoSocial(text.target.value)} ></input>
                        {retorno?.razaoSocial && <div className="invalid-feedback">{retorno.razaoSocial}</div>}
                    </div>

                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/empresa"}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/empresa',
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Editar</button>
                    </div>

                </form>
            </div>
            </PageContent>
        </div>
    )
}