import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

import * as yup from 'yup';

export const EditarCliente = (props) => {

    const [nome, setNome] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [cpf, setCpf] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidadeestado, setCidadeEstado] = useState('');
    const [cep, setCep] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tipo, setTipo] = useState('');

    const [type, setType] = useState('');
    const [status, setStatus] = useState('');

    const [email, setEmail] = useState('');

    const [tipoCliente, setTipoCliente] = useState('');


    const [id] = useState(props.match.params.idinstituicao);
    //console.log("id", id);

    const handleTipoInstituicaoChange = e => {
        setTipo(e.target.value);
    };

    const [retorno, setRetorno] = useState({
        type: '',
        mensagem: ''
    });

    const editarCliente = async e => {
        e.preventDefault();
        console.log("e", e);
        console.log("id, nome, endereco, numero, cnpj, bairro, cidadeestado, cep, telefone, tipo", id, nome, endereco, numero, cnpj, bairro, cidadeestado, cep, telefone, tipo);

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        console.log("olhe aqui", id, email);

        await api.put("/instituicao", { id, nome, endereco, numero, cnpj, bairro, cidadeestado, cep, telefone, tipo, status, email, tipoCliente, cpf, estado }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })


    }


    const getInstituicao = async (id) => {
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.get("/instituicao/" + id, headers)
            .then((response) => {
                console.log("response.data", response.data);
                if (response.data) {
                    setNome(response.data.nome);
                    setEndereco(response.data.endereco);
                    setNumero(response.data.numero);
                    setCnpj(response.data.cnpj);
                    setCpf(response.data.cpf);
                    setBairro(response.data.bairro);
                    setCep(response.data.cep);
                    setTelefone(response.data.telefone);
                    setTipo(response.data.tipo);
                    setCidadeEstado(response.data.cidadeestado);
                    setStatus(response.data.status);
                    setEmail(response.data.email);
                    setTipoCliente(response.data.tipoCliente);
                    setEstado(response.data.estado);
                } else {
                    console.log("Erro: Usuário não encontrado !");
                }

            }).catch((err) => {
                if (err) {
                    console.log("Erro");
                } else {
                    console.log("Erro  não encontrado");
                }
            })
    }

    useEffect(() => {
        getInstituicao(id);
    }, [id]);

    const handleSelectChange = (event) => {
        setStatus(event.target.value);
    };

    const estados = [
        "Acre", "Alagoas", "Amapá", "Amazonas", "Bahia", "Ceará", "Distrito Federal", "Espírito Santo",
        "Goiás", "Maranhão", "Mato Grosso", "Mato Grosso do Sul", "Minas Gerais", "Pará", "Paraíba",
        "Paraná", "Pernambuco", "Piauí", "Rio de Janeiro", "Rio Grande do Norte", "Rio Grande do Sul",
        "Rondônia", "Roraima", "Santa Catarina", "São Paulo", "Sergipe", "Tocantins"
    ];

    const [estado, setEstado] = useState("");

    const handleEstadoChange = (e) => {
        setEstado(e.target.value);
    };

    const handleTipoClienteChange = e => {
        setTipoCliente(e.target.value);
    };

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container'>
                    <h1>Editar Instituição</h1>

                    <hr />
                    {retorno.type === 'err' ?
                        <div className="alert alert-error alert-dismissible fade show" role="alert">
                            <strong>{retorno.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}

                    <form onSubmit={editarCliente} className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Tipo Cliente:</label>
                            </b>
                            <select
                                name="tipoCliente"
                                className="form-select"
                                onChange={handleTipoClienteChange}
                                value={tipoCliente}
                            >
                                <option value="">Selecione o tipo</option>
                                <option value="PJ">Pessoa Juridica</option>
                                <option value="PL">Profissional Liberal</option>
                            </select>
                        </div>
                        {tipoCliente === "PL" && (<>
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label className="form-label">CPF:</label>
                                </b>
                                <input type="text" name="cpf" className={`form-control`} value={cpf} onChange={text => setCpf(text.target.value)} ></input>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                <b>
                                    <label className="form-label">Profissional Liberal:</label>
                                </b>
                                <input type="text" name="nome" className={`form-control`} value={nome} onChange={text => setNome(text.target.value)} ></input>
                            </div>
                        </>)}
                        {tipoCliente === "PJ" && (
                            <>
                                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                    <b>
                                        <label className="form-label">CNPJ:</label>
                                    </b>
                                    <input type="text" name="cnpj" className={`form-control`} value={cnpj} onChange={text => setCnpj(text.target.value)} ></input>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                                    <b>
                                        <label className="form-label">Razão Social:</label>
                                    </b>
                                    <input type="text" name="nome" className={`form-control`} value={nome} onChange={text => setNome(text.target.value)} ></input>
                                </div>
                            </>
                        )}
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Estado:</label>
                            </b>
                            <select name="estado" className="form-select" value={estado} onChange={handleEstadoChange}>
                                <option value="">Selecione um estado</option>
                                {estados.map((estado) => (
                                    <option key={estado} value={estado}>{estado}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Cidade/Estado:</label>
                            </b>
                            <input type="text" name="cidadeestado" className={`form-control`} value={cidadeestado} onChange={text => setCidadeEstado(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Endereço:</label>
                            </b>
                            <input type="text" name="endereco" className={`form-control`} value={endereco} onChange={text => setEndereco(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Número:</label>
                            </b>
                            <input type="text" name="numero" className={`form-control`} value={numero} onChange={text => setNumero(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Bairro:</label>
                            </b>
                            <input type="text" name="bairro" className={`form-control`} value={bairro} onChange={text => setBairro(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Cep:</label>
                            </b>
                            <input type="text" name="cep" className={`form-control`} value={cep} onChange={text => setCep(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Telefone:</label>
                            </b>
                            <input type="text" name="telefone" className={`form-control`} value={telefone} onChange={text => setTelefone(text.target.value)} ></input>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Tipo de Instituição:</label>
                            </b>
                            <select
                                name="tipo_instituicao"
                                className="form-select"
                                onChange={handleTipoInstituicaoChange}
                                value={tipo}
                            >
                                <option value="">Selecione o tipo</option>
                                <option value="1">Matriz</option>
                                <option value="2">Filial</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label for="inputStatus" className="form-label">Status:</label>
                            </b>
                            <select id="inputStatus" className="form-select" name="status" value={status} onChange={handleSelectChange}>
                                <option value="ativo">Ativo</option>
                                <option value="Inativo">Inativo</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                            <b>
                                <label className="form-label">Email:</label>
                            </b>
                            <input type="text" name="email" className="form-control" value={email} onChange={text => setEmail(text.target.value)} ></input>
                        </div>


                        <div className='col-12 mb-4 text-center'>

                            <Link to={"/cliente"}>
                                <button type="button" className="btn btn-success me-md-2">
                                    Voltar
                                </button>
                            </Link>
                            {retorno.type === 'redSuccess' ?
                                <Redirect to={{
                                    pathname: '/cliente',
                                    state: {
                                        type: "success",
                                        mensagem: retorno.mensagem
                                    }

                                }} /> :
                                ""}
                            <button type="submit" className="btn btn-success">Editar</button>
                        </div>

                    </form>
                </div>
            </PageContent>
        </div>
    )
}