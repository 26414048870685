import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const Treinamentos = () => {
    const { state } = useLocation();

    const [data, setData] = useState([]);
    const [status, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const dataTableRef = useRef(null);

    const getTreinamentos = async () => {
        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        };

        try {
            if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable().destroy();
            }
            const response = await api.get("/treinamentos/", headers);
            //console.log("area", response);
            setData(response.data);
            if (dataTableRef.current) {
                $(dataTableRef.current).DataTable({
                    retrieve: true,
                    "order": [[0, "desc"]]
                });
            }
        } catch (err) {
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    };

    const deletarTreinamento = async (idNivel) => {
        if (window.confirm('Tem certeza que deseja excluir este registro?')) {
            const headers = {
                'headers': {
                    'Authorization': "Bearer " + localStorage.getItem('token')
                }
            };

            try {
                const response = await api.delete("/treinamentos/" + idNivel, headers);
                //console.log("response excluir", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
                getTreinamentos();
            } catch (err) {
                if (err.response) {
                    console.log("response", err.response);
                } else {
                    console.log("'Erro: Tente mais tarde!");
                }
            }
        }
    };

    useEffect(() => {
        getTreinamentos();
    }, []);

    

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container px-4'>
                    <h1>Treinamentos</h1>
                    {status.type === 'success' ?
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>{status.mensagem}</strong>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}

                    <Link to={"/addtreinamentos"}>
                        <button type="button" className="btn btn-primary me-2">
                            <i className="fa-solid fa-plus"></i>Adicionar
                        </button>
                    </Link>
                    <Link to={"/emailtreinamento"}>
                        <button type="button" className="btn btn-success">
                            Email Treinamento
                        </button>
                    </Link>


                    <br></br><br></br>
                    <div className='card mt-5'>
                        <div className='card-body'>
                            <div className="table-responsive">
                                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">treinamentos</th>
                                            <th scope="col">categoria</th>
                                            <th scope="col">data Criação</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(treinamento => (
                                            <tr key={treinamento.id}>
                                                <th scope="row">{treinamento.id}</th>
                                                <td>{treinamento.nome}</td>
                                                <td>{treinamento.categoria}</td>
                                                <td>{treinamento.data}</td>
                                                <td>
                                                    <div className="d-flex flex-row mb-3">
                                                        <div>
                                                            <Link to={"/viewtreinamentos/" + treinamento.id}>
                                                                <button type="button" className="btn btn-warning me-md-2">
                                                                    <i className="fa-solid fa-eye"></i>Visualizar
                                                                </button>
                                                            </Link>

                                                        </div>
                                                        <div>
                                                            <Link to={"/editartreinamentos/" + treinamento.id}>
                                                                <button type="button" className="btn btn-success me-md-2">
                                                                    <i className="fa fa-edit"></i>Editar
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div>

                                                            <Link to={"#"} onClick={() => deletarTreinamento(treinamento.id)} >
                                                                <button type="button" className="btn btn-danger">
                                                                    <i className="fa-solid fa-trash"></i>
                                                                    Deletar
                                                                </button>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        </div>
    );
};
