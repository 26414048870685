import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';

import { Link, Redirect } from 'react-router-dom';

export const EditarEquipamentoInstituicao = (props) => {
  const [id] = useState(props.match.params.id);
  const [idinstituicao] = useState(props.match.params.idinstituicao);

  //console.log("id Visualizar Empresa", id);
  const [sala, setSala] = useState('');
  const [setor, setSetor] = useState('');
  const [andar, setAndar] = useState('');
  const [equipamento, setEquipamento] = useState('');
  const [fabricante, setFabricante] = useState('');
  const [modelo, setModelo] = useState('');
  const [numserie, setNumserie] = useState('');
  const [tipo, setTipo] = useState('');
  const [status, setStatus] = useState('');
  
  const [retorno, setRetorno] = useState({
    type: '',
    mensagem: ''
  });

  const handleTipoChange = (e) => {
    if (e.target.value == "2") {
      setSala('');
      setAndar('');
    }
    setTipo(e.target.value);
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  }

    const editarEquipamentoInstituicao = async e => {
        e.preventDefault();        
        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        
        var instituicao_id = idinstituicao;
        await api.put("/equipamento", { id, sala, setor, andar, equipamento, fabricante, modelo, numserie, tipo, instituicao_id, status }, headers)
            .then((response) => {
                console.log("response", response);
                setRetorno({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err) {
                    setRetorno({
                        type: 'error',
                        mensagem: err.data.mensagem
                    });
                } else {
                    setRetorno({
                        type: 'error',
                        mensagem: 'Tente novamente mais tarde!'
                    })
                }
            })
    }
    useEffect(() => {
        const getEquipamentoInstituicao = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
    
            await api.get("/equipamento/selecionar/" + id, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("status", response.data);
                        setSala(response.data.sala);
                        setSetor(response.data.setor);
                        setAndar(response.data.andar);
                        setEquipamento(response.data.equipamento);
                        setFabricante(response.data.fabricante);
                        setModelo(response.data.modelo);
                        setNumserie(response.data.numserie);
                        setTipo(response.data.tipo);
                        setStatus(response.data.status);
                        
                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }
    
                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getEquipamentoInstituicao();
    }, [id]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
            <div className='container' style={{ marginLeft: '220px' }}>
            <h1>Editar Equipamento</h1>
                <hr />
                {retorno.type === 'err' ? 
                        <div className="alert alert-error alert-dismissible fade show" role="alert">
                        <strong>{retorno.mensagem}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div> : ""}
                        
                <form onSubmit={editarEquipamentoInstituicao} className='row'>
                {tipo === 'fixo' && (
                  <>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Sala:</label>
                        </b>
                        <input type="text" name="sala" className={`form-control`} value={sala} onChange={text => setSala(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Andar:</label>
                        </b>
                        <input type="text" name="andar" className={`form-control`} value={andar} onChange={text => setAndar(text.target.value)} ></input>
                    </div>
                  </>
                  )}
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Setor:</label>
                        </b>
                        <input type="text" name="setor" className={`form-control`} value={setor} onChange={text => setSetor(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Equipamento:</label>
                        </b>
                        <input type="text" name="equipamento" className={`form-control`} value={equipamento} onChange={text => setEquipamento(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Fabricante:</label>
                        </b>
                        <input type="text" name="fabricante" className={`form-control`} value={fabricante} onChange={text => setFabricante(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Modelo:</label>
                        </b>
                        <input type="text" name="modelo" className={`form-control`} value={modelo} onChange={text => setModelo(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Número de Série:</label>
                        </b>
                        <input type="text" name="numserie" className={`form-control`} value={numserie} onChange={text => setNumserie(text.target.value)} ></input>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                          <label className="form-label">Tipo de Equipamento:</label>
                        </b>
                        <select name='tipo' value={tipo} onChange={handleTipoChange} className='form-select'>
                          <option value="">Selecione</option>
                          <option value="fixo">Fixo</option>
                          <option value="movel">Móvel</option>
                        </select>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 form-outline mb-4">
                        <b>
                          <label className="form-label">Status do Equipamento:</label>
                        </b>
                        <select name='status' value={status} onChange={handleStatusChange} className='form-select'>
                          <option value="">Selecione</option>
                          <option value="ativo">Ativo</option>
                          <option value="inativo">Inativo</option>
                        </select>
                    </div>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={"/addequipamentoinstituicao/" + idinstituicao}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>
                        {retorno.type === 'redSuccess' ?
                            <Redirect to={{
                                pathname: '/addequipamentoinstituicao/' + idinstituicao,
                                state: {
                                    type: "success",
                                    mensagem: retorno.mensagem
                                }

                            }} /> :
                            ""}
                        <button type="submit" className="btn btn-success">Editar</button>
                    </div>

                </form>
            </div>
            </PageContent>
        </div>
    )
}