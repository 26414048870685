import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartFaturamento = ({ dados }) => {
  const data = {
    labels: dados.map(dado => dado.mes),  
    datasets: [
      {
        label: 'Valor Faturado',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',  
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        data: dados.map(dado => dado.valorFaturado),  
      },
      {
        label: 'Valor Liquidado',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',  
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: dados.map(dado => dado.liquidado),  
      },
      {
        label: 'Valor Deduzido',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',  
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        data: dados.map(dado => dado.deduzido), 
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Faturamento, Valor Liquidado e Deduzido por Mês',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Valores em Reais (R$)',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Meses',
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChartFaturamento;