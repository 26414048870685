// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  color: #fff;
}

.gradient-form {
  background-color: white;
}

.logo-img {
  width: 250px;
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #168989;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: #168989;
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: #168989;
  }
  
  @media (min-width: 768px) {
  .gradient-form {
  height: 100vh !important;
  }
  }
  @media (min-width: 769px) {
  .gradient-custom-2 {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;;EAEnB,+BAA+B;EAC/B,mBAAmB;;EAEnB,qEAAqE;EACrE,mBAAmB;EACnB;;EAEA;EACA;EACA,wBAAwB;EACxB;EACA;EACA;EACA;EACA,8BAA8B;EAC9B,iCAAiC;EACjC;EACA","sourcesContent":[".btn {\n  color: #fff;\n}\n\n.gradient-form {\n  background-color: white;\n}\n\n.logo-img {\n  width: 250px;\n}\n\n.gradient-custom-2 {\n  /* fallback for old browsers */\n  background: #168989;\n  \n  /* Chrome 10-25, Safari 5.1-6 */\n  background: #168989;\n  \n  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */\n  background: #168989;\n  }\n  \n  @media (min-width: 768px) {\n  .gradient-form {\n  height: 100vh !important;\n  }\n  }\n  @media (min-width: 769px) {\n  .gradient-custom-2 {\n  border-top-right-radius: .3rem;\n  border-bottom-right-radius: .3rem;\n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
