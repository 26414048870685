import React, { useEffect, useState } from 'react';
import api from '../../../config/configApi';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { Link } from 'react-router-dom';

export const ViewBanco = (props) => {



    const [idEmpresa] = useState(props.match.params.idempresa);
    const [idbanco] = useState(props.match.params.idconta);
    //console.log("id Visualizar idEmpresa", idEmpresa);
    //console.log("id Visualizar idbanco", idbanco);

    const [banco, setBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [conta, setConta] = useState('');
    const [tipoconta, setTipoConta] = useState('');

    const handleSelectChange = (event) => {
        setTipoConta(event.target.value);
    };


    useEffect(() => {
        const getConta = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };

            await api.get("/selecionarbanco/" + idbanco, headers)
                .then((response) => {
                    if (response.data) {
                        console.log("response", response);
                        setBanco(response.data.banco);
                        setAgencia(response.data.agencia);
                        setConta(response.data.conta);
                        setTipoConta(response.data.tipoconta);

                    } else {
                        console.log("Erro: Usuário não encontrado !");
                    }

                }).catch((err) => {
                    if (err) {
                        console.log("Erro");
                    } else {
                        console.log("Erro  não encontrado");
                    }
                })
        }
        getConta();
    }, [idEmpresa]);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <div className='container' style={{ marginTop: '70px', marginLeft: '220px' }}>
                <h1>Visualizar Dados Bancarios</h1>
                <form className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Banco:</label>
                        </b>
                        <input type="text" name="banco" className="form-control" value={banco} onChange={text => setBanco(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Agencia:</label>
                        </b>
                        <input type="text" name="agencia" className="form-control" value={agencia} onChange={text => setAgencia(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label className="form-label">Conta:</label>
                        </b>
                        <input type="text" name="conta" className="form-control" value={conta} onChange={text => setConta(text.target.value)} disabled ></input>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-outline mb-4">
                        <b>
                            <label for="inputTipoConta" className="form-label">Tipo Conta:</label>
                        </b>
                        <select id="inputTipoConta" className="form-select" name="tipoconta" value={tipoconta} onChange={handleSelectChange} disabled>
                            <option value="contacorrente">Conta Corrente</option>
                            <option value="poupanca">Poupança</option>
                        </select>
                    </div>
                    <div className="col-12 mb-4 text-center">
                        <Link to={"/banco/" + idEmpresa}>
                            <button type="button" className="btn btn-success">
                                Voltar
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}