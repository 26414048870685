import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { PageContent } from '../../../components/PageComponent';
import api from '../../../config/configApi';
import { Link, useLocation } from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'datatables.net-bs5';

export const LogsOs = (props) => {
    const { state } = useLocation();

    const permissao = JSON.parse(localStorage.getItem('permissao'));
    const [telaAnterior] = useState(props.match.params.telaAnterior);
    const [idos] = useState(props.match.params.idos);

    const [data, setData] = useState([]);
    const [status, setRetorno] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const dataTableRef = useRef(null);

    const getLogs = async () => {
        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        };

        try {
            if (dataTableRef.current && $.fn.DataTable.isDataTable(dataTableRef.current)) {
                $(dataTableRef.current).DataTable().destroy();
            }
            const response = await api.get("/logsos/" + idos, headers);
            //console.log("area", response);
            setData(response.data);
            if (dataTableRef.current) {
                $(dataTableRef.current).DataTable({
                    retrieve: true,
                    "order": [[0, "desc"]]
                });
            }
        } catch (err) {
            setRetorno({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    };

    useEffect(() => {
        getLogs();
    }, []);

    return (
        <div className="sb-nav-fixed">
            <NavBar />
            <SideBar />
            <PageContent>
                <div className='container px-4'>
                    <h1>Logs OS</h1>

                    <div className='card mt-5'>
                        <div className='card-body'>
                            <div className="table-responsive">
                                <table ref={dataTableRef} className="table table-fit mt-5 table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">status</th>
                                            <th scope="col">dataAtualizacao</th>
                                            <th scope="col">nome</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map(areas => (
                                            <tr key={areas.id}>
                                                <th scope="row">{areas.id}</th>
                                                <td>{areas.status}</td>
                                                <td>{areas.dataAtualizacao}</td>
                                                <td>{areas.nome}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className='col-12 mb-4 text-center'>
                        <Link to={`/${telaAnterior}/` + idos}>
                            <button type="button" className="btn btn-success me-md-2">
                                Voltar
                            </button>
                        </Link>

                    </div>
                </div>
            </PageContent>
        </div>
    );
};
